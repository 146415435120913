import React, { useState } from 'react'
import { useSceneStore } from 'ogmo-renderer';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, FormControl, Grid, InputBase, NativeSelect, TextField, Typography, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import arAccordian from "../../../images/arAccordian.png";

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'rgba(109, 109, 109, 1)',
        border: '1px solid #ced4da',
        fontSize: 12,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        color: 'white',
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const ARInspectorComponent = () => {

    const min = useSceneStore((state) => state.modelMin)
    const max = useSceneStore((state) => state.modelMax)
    const size = useSceneStore((state) => state.modelSize)

    const [expanded, setExpanded] = useState(true);

    return (
        <Box data-test="ar_settings_component">
            <Accordion style={{ background: 'linear-gradient(180deg, rgba(52, 52, 52, 1), rgba(52, 52, 52, 0.5))', color: 'white', display: 'flex', flexDirection: 'column' }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'rgba(178, 178, 178, 1)' }} />} data-test="real_world_scale"><img src={arAccordian} height='20px' width='20px' style={{ marginRight: '8px',marginTop:'2px' }} />Real World Scale</AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} data-test="object_size">
                    <Typography style={{ color: 'white' }} variant='subtitle1' gutterBottom>Object Size</Typography>
                    <>
                        X : {((max.x - min.x) * 100).toFixed(2)} cm
                    </>
                    <br />
                    <>
                        Y : {((max.y - min.y) * 100).toFixed(2)} cm
                    </>
                    <br />
                    <>
                        Z : {((max.z - min.z) * 100).toFixed(2)} cm
                    </>
                    <br />
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export { ARInspectorComponent }