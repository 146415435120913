import React,{useState,useMemo,useRef} from 'react';
import { Typography, Input, Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { useDropzone } from 'react-dropzone';
import FileLogo from '../../images/file.png';
import LinearProgress from '@material-ui/core/LinearProgress';
import { OGMOCanvas } from "ogmo-renderer";
import { Link } from "react-router-dom";
import Computer from '../../images/computer.png';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Uploader from './../../components/Design/Uploader';
import { MAX_GLB_SIZE as maxSize} from './../../util/constants';
import { useEnvironmentStore, useSceneStore } from 'ogmo-renderer';
import { useHistory } from "react-router-dom";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const baseStyle = {
  width: '750px',
  height: '450px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '45px 0',
  borderWidth: 2,
  boxSizing: 'border-box',
  borderRadius: '10px',
  borderColor: '#263C67',
  borderStyle: 'dashed',
  backgroundColor: '#fff',
  color: '#263C67',
  outline: 'none',
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    width: '370px',
  },
  colorPrimary: {
    backgroundColor: '#C4C4C4',
  },
  bar: {
    backgroundColor: '#1D4C92',
  },
}))(LinearProgress);

const useStyles = makeStyles(theme => ({
  preview: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: '6px',
    height: '30vh',
    width: '40vw',
    border: 'solid 1px #C4C4C4',
    marginTop: '30px',
    marginBottom: '30px',
  },
  input: {
    fontSize: 14,
    color: '#263C67',
    fontFamily: 'Poppins',
    fontWeight: '500',
    borderRadius: '5px',
    alignItems: 'center',
    padding: '6px',
    width: 300,
    height: '40px',
    border: '2px solid #ECECEC',
    boxSizing: 'border-box',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  filebtn: {
    padding:'8px 16px',
    boxSizing: 'border-box',
    background: '#1D4C92',
    color: '#FBFDFF',
    borderRadius: '5px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
    },
  },
  loadbarDiv: {
    marginTop: '30px',
    width: '450px',
    height: '100px',
    border: '2px dashed #CBCBCB',
    boxSizing: 'border-box',
    borderRadius: '10px',
  },
  loadbar: {
    marginTop: '50px',
    marginLeft: '40px',
    marginRight: '40px',
  },
  editbtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    background: '#3D6ACF',
    color: 'white',
    border: 'transparent',
    borderRadius: '5px',
    padding: '8px 16px',
    cursor:'pointer',
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
    },
  },
  dragText:{
    fontSize: 14,
    color: '#263C67',
    fontFamily: 'Poppins',
  }
}));

const DesignDetails = ({isUploadGlb, design,isUploading,isReplaceGlb ,handleNameChange, handleUpload, handleReplace, replacedFileName,progress }) => {
  
  const setBackgroundColor = useEnvironmentStore((state) => state.setBackgroundColor);
  const setModelPath = useSceneStore((state) => state.setModelPath);
  const resetSceneDimensions = useSceneStore((state) => state.resetSceneDimensions);
  let history = useHistory();
  const classes = useStyles();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText,setAlertText] = useState()
  const onDrop = (acceptedFiles) => handleUpload(acceptedFiles);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };
  
  function loadEditor() {
    setModelPath(null);
    resetSceneDimensions();
    setBackgroundColor('#ffffff');

    history.push({
      pathname: `/editor/${design.designId}`
    });
  }

  function fileSizeValidator(file) {
    if (file.name.split('.').pop() !== 'glb') {
      setAlertText("Incorrect File Type")
      setAlertOpen(true);
    }

    if (file.size > maxSize) {
      setAlertText("Maximum upload file size: 50 MB")
      setAlertOpen(true)
      return {
        code: "file-too-large",
        message: `File is larger than max limit`
      };
    }
  
    return null
  }

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    validator: fileSizeValidator,
    onDrop,
    accept: '.glb',
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <Box>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={alertOpen}
        onClose={handleClose}
        autoHideDuration={3000} >
        <Alert onClose={handleClose} severity="error">
          {alertText}
        </Alert>
      </Snackbar>
      {isUploading ? <div>
        <Typography variant="subtitle1">
          3D File
        </Typography>
        <div className={classes.loadbarDiv}>
          <BorderLinearProgress className={classes.loadbar} variant="determinate" value={progress} />
        </div>
      </div> : design.designId ? <div>
        <div>
          <FormControl>
            <Typography
              variant="subtitle1"
            >Design name</Typography>
            <Input
              type="text"
              autoFocus
              className={classes.input}
              disableUnderline
              onChange={handleNameChange}
              placeholder='Add Design Name'
              required
              data-test='design_name'
            />
          </FormControl>
        </div>
        <div>
          <Box className={classes.preview}>
            <OGMOCanvas id={'preview'} />
          </Box>
          <Uploader
            handleReplace={handleReplace}
            isReplaceGlb={isReplaceGlb}
            replacedFileName={replacedFileName}
            progress={progress}
          />     
          {!isReplaceGlb &&
              <button onClick={()=>loadEditor()} disabled={!isUploadGlb ? false : true} className={classes.editbtn}><img style={{ marginRight: "8px" }} width="20px" height="20px" src={Computer} alt="edit logo" /> Edit in 3D Editor</button>
            }
          </div>
      </div> : <div>
        <Typography variant="subtitle1">3D File</Typography>
        <div {...getRootProps({ style })}>
            <input {...getInputProps()} data-test="upload_glb" />
          <img width="66px" height="80px" src={FileLogo} alt="glb logo" />
          <p className={classes.dragText}>Drag and drop GLB or</p>
          <button className={classes.filebtn} onClick={open} data-test="browse">Browse</button>
          <p className={classes.dragText}>(Maximum upload file size: 50 MB)</p>
        </div>
      </div>}
    </Box>
  );
};

export default DesignDetails;
