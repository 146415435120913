import React,{useState,useRef,useEffect} from 'react';
import { Box,Breadcrumbs,Typography,Select,MenuItem,FormControl,Input,Checkbox, Button,TextField,NativeSelect } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { OGMOCanvas } from "ogmo-renderer";
import { SketchPicker } from 'react-color';
import { useEnvironmentStore, useSceneStore } from 'ogmo-renderer';
import * as api from '../../services/api';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0px',
    },
    container: {
        padding: '30px',
        paddingTop: '20px',
        background: '#fff',
        boxShadow: '0px 4px 8px rgb(73 73 73 / 10%)',
        borderRadius: '4px'
    },
    input: {
        fontSize: 12,
        color:'#263C67',
        fontFamily: 'Poppins',
        fontWeight: '500',
        borderRadius: '5px',
        alignItems: 'center',
        padding: '6px',
        width: 225,
        height:'30px',
        border: '1px solid #C4C4C4',
        boxSizing: 'border-box',
      },
      preview: {
        borderRadius: '4px',
        alignItems: 'center',
        height: 'calc(100vh - 465px)',
        width: 'calc(100vw - 560px)',
        border: 'solid 1px #C4C4C4',
        [theme.breakpoints.up(1280)]: {
            width: 'calc(100vw - 820px)',   
        }
      },
      checkboxIcon: {
        border: '1px solid #C4C4C4',
        borderRadius: 5,
        width: 20,
        height: 20,
      },
      editbtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
        background: '#3D6ACF',
        color: 'white',
        border: 'transparent',
        borderRadius: '5px',
        boxSizing: 'border-box',
        padding:'6px 8px',
        cursor:'pointer',
        "&:hover": {
          backgroundColor: "rgba(29, 76, 146,0.8)",
        },
      },
      replaceBtn: {
        position: 'relative',
        boxSizing: 'border-box',
        background: '#1D4C92',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        background: '#1D4C92',
        color: 'white',
        border: 'transparent',
        borderRadius: '5px',
        padding: '6px 8px',
        boxSizing: 'border-box',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        textTransform: 'capitalize',
        "&:hover": {
          backgroundColor: "rgba(29, 76, 146,0.8)",
        },
      },
      colordiv: {
        width: 28,
        height: 28,
        border: 'none',
        borderRadius: '100%',
        objectFit: 'cover',
        border: '1px solid #C4C4C4'      
    },
      displayName: {
        width:'265px', 
        height:'30px',
        '& input': {
            padding: '8px 6px'
          }
      },
      dltbtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40px',
        width: '40px',
        borderRadius: '100%',
        cursor: 'pointer',
        '&:hover': {
          background: '#EAEAEA',
        },
    },
    colourPickerStyles: {
        boxShadow: 'none !important',
        borderRadius: '5px',
        border: '1px solid #C4C4C4' 
    },
    selectRoot: {
          '&:focus':{
            backgroundColor:'transparent'
          }
        }
  }));

function GroupDesignDetails({ displayImage,setDisplayImage, isDefault,setIsDefault, setImage, setDisplayName, displayName, image, design, setDesign, bgColour, setBgColour, handleDeleteDesign, handleDefaultDesign, groupDesignData, setGroupDesigns, groupDesigns, setIsInProgress, isInProgress }) {
    
    const classes = useStyles();
    const [displayType, setDisplayType] = useState('');
    const hiddenFileInput = useRef();
    const setEnvironmentMap = useEnvironmentStore((state) => state.setEnvironmentMap);
    const setEnvironmentMapExplicitPath = useEnvironmentStore((state) => state.setEnvironmentMapExplicitPath);
    const setBackgroundColor = useEnvironmentStore((state) => state.setBackgroundColor);
    const setAmbientIntensity = useEnvironmentStore((state) => state.setAmbientIntensity);
    const setShadowOpacity = useEnvironmentStore((state) => state.setShadowOpacity);
    const setShadowBlur = useEnvironmentStore((state) => state.setShadowBlur);
    const setShadowBias = useEnvironmentStore((state) => state.setShadowBias);
    const setModelPath = useSceneStore((state) => state.setModelPath);
    const setCameraPosition = useSceneStore((state) => state.setCameraPosition);
    const resetSceneDimensions = useSceneStore((state) => state.resetSceneDimensions);

    useEffect(() => {
        setModelPath(null);
        setBackgroundColor(null);
        resetSceneDimensions();
        if (groupDesigns) {
            if (design && design.designId !== undefined) {
                setDisplayName(design.name);
                setDisplayType(design.displayType);
                getDesign(design.designId);
                setBgColour({ [design.designId]: [design.color] });
                setDisplayImage(prevState => ({ ...prevState, [design.designId]: (design.thumbnail) }));
            } else if (design === undefined) {
                setModelPath(null);
                setBackgroundColor(null);
            }
        } else if (design) {
            setDisplayName(design.name);
            setDisplayType(design.displayType);
            if (design.designId !== undefined) {
                getDesign(design.designId);
            }
            setDisplayImage(prevState => ({ ...prevState, [design.designId]: (design.thumbnail) }));
        }
    }, [design]);
    
    useEffect(() => {
        if(groupDesigns)
        {
            setIsDefault(groupDesigns.indexOf(design) === 0 ? true : false);
        }
        
    }, [design, groupDesigns]);

    const getDesign = (designId) => {
        api.get(`designs/${designId}`)
          .then(data => {
            setModelPath(data.data['sceneInfo']['modelPath']);
            setEnvironmentMap(data.data['sceneInfo']['environmentMapId']);
            setEnvironmentMapExplicitPath(data.data['sceneInfo']['environmentMapPath']);
            setBackgroundColor(data.data['sceneInfo']['backgroundColor']);
            setAmbientIntensity(data.data['sceneInfo']['ambientIntensity']);
            setShadowOpacity(data.data['sceneInfo']['shadowOpacity']);
            setShadowBlur(data.data['sceneInfo']['shadowBlur']);
            setShadowBias(data.data['sceneInfo']['shadowBias']);
            setCameraPosition(data.data['sceneInfo']['cameraPosition']);
    
          })
          .catch(error => {
            console.log('Faild to get design: ', error.message);
          });
      };

    const handleBgColorChange = (color) => {
        setBgColour(prevState => ({...prevState, [design.designId]:color.hex }));
        design.color=bgColour;
        if (groupDesigns) {
            design["color"] = color.hex;
        }
    };

    const handleBgColourChangeComplete = (color) => {
        setBgColour(prevState => ({ ...prevState, [design.designId]: color.hex }));
        if (groupDesigns) {
            design["color"] = color.hex;
        }
    };

    const handleDisplayTypeChange = (event) => {
        setDisplayType(event.target.value);
        design.displayType = event.target.value;
    };

    const handleFileInput = (event) => {
        let thumbnailFile = event.target.files[0]
            if (thumbnailFile) {
                setImage(prevState => ({ ...prevState, [design.designId]: thumbnailFile }));
                design.thumbnail = URL.createObjectURL(thumbnailFile);
                setDisplayImage(prevState => ({ ...prevState, [design.designId]: URL.createObjectURL(thumbnailFile) }));
            }
    };

    const handleDisplayName = (event) => {
        setDisplayName(event.target.value);
        design.name= event.target.value;
    };

    return (
        <Box mt={4} flexGrow={1} display="flex" flexDirection="column" className={classes.container}>
            <div style={{ display: 'flex', alignItems: "center", marginBottom: '32px', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: "center" }}>
                    <div style={{ width: '150px' }}>
                        <Typography
                            variant="subtitle1"
                        >Display Name</Typography>
                    </div>
                    <div>
                        <FormControl size="small">
                            <TextField className={classes.displayName} variant="outlined" size="small" disabled={isInProgress || !design ? true : false} onChange={handleDisplayName} value={displayName} />
                        </FormControl>
                    </div>
                </div>
                <div>
                    <div>
                        <div className={classes.dltbtn} onClick={() => handleDeleteDesign(design)} style={isInProgress ? { display: 'none' } : null}>
                            <DeleteIcon style={{ color: "#263C67" }} disabled={isInProgress} />
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ display: 'flex', flexGrow: 1 }}>
                <div style={{ display: 'flex', alignItems: "flex-start", flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: "center" }}>
                        <div style={{ width: '150px' }}>
                            <Typography
                                variant="subtitle1"
                            >
                                Display Icon
                            </Typography>
                        </div>
                        <div>
                            <FormControl size="small">
                                <Select
                                    classes={{ root: classes.selectRoot }}
                                    input={<Input className={classes.input} disableUnderline />}
                                    value={displayType}
                                    onChange={handleDisplayTypeChange}
                                    disabled={isInProgress || !design ? true : false}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        }
                                    }}
                                >
                                    <MenuItem value={`color`}>Colour Picker</MenuItem>
                                    <MenuItem value={`thumbnail`}>Upload Image</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div style={{ height: '275px' }}>
                        {displayType === 'color' && <div style={{ display: 'flex', alignItems: "flex-start" }}>
                            <div style={{ marginLeft: '150px', paddingTop: '10px' }}>
                                {design &&
                                    <SketchPicker
                                        color={design ? `${bgColour[design.designId]}` : '#808080'}
                                        width='203px'
                                        onChange={handleBgColorChange}
                                        onChangeComplete={handleBgColourChangeComplete}
                                        disableAlpha={true}
                                        className={classes.colourPickerStyles}
                                    />
                                }
                            </div>
                        </div>
                        }
                        {displayType === 'thumbnail' && <div style={{ display: 'flex', marginLeft: '150px' }}>
                            <input
                                accept="image/png, image/jpeg"
                                type="file"
                                ref={hiddenFileInput}
                                style={{ display: "none" }}
                                multiple={false}
                                id="replaceGlbFile"
                                required
                                data-test="replace_glb_file"
                                onChange={(e) => { handleFileInput(e) }}
                            />
                            <button className={classes.replaceBtn} style={{ marginTop: '10px' }} onClick={() => hiddenFileInput.current.click()} disabled={isInProgress}>Upload</button>
                        </div>
                        }
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: '150px' }}>
                            <Typography
                                variant="subtitle1"
                            >
                                Default
                            </Typography>
                        </div>
                        <div>
                            <div>
                                <Button variant="contained" color="primary" className={classes.replaceBtn} disabled={isDefault || isInProgress} onClick={() => handleDefaultDesign(design)}>make default</Button>
                            </div>
                        </div>
                    </div>
                </div>

                {groupDesigns ? <div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '40px' }}>
                        {displayType === 'color' &&
                            <div>
                                {bgColour[design.designId] &&
                                    <div className={classes.colordiv} style={{ background: `${bgColour[design.designId]}` }}></div>
                                } </div>
                        }
                        {
                            displayType === 'thumbnail' &&
                            <>
                                {
                                    displayImage && displayImage[design.designId] && displayImage[design.designId][0] !== "" ?
                                        <div>
                                            {
                                                displayImage[design.designId] &&
                                                <img className={classes.colordiv} src={displayImage[design.designId]} />
                                            }
                                        </div> :
                                        <div>
                                            {
                                                bgColour[design.designId] &&
                                                <div className={classes.colordiv} style={{ background: `${bgColour[design.designId]}` }}></div>
                                            }
                                        </div>
                                }
                            </>
                        }
                    </div>
                </div>

                    :

                    <div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '40px' }}>
                            {displayType === 'color' &&
                                <div>
                                    {bgColour[design.designId] &&
                                        <div className={classes.colordiv} style={{ background: `${bgColour[design.designId]}` }}></div>
                                    } </div>
                            }
                            {displayType === 'thumbnail' && displayImage &&
                                <div>
                                    {displayImage[design.designId] &&
                                        <img className={classes.colordiv} src={displayImage[design.designId]} />
                                    } </div>
                            }
                             {displayType === 'thumbnail' && 
                                <div>
                                {bgColour[design.designId] && !displayImage[design.designId] &&
                                    <div className={classes.colordiv} style={{ background: `${bgColour[design.designId]}` }}></div>
                                } </div>
                            }
                        </div>
                    </div>
                }

                <div style={{ display: 'flex', flexGrow: 1, paddingLeft: '20px' }}>
                    <div className={classes.preview}>
                        <OGMOCanvas id={'preview'} />
                    </div>
                </div>
            </div>
        </Box>
    );
}

export default GroupDesignDetails;