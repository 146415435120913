import React from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
  Box,
  Typography
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  tableHead: {
    fontFamily: 'Roboto,sans-serif',
    fontSize: '14px',
    lineHeight: '18.75px',
    color: '#000',
  },
  designName: {
    marginLeft: theme.spacing(4),
    color: '#000',
    fontFamily: 'Roboto,sans-serif',
    fontSize: '14px',
    lineHeight: '18.75px',
  },
  checkboxStyle: {
    color: '#AEAEAE',
  },
}));

function DesignTableHead({onSelectAllClick,numSelected,rowCount}) {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
      <TableCell padding="checkbox">
        <Checkbox className={classes.checkboxStyle} indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount} onChange={onSelectAllClick}/>
        </TableCell>
        <TableCell className={classes.designName}>Design Name </TableCell>
        <TableCell className={classes.tableHead}>Design Type</TableCell>
        <TableCell className={classes.tableHead}>Created Date</TableCell>
        <TableCell className={classes.tableHead}>Linked Status</TableCell>
        <TableCell className={classes.tableHead}></TableCell>
      </TableRow>
    </TableHead>
  );
}

export default DesignTableHead;
