import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import RouteWrapper from "./components/RouteWrapper/RouteWrapper";
import Designs from "./views/Designs/Designs";
import { Main as MainLayout } from "./layouts/Main";
import Dashboard from "./views/Dashboard/Dashboard";
import Scans from "./views/Scans/Scans";
import Settings from "./views/Settings/Settings";
import Editor from "./views/Editor/Editor";
import CreateDesign from "./views/Designs/CreateDesign";
import UpdateDesign from "./views/Designs/UpdateDesign";
import Billing from "./views/Settings/Billing";
import EComIntegration from "./views/Settings/EComIntegration";
import Password from "./views/Settings/Password";
import UserProfile from "./views/Settings/UserProfile";
import GroupDesign from "./views/Designs/GroupDesign";
import UpdateGroupDesign from "./views/Designs/UpdateGroupDesign";

const allRoutes = [
    {
        component: Dashboard,
        exact: true,
        layout: true,
        path: "/dashboard",
    },
    {
        component: Designs,
        exact: true,
        layout: true,
        path: "/designs",
    },
    {
        component: Scans,
        exact: true,
        layout: true,
        path: "/scans",
    },
    {
        component: Settings,
        exact: true,
        layout: true,
        path: "/settings",
    },
    {
        component: Billing,
        exact: true,
        layout: true,
        path: "/settings/billing",
    },
    {
        component: EComIntegration,
        exact: true,
        layout: true,
        path: "/settings/ecomintegration",
    },
    {
        component: Password,
        exact: true,
        layout: true,
        path: "/settings/password",
    },
    {
        component: UserProfile,
        exact: true,
        layout: true,
        path: "/settings/userprofile",
    },
    {
        component: Editor,
        exact: true,
        path: "/editor/:designId"
    },
    {
        component: CreateDesign,
        exact: true,
        layout: true,
        path: "/createDesign",
    },
    {
        component: UpdateDesign,
        exact: true,
        layout: true,
        path: "/updateDesign/:designId",
    },
    {
        component: GroupDesign,
        exact: true,
        layout: true,
        path: "/groupDesign",
    },
    {
        component: UpdateGroupDesign,
        exact: true,
        layout: true,
        path: "/updateGroupDesign/:designId",
    }
];

const Routes = () => {
    var routesWithMainLayout = [];
    var routesWithoutMainLayout = [];

    allRoutes.forEach(({ component, exact, layout, path }, index) => {
        if (layout) {
            routesWithMainLayout.push((
                <RouteWrapper
                    component={component}
                    exact={exact}
                    layout={null}
                    path={path}
                    key={index}
                />
            ));
        } else {
            routesWithoutMainLayout.push((
                <Route component={component} exact={exact} layout={null} path={path} key={index} />
            ));
        }
    });

    return (
        <Switch>
            <Route path="/editor" component={null}>
                {routesWithoutMainLayout}
            </Route>
            <Route path="/" component={null}>
                <MainLayout>
                    <Switch>
                        {routesWithMainLayout}
                        <Redirect from="/" to="/dashboard" key="redirect" />
                    </Switch>
                </MainLayout>
            </Route>

        </Switch>
    );
};


export default Routes;