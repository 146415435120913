import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Box, Drawer } from '@material-ui/core';
import ogmoLogo from '../../../../images/ogmoLogoWhite.png';
import { SidebarNav } from './components';
import Settings from '../../../../images/Settings.png';
import Designs from '../../../../images/Designs.png';
import AdminDashboard from '../../../../images/dashboard.png';
import Scanning from '../../../../images/Scanning.png';
import {Link} from "react-router-dom";

import ScansPopup from '../../../../components/Scans/ScansPopup'

const useStyles = makeStyles(theme => ({
  drawer: {
    display: 'flex',
    background: '#1F1B39',
    width: 220,
    [theme.breakpoints.up('lg')]: {
      width: 220,
      height: 'calc(100%)'
    }
  },
  root: {
    
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '20px',
  },
  nav: {
    width:'100%',
    marginBottom: theme.spacing(2),
    color: 'white'
  },
  iconImg: {
    color: '#ffffff',
    width: 20,
    height: 20,
    alignItems: 'center'
  },
  ogmoLogo: {
    margin: 'auto',
    marginTop: 25,
    marginBottom: 50,
    width: 100,
    height: 24,  
  },
  orgName: {
    marginTop: 'auto',
    minWidth: 200
  }
}));

const Sidebar = ({ open, variant, onClose, className, ...rest }) => {
  const classes = useStyles();
  const [showScansPopup, setShowScansPopup] = useState(false);
  const [pages, setPages] = useState([
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <img
        alt=""
        className={classes.iconImg}
        style={{width:'20px', height: '18px'}}
        src={AdminDashboard}
      />
      ,
      sub:[]
    },
    {
      title: 'Designs',
      href: '/designs',
      icon: <img
        alt=""
        className={classes.iconImg}
        src={Designs}
      />
      ,
      sub:[]
    },
    {
      title: 'Scans',
      href: '/scans',
      onClick: e => {e.preventDefault(); setShowScansPopup(true);},
      icon: <img
        alt=""
        className={classes.iconImg}
        style={{width:'24px', height: '24px'}}
        src={Scanning}
      />
      ,
      sub:[]
    },
    {
      title: 'Settings',
      href: '/settings',
      icon: <img
        alt=""
        className={classes.iconImg}
        style={{width:'24px', height: '24px'}}
        src={Settings}
      />,
      sub:[
        {
          title: 'User Profile',
          href: '/userprofile'
        },
        {
          title: 'Billing',
          href: '/billing'
        },
        {
          title: 'Password',
          href: '/password'
        },
        {
          title: 'Integration',
          href: '/ecomintegration'
        }
      ]
    }
  ]
  );


  return (
    <>
      <ScansPopup showScansPopup={showScansPopup} setShowScansPopup={setShowScansPopup} />
      <Drawer
        anchor="left"
        classes={{ paper: classes.drawer }}
        onClose={onClose}
        open={open}
        variant={variant}
      
      >
        <Link to='/dashboard' style={{margin:'auto'}}>
          <img
            alt=""
            className={classes.ogmoLogo}
            src={ogmoLogo}
          />
        </Link>

        <Box
          alignItems="center"
          display="flex"
          {...rest}
          className={clsx(classes.root, className)}
        >
          <SidebarNav
            className={classes.nav}
            pages={pages}
          />
        </Box>
      </Drawer>
    </>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
