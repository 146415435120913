import React, { useEffect, Suspense } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { useEnvironmentStore } from './Store'
import { Background } from './Background'
import { Model } from './Model'
import { Camera } from './Camera'
import { SkyBox } from './Skybox'
import { useSceneStore } from './Store'
import { Html } from '@react-three/drei'
import { Shadows } from './Shadows'
import { Environments } from './Consts'
import LoaderElement from './LoaderElement';

const ScreenShot = () =>{

	const { gl} = useThree()
	const captureScreen = useSceneStore((state) => state.captureScreen)
	const setCaptureScreenState = useSceneStore((state) => state.setCaptureScreenState);
	const setImage = useEnvironmentStore((state) => state.setImage);
	useEffect(()=>{
		if (captureScreen){
			var strMime = "image/png";
			let imgData = gl.domElement && gl.domElement.toDataURL(strMime);
			setImage(imgData,Date.now())
			let strDownloadMime = "image/octet-stream";
			// imgData.height= 46
			// imgData.width= 46
			// saveFile(imgData.replace(strMime, strDownloadMime), "ScreenShot.jpg");
		}
		setCaptureScreenState(false)
	
	},[captureScreen])

	var saveFile = function (strData, filename) {
        var link = document.createElement('a');
        if (typeof link.download === 'string') {
            document.body.appendChild(link); //Firefox requires the link to be in the body
            link.download = filename;
            link.href = strData;
            link.click();
            document.body.removeChild(link); //remove the link when done
        } else {
            location.replace(uri);
        }
    }
	return(
		<mesh/>
	)
}
	
export const OGMOCanvas = ({ designId }) => {	
	
	const modelPath = useSceneStore((state) => state.modelPath)
	
	return (
		<Canvas /*style={{ height: '100vh', width: '100vw' }}*/
			style={{ touchAction: "none" }}
			shadows
			gl={{ preserveDrawingBuffer: true }}
			dpr={window.devicePixelRatio}>
			<ScreenShot />
			{/* <pointLight position={[10, 10, 10]} /> */}
			<Suspense fallback={<LoaderComponent />}>
				<group>{modelPath && <Model path={modelPath} />}</group>
				<SkyBox />
				<Camera />
				<Shadows />
				<Background />
			</Suspense>
		</Canvas>
	)
}

const LoaderComponent = (props) => (<Html><div style={{position: 'absolute', width:'40px', height: '40px', transform: 'translate(-50%, -50%)'}}><LoaderElement/></div></Html>)

export { useEnvironmentStore }
export { useSceneStore }
export { Environments }
