import { DISABLED_USER_UPDATE_USERNAME_PREFIX } from './constants';

export const checkUsernameIsLimited = (user) => {
  let isDisabledPrefixExists = false;

  for (let userNamePref of DISABLED_USER_UPDATE_USERNAME_PREFIX) {
    if (user.indexOf(userNamePref) !== -1) {
      isDisabledPrefixExists = true;
      break;
    }
  }

  return isDisabledPrefixExists;
}