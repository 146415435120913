import React, { useState, useEffect } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DataGrid } from "@material-ui/data-grid";
import { Button, Input, InputAdornment } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { Auth } from 'aws-amplify';
import * as api from '../../services/api';
import { useMessageStore } from "../Messages/MessageStore/MessageStore";
import axios from "axios";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles(theme => ({
    input: {
        width: '200px',
        height: '40px',
        border: '1px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: '5px',
    },
    adornment: {
        paddingLeft: '15px',
        color: '#D6D6D6',
    },
    endadornment: {
        height: '16px',
        width: '16px',
        marginRight: '15px',
        color: '#D6D6D6',
        cursor: 'pointer',
    },
    button: {
        padding: '8px 16px',
        background: '#3D6ACF',
        borderRadius: '5px',
        color: '#FBFDFF',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '21px',
        marginLeft: '10px',
        "&:hover": {
            backgroundColor: "rgba(29, 76, 146,0.8)",
        },
        "&.Mui-disabled": {
            background: "#c9c9c9"
        }
    }
}))

const columns = [
    { field: 'designName', headerName: 'Design Name', width: 320 }
];

export default function DesignListDialogBox({ showDialogBox, handleClose, setGroupDesigns, groupDesigns, groupDesignData, isInProgress, setIsInProgress, setImage, setSelectedDesignId, setSelectedDesign }) {
    const classes = useStyles();
    const [designs, setDesigns] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [authuser, setAuthuser] = useState('');
    const [selectedDesigns, setSelectedDesigns] = useState([]);
    const [page, setPage] = useState(0);
    const [exclusiveStartKey, setExclusiveStartKey] = useState('');
    const [designsLoading, setDesignsLoading] = useState(false);
    const [totalDesignCount, setTotalDesignCount] = useState(0);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText,setAlertText] = useState()
    const dispatch = useMessageStore(state => state.dispatch);
    const prevSelectedDesigns = React.useRef(selectedDesigns);
    const [designList, setDesignList] = useState([]);

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setAlertOpen(false);
      };

    async function getAuthUser() {
        try {
            const auth = await Auth.currentAuthenticatedUser();
            if (auth) {
                setAuthuser(auth);
                loadDesigns(auth.username, page);
            }
        }
        catch (err) {
            console.log('Auth user error: ', err)
        }
    };

    useEffect(() => {
        getAuthUser();
        return function cleanup() {
            setDesigns([]);
        }
    }, []);

    useEffect(() => {
        setSelectedDesigns([]);
    }, [handleClose]);

    const searchDesign = () => {
        loadDesigns(authuser.username, page);
    };

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            searchDesign();
        };
    };

    const handleDesignSelection = (e) => {
        setSelectedDesigns(e);
    };

    let excludedDesigns = []
    let singleDesigns = []

    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }

    const uniqueDesignsSet = new Set();

    const addDesigns = () => {

        const filteredDesignList = designList.filter((obj) => {
            const isPresentInSet = uniqueDesignsSet.has(obj.designId);
            uniqueDesignsSet.add(obj.designId);
            return !isPresentInSet;
        });
        setDesignList(filteredDesignList)

        if ((selectedDesigns.length + groupDesigns.length) < 6) {

            groupDesigns.map((a) => {
                singleDesigns.push(a.designId)
            })

            let designList = selectedDesigns.filter(x => !singleDesigns.includes(x));
            let duplicatedDesigns = selectedDesigns.filter(x => singleDesigns.includes(x));

            for (let i = 0; i < groupDesigns.length; i++) {
                groupDesigns.map((design) => {
                    if (design.designId == duplicatedDesigns[i]) {
                        excludedDesigns.push(design.name)
                    }
                })
            }

            if (designList.length !== 0) {

                if (groupDesignData) {
                    setIsInProgress(true);
                    const groupDesignLength = groupDesigns.length;
                    const selectedIDs = new Set(designList);
                    const selectedRowData = filteredDesignList.filter((row) =>
                        selectedIDs.has(row.designId.toString())
                    );
                    var newArray = JSON.parse(JSON.stringify(selectedRowData));
                    const newDesigns = newArray.map((row, index) => {
                        let randomColor = getRandomColor()
                        setImage(oldVal => ({ ...oldVal, [row.designId]: row.thumbnail }));
                        return {
                            name: row.designName,
                            designName: row.designName,
                            designId: row.designId,
                            shortId: row.shortId,
                            thumbnail: row.thumbnail ? row.thumbnail : '',
                            id: groupDesignLength + (index + 1),
                            color: randomColor,
                            displayType: row.thumbnail ? "thumbnail" : "color"
                        };
                    });
                    setGroupDesigns(oldArray => ([...oldArray, ...newDesigns]));
                    setSelectedDesignId(newDesigns[newDesigns.length - 1].designId);
                    if (excludedDesigns.length !== 0) {
                        setAlertText("You have already added " + excludedDesigns)
                        setAlertOpen(true)
                    }
                    setIsInProgress(false);
                    handleClose();
                    setSelectedDesigns([]);
                    excludedDesigns = []
                    singleDesigns = []
                    setSelectedDesign(newDesigns[newDesigns.length - 1]);       
                } else {
                    const groupDesignLength = groupDesigns.length;
                    const selectedIDs = new Set(designList);
                    const selectedRowData = filteredDesignList.filter((row) =>
                        selectedIDs.has(row.designId.toString())
                    );
                    var newArray = JSON.parse(JSON.stringify(selectedRowData));
                    const newDesigns = newArray.map((row, index) => {
                        let randomColor = getRandomColor()
                        return {
                            name: row.designName,
                            designName: row.designName,
                            designId: row.designId,
                            shortId: row.shortId,
                            thumbnail: row.thumbnail ? row.thumbnail : '',
                            id: groupDesignLength + (index + 1),
                            color: randomColor,
                            displayType: row.thumbnail ? "thumbnail" : "color"
                        }
                    });
                    if (excludedDesigns.length !== 0) {
                        setAlertText("You have already added " + excludedDesigns)
                        setAlertOpen(true)
                    }

                    setGroupDesigns(oldArray => ([...oldArray, ...newDesigns]));
                    handleClose();
                    setSelectedDesigns([]);
                    setSelectedDesign(newDesigns[newDesigns.length - 1]);
                    excludedDesigns = []
                    singleDesigns = []
                }
            } else {
                if (excludedDesigns.length !== 0) {
                    setAlertText("You have already added " + excludedDesigns)
                    setAlertOpen(true)
                }
                handleClose();
                setSelectedDesigns([]);
                excludedDesigns = []
                singleDesigns = []
            }
        } else {
            dispatch({ type: 'error', message: 'There can be only five maximum designs.' });
        }
    };

    const handlePageChange = (newPage) => {
        prevSelectedDesigns.current = selectedDesigns;
        setPage(newPage);
        loadDesigns(authuser.username, newPage);
    }
    const loadDesigns = (username, page) => {
        setDesignsLoading(true);
        let nextDesignStartKey = exclusiveStartKey;
        if (page === 0) {
            nextDesignStartKey = '';
        }
        api.get(`designs?userId=${username}&itemsPerPage=5&designName=${searchText}&exclusiveStartKey=${nextDesignStartKey}&onlySingleDesigns=true`)
            .then(data => {
                setDesigns([]);
                data.data.designs.map(val => {
                    setDesigns(oldVal => [...oldVal, { id: val.designId, ...val }]);
                    setDesignList(oldArray => ([...oldArray,val]));
                });
                setTimeout(() => {
                    setSelectedDesigns(prevSelectedDesigns.current);
                  });
                setTotalDesignCount(data.data.designCount);
                setDesignsLoading(false);
                if (data.data.exclusiveStartKey !== null) {
                    setExclusiveStartKey(data.data.exclusiveStartKey);
                }
                else {
                    setExclusiveStartKey('');
                }
            })
            .catch(error => {
                console.log(error);
            });
    };
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={alertOpen}
                onClose={handleCloseAlert}
                autoHideDuration={3000} >
                <Alert onClose={handleCloseAlert} severity="error">
                    {alertText}
                </Alert>
            </Snackbar>
            <Dialog
                open={showDialogBox}
                onClose={() => handleClose()}
                aria-labelledby="responsive-dialog-title"
                scroll="body"
                maxWidth="xs"
                fullWidth={true}
            >
                    <DialogTitle id="responsive-dialog-title">
                        <div style={{ display: 'flex' }}>
                            <Input
                                value={searchText}
                                autoFocus
                                variant="outlined"
                                placeholder="Search by name"
                                className={classes.input}
                                disableUnderline
                                onChange={e => setSearchText(e.target.value)}
                                onKeyPress={handleEnterKeyPress}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon className={classes.adornment} />
                                    </InputAdornment>
                                }
                                endAdornment={
                                    searchText.length > 0 ?
                                        <InputAdornment position="end"
                                            onClick={() => setSearchText('')}
                                        >
                                            <CloseIcon className={classes.endadornment} />
                                        </InputAdornment>
                                        : null
                                }
                                data-test="search_by_name"
                            />
                            <Button
                                className={classes.button}
                                onClick={() => searchDesign()}
                                data-test="search_by_name_btn"
                            >
                                search
                            </Button>
                        </div>
                    </DialogTitle>
                    <DialogContent style={{ display:'flex', justifyContent: 'center' }}>
                        <div style={{ height: '400px', display: 'flex', justifyContent: 'center', width: '380px'}}>
                            <DataGrid
                                rows={designs}
                                columns={columns}
                                checkboxSelection
                                autoHeight
                                onSelectionModelChange={(newSelectedDesigns) => {
                                    setSelectedDesigns(newSelectedDesigns);
                                  }}
                                selectionModel={selectedDesigns}
                                pagination
                                pageSize={5}
                                page={page}                                
                                onPageChange={handlePageChange}
                                rowCount={totalDesignCount}
                                loading={designsLoading}
                                paginationMode="server"
                                rowsPerPageOptions={[5]}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.button} onClick={() => addDesigns()} style={{marginRight: '15px', marginBottom: '10px'}} 
                            disabled={isInProgress || selectedDesigns.length === 0? true: false}>
                            Add Design
                        </Button>
                    </DialogActions>
            </Dialog>
        </div>
    );
};
