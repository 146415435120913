import { Snackbar, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React from "react";
import { Severity } from "./constants/consts";
import useSnackbar from "./useSnackbar";

const CustomSnackBar = () => {

    const { open, handleSnackbarClose, handleExited, messageInfo } = useSnackbar();

    return (
        <Snackbar
            open={open}
            onClose={handleSnackbarClose}
            autoHideDuration={4000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ color: 'white' }}
            TransitionProps={{ onExited: handleExited }}
        >
            <React.Fragment>
                {
                    messageInfo && messageInfo[1] === Severity.success &&
                    <Alert style={{ backgroundColor: 'rgba(78, 78, 78, 1)', color: 'white' }} icon={<CheckCircleOutlineIcon fontSize="inherit" style={{ color: 'white' }} />} onClose={handleSnackbarClose}><Typography style={{ color: 'white' }} variant="subtitle1">{messageInfo[0]}</Typography></Alert>
                }
                {
                    messageInfo && messageInfo[1] !== Severity.success &&
                    <Alert variant="filled" severity={messageInfo[1]} icon={<ErrorOutlineIcon fontSize="inherit" style={{ color: 'white' }} />} onClose={handleSnackbarClose}><Typography style={{ color: 'white' }} variant="subtitle1">{messageInfo[0]}</Typography></Alert>
                }
            </React.Fragment>
        </Snackbar>
    );
};

export default CustomSnackBar;