import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import PaymetDetailCard from '../../components/Settings/PaymentDetailCard/PaymentDetailCard';
import PlanCard from '../../components/Settings/PlanCard/PlanCard';
import * as api from '../../services/api';
import { Auth } from 'aws-amplify';
import CustomBreadCrumb from './../../components/Settings/CustomBreadCrumb/CustomBreadCrumb';
import PageLoader from './../../components/PageLoader/PageLoader'

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  header: {
    color: '#263C67',
    fontSize: '34px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    paddingTop: "30px",
    paddingBottom: "50px",
  },
  subheader: {
    color: '#263C67',
    fontSize: '24px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    paddingTop: "60px",
    paddingBottom: "40px",
  },
  root: {
    flexGrow: 1,
    backgroundColor: '#FFFFFF',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: "20px 20px 20px 0px",
  },
}))

const Billing = () => {
  const classes = useStyles();

  const [currentPlan, setCurrentPlan] = useState(null);
  const [allPlans, setAllPlans] = useState([]);

  useEffect(() => {
    getAllPricePlanDefs();
    getUserPricePlan();
  }, [])

  async function getUserPricePlan() {
    try {
      const auth = await Auth.currentAuthenticatedUser();
      if (auth) {
        getPricePlan(auth.username);
      }
    }
    catch (err) {
      console.log('Auth user error: ', err)
    }
  }

  async function getAllPricePlanDefs() {
    await api.get('billing/pricePlanDefs')
      .then(data => {
        let planDef = data.data;
        let tempPlanArray = [];

        planDef.map(plan => {
          const tempPlanDetail = { ...plan,  details: [plan.designCount === "unlimited"? 'Unlimited designs': `${plan.designCount} designs`, plan.designViewCount === "configurable"? 'Configurable':`${plan.designViewCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} views`] };
          tempPlanArray.push(tempPlanDetail);
        });

        tempPlanArray.sort((a, b) => {
          return a.displayOrder - b.displayOrder;
        });
        
        setAllPlans(tempPlanArray)
      })
      .catch(error => {
        alert('Error loading Price plans');
        console.log('pricePlanDefs Error: ', error);
      });
  }

  async function getPricePlan(userId) {
    await api.get(`billing/pricePlans?userId=${userId}`)
      .then(data => {
        setCurrentPlan(data.data)
      })
      .catch(error => {
        alert('Error loading Plan');
        console.log('pricePlan Error: ', error);
      });
  }

  if (allPlans.length !== 0 && currentPlan !== null) {
    return (
      <>
        <div className={classes.root}>
          <CustomBreadCrumb breadcrumbs={[
            { text: "Settings", isCurrent: false },
            { text: "Billing", isCurrent: true },
          ]} />
          <div>
            <div>
              <div>
                <Typography className={classes.subheader}>Choose a Plan</Typography>
              </div>


              {0 < allPlans.length !== null && currentPlan !== null &&
                <Grid container spacing={2} >
                  {allPlans.map(plan => {
                    return (<Grid key={plan.pricePlanDefId} item  >
                      <PlanCard plan={plan} current={plan.pricePlanDefId === currentPlan.pricePlanDefId} />
                    </Grid>)
                  })
                  }
                </Grid>
              }
            </div>
          </div>
          {/* <Grid item sm={4} >
              <PaymetDetailCard lastdigit={7849} />
            </Grid > */}
        </div>
      </>
    );
  } else {
    return (
      <PageLoader />
    );
  }

};

export default Billing;
