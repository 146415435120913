import React, { useState, useEffect, useCallback } from 'react'
import { Box, Button } from "@material-ui/core"
import { useEnvironmentStore, useSceneStore } from 'ogmo-renderer'
import logo from "../../../images/ogmoLogoWhite.png";
import * as api from "../../../services/api";
import { useHistory } from "react-router-dom";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { makeStyles } from '@material-ui/styles';
import Thumbnail from '../../../images/thumbnail.png';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

const override = css`
  margin: 0 10px 0 0;
`;

const useStyles = makeStyles((theme) => ({
    btnStyle: {
        position: 'absolute',
        right: '265px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#BBBBBB',
        border: '1px solid #FFFFFF',
        boxSizing: 'border-box',
        width: '150px',
        height: '32px',
        boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.15)',
        borderRadius: '5px',
        color: 'white',
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '21px',
        cursor: 'pointer',
        marginRight: '8px'
    }
}));

const HeaderBar = (props) => {
    const saveCameraPisition = useSceneStore((state) => state.saveCameraPisition);
    const classes = useStyles();
    const saveCameraPositionFunction = useSceneStore((state) => state.saveCameraPisition);
    const environmentMap = useEnvironmentStore((state) => state.environmentMap);
    const environmentMapPath = useEnvironmentStore((state) => state.environmentMapExplicitPath);
    const ambientIntensity = useEnvironmentStore((state) => state.ambientIntensity);
    const shadowOpacity = useEnvironmentStore((state) => state.shadowOpacity);
    const shadowBlur = useEnvironmentStore((state) => state.shadowBlur);
    const shadowBias = useEnvironmentStore((state) => state.shadowBias);
    const size = useSceneStore((state) => state.modelSize);
    const backgroundColor = useEnvironmentStore((state) => state.backgroundColor);
    const cameraPosition = useSceneStore((state) => state.cameraPosition);
    let history = useHistory();

    const saveModel = () => {
        props.setOpenBackdrop(true);
        var today = new Date();
        var data = props.designData;
        var body = {
            "designId": data.designId,
            "userId": data.userId,
            "designName": data.designName,
            "publishProducts": [],
            "thumbnail": props.thumbnail,
            "sceneInfo": {
                "modelPath": data.sceneInfo.modelPath,
                "cameraPosition": {
                    "x": cameraPosition.x,
                    "y": cameraPosition.y,
                    "z": cameraPosition.z
                },
                "environmentMapId": environmentMap,
                "environmentMapPath": environmentMapPath,
                "backgroundColor": backgroundColor,
                "ambientIntensity": ambientIntensity,
                "shadowOpacity": shadowOpacity,
                "shadowBlur": shadowBlur,
                "shadowBias": shadowBias
            },
            "enableDesign": true,
            "updatedAt": "2021-06-07T03:43:05.812Z",
            "createdAt": "2021-06-07T03:43:05.832Z",
            "shortId": "ncb1DuSA"
        };
        api.put(`designs/${props.designData.designId}`, body)
            .then(response => {
                if (response.message === 'success') {
                    props.setOpenBackdrop(false);
                };
            })
            .catch(error => {
                console.log('error', error);
                props.setOpenBackdrop(false);
            });
    };

    const titleStyle = {
        fontFamily: 'Poppins,sans-serif',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#FFFFFF',
    }

    const exitBtnStyle = {
        fontFamily: 'Poppins,sans-serif',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '21px',
        textAlign: 'center',
        color: '#EDEDED',
        background: '#4677E4',
        marginRight: '10px',
        borderRadius: '5px',
        textTransform: 'none',
        height: '40'
    }

    const saveBtnStyle = {
        fontFamily: 'Poppins,sans-serif',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '21px',
        textAlign: 'center',
        color: '#EDEDED',
        background: '#1B50C6',
        marginRight: '10px',
        borderRadius: '5px',
        textTransform: 'none',
        marginRight: '50px',
    }

    const savePreviewBtnStyle = {
        background: '#BBBBBB',
        border: '1px solid #FFFFFF',
        boxSizing: 'border-box',
        textAlign: 'center',
        width: '146px',
        boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.15)',
        borderRadius: '5px',
        color: 'white',
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '13px',
        lineHeight: '21px',
        cursor: 'pointer',
        marginRight: '20px',
    }

    return (
        <Box width="100%" height={50} display="flex" boxShadow={3} alignItems="center" justifyContent="space-between" style={{ backgroundColor: 'rgba(65, 65, 65, 1)' }}>
            <div style={{ flexBasis: '0', flexGrow: '1' }}>
                <img style={{ marginLeft: '120px' }} width="100px" height="24px" src={logo} />
            </div>
            <div style={{ flexBasis: '0', flexGrow: '1', textAlign: 'center' }}>
                <p style={titleStyle}>{props.designData && props.designData.designName}</p>
            </div>
            <div style={{ flexBasis: '0', flexGrow: '1', textAlign: 'right', paddingRight: '50px' }}>
                <Button disabled={props.designData ? false : true} style={savePreviewBtnStyle} className={classes.btnStyle} onClick={() => props.handleOnClickSavePreview()}>{props.captureText ? <ClipLoader color="#ffffff" css={override} size={22} /> : <CameraAltIcon style={{ fontSize: 20, marginRight: '10px' }} />} {props.captureText ? "Capturing..." : "Save Preview"} </Button>
                <Button disabled={props.designData ? false : true} style={exitBtnStyle} onClick={() => {
                    history.push({
                        pathname: `/updateDesign/${props.designData && props.designData.designId}`
                    });
                }} >Close</Button>
                <Button disabled={props.designData ? false : true} style={saveBtnStyle} data-test="save_design" onClick={() => saveModel()} >Save</Button>
            </div>
        </Box >
    )
}

export { HeaderBar }