import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, MenuItem, Typography, Select, Input,TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link, useHistory } from "react-router-dom";
import DeleteButton from '../../components/Design/DeleteButton';
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';
import { useEnvironmentStore, useSceneStore } from 'ogmo-renderer';
import SaveButton from '../../components/Design/SaveButton';
import DeleteConfirmDialog from './../../components/Design/DeleteConfirmDialog';
import GroupDesignDetails from '../../components/Design/GroupDesignDetails';
import DesignListDialogBox from '../../components/Design/DesignListDialogBox';
import CustomSnackBar from '../../components/Messages/CustomSnackBar';
import { useMessageStore } from '../../components/Messages/MessageStore/MessageStore';
import * as api from '../../services/api';
import ComingSoonAlert from '../../components/Design/CommingSoonAlert/ComingSoonAlert';
import LoaderElement from '../../components/PageLoader/LoaderElement';
import Backdrop from '@material-ui/core/Backdrop';
import axios from 'axios';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
    breadcrumb: {
        fontFamily: 'Poppins,sans-serif',
        lineHeight: '24px',
        color: '#263C67',
        fontSize: '16px',
        fontWeight: 500,
        cursor: 'pointer',
        textDecoration: 'none',
    },
    preview: {
        borderRadius: '4px',
        alignItems: 'center',
        height: '30vh',
        width: '40vw',
        border: 'solid 1px #C4C4C4',
        marginTop: '30px',
        marginBottom: '30px',
    },
    loader: {
        color: '#3183ae',
    },
    title: {
        fontFamily: 'Poppins,sans-serif',
        fontWeight: 500,
        fontSize: '26px',
        lineHeight: '36px',
        color: '#263C67',
        borderRadius: '5px',
        alignItems: 'center',
        width: 300,
        boxSizing: 'border-box',
        padding: 0
    },
    resize:{
        fontSize:26,
      },
    editbtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        boxSizing: 'border-box',
        background: '#3D6ACF',
        color: 'white',
        border: 'transparent',
        borderRadius: '5px',
        padding: '8px 16px',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: "rgba(29, 76, 146,0.8)",
        },
    },
    input: {
        fontSize: 14,
        color: '#263C67',
        fontFamily: 'Poppins',
        fontWeight: '500',
        borderRadius: '5px',
        alignItems: 'center',
        padding: '6px',
        width: 300,
        height: '40px',
        border: '2px solid #ECECEC',
        boxSizing: 'border-box',
    },
    thumbnailBtn: {
        backgroundColor: '#3D6ACF',
        padding: '3px 8px',
        border: '2px solid #3D6ACF',
        boxSizing: 'border-box',
        borderRadius: '5px',
        color: 'white',
        fontFamily: 'Poppins,sans-serif',
        lineHeight: '24px',
        fontSize: '12px',
        fontWeight: 500,
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: "rgba(29, 76, 146,0.8)",
        },
    },
    selectRoot: {
          '&:focus':{
            backgroundColor:'transparent'
          }
        }
}));

const UpdateGroupDesign = (props) => {

    const [designName, setDesignName] = useState('');
    const [designs, setDesigns] = useState([]);
    const [selectedDesign, setSelectedDesign] = useState([]);
    const [selectedDesignId, setSelectedDesignId] = useState('');
    const [isInProgress, setIsInProgress] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [image, setImage] = useState();
    const [bgColour, setBgColour] = useState();
    const [showDesignListDialogBox, setShowDesignListDialogBox] = useState(false);
    const [displayName, setDisplayName] = useState('');
    const dispatch = useMessageStore(state => state.dispatch);
    const [isDefault, setIsDefault] = useState(false);
    const [displayImage,setDisplayImage] = useState();
    const [onEditName,setOnEditName] = useState(false);
    const [thumbnailUrl,setThumbnailUrl] = useState();
    let history = useHistory();

    const setModelPath = useSceneStore((state) => state.setModelPath);
    const resetSceneDimensions = useSceneStore((state) => state.resetSceneDimensions);
    const setBackgroundColor = useEnvironmentStore((state) => state.setBackgroundColor);
    
    const classes = useStyles();

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 200,
            },
        },
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        }
    };

    useEffect(() => {
        setDesigns(props.location.state.data.singleModelDesigns);
        setDesignName(props.location.state.data.designName);
        setSelectedDesign(props.location.state.data.singleModelDesigns[0]);
        setSelectedDesignId(props.location.state.data.singleModelDesigns[0].designId);
        setBgColour({ [props.location.state.data.singleModelDesigns[0].designId]: '#808080' });
        setDisplayName(props.location.state.data.singleModelDesigns[0].name);
    }, []);

    useEffect(() => {
        if (thumbnailUrl) {
            let lengthOfDesigns = designs.length;
            let lengthOfThumbnailURLs = Object.keys(thumbnailUrl).length;
            if (lengthOfDesigns === lengthOfThumbnailURLs) {
                handleSubmit();
            }
        }
    }, [thumbnailUrl]);

    const handleNameChange = event => {
        setDesignName(event.target.value);
    };

    const handleChange = (event) => {
        const selectedDesignObj = designs.filter(val => val.designId === event.target.value);
        setSelectedDesignId(event.target.value);
        setSelectedDesign(...selectedDesignObj);
    };

    const handlePopupDesignList = () => {
        setShowDesignListDialogBox(true);
    };

    const handleCloseDesignListDialogBox = () => {
        setShowDesignListDialogBox(false);
    };

    const handleDeleteDesign = (deletedDesign) => {
        setModelPath(null);
        resetSceneDimensions();
        setBackgroundColor(null);
        setIsInProgress(true);
        if (designs.length > 1) {
            if (deletedDesign.thumbnail !== '') {
                removeAsset(deletedDesign).then(removeAssetResponse => {
                    if (removeAssetResponse.message === "success") {
                        manageDeleteDesign(deletedDesign);
                    } else {
                        dispatch({ type: 'error', message: 'An error occured.' });
                        setIsInProgress(false);
                    }
                });
            } else {
                manageDeleteDesign(deletedDesign);
            }
        } else {
            dispatch({ type: 'error', message: 'Group design must have at least one single design.' });
            setIsInProgress(false);
        }
    };

    const manageDeleteDesign = (deletedDesign) => {
        const newDesigns = designs.filter(design => design.designId !== deletedDesign.designId);
        setDesigns(newDesigns);
        setSelectedDesign(newDesigns[0]);
        setSelectedDesignId(newDesigns[0].designId);
        setIsInProgress(false);
    };

    const removeAsset = async (deletedDesign) => {
        let removeAssetResponse = api.post('common/removeAsset', {
            userId: props.location.state.data.userId,
            url: deletedDesign.thumbnail
        });
        return removeAssetResponse;
    };

    const handleDefaultDesign = (defaultDesign) => {
        const newDesigns = [...designs];
        newDesigns.sort(function (x, y) { return x == defaultDesign ? -1 : y == defaultDesign ? 1 : 0; });
        setDesigns(newDesigns);
    };

    const handleSharableLink = () => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_VIEWER_URL}${props.location.state.data.shortId}`).then(() => {
            dispatch({ type: 'success', message: 'Link Copied to Clipboard.' });
        });
    };

    async function fetchThumbnailFile(thumbnail) {
        let thumbnailImage = await axios.get(`${thumbnail}?x-request=blob`, {
            responseType: 'blob',
        });
        return thumbnailImage;
    };

    const uploadAsset = async (designId) => {
        let uploadAssetResponse = await api.post('common/uploadAsset', {
            dataType: "user-data",
            userId: props.location.state.data.userId,
            designId: designId,
            assetType: "design",
            assetName: "thumbnail.png"
        })
        return uploadAssetResponse;
    };

    const uploadAssetWithUrl = async (uploadUrl, file) => {
        const options = {
            headers: { 'Content-Type': 'image/*' },
        }
        let uploadAssetWithUrlResponse = await axios.put(uploadUrl, file, options)
        return uploadAssetWithUrlResponse;
    };

    const handleAssetUpload = (thumbnailImgFile, designId) => {
        uploadAsset(props.location.state.data.designId).then(uploadAssetResponse => {
            uploadAssetWithUrl(uploadAssetResponse.data.uploadUrl, thumbnailImgFile).then(uploadAssetWithUrlResponse => {
                if (uploadAssetWithUrlResponse.status === 200) {
                    const newArray = JSON.parse(JSON.stringify(designs));
                    newArray.forEach(element => {
                        if (element.designId === designId) {
                            element.thumbnail = uploadAssetResponse.data.downloadUrl
                        }
                    });
                    setDesigns(newArray);
                    setThumbnailUrl(prevState => ({ ...prevState, [designId]: uploadAssetResponse.data.downloadUrl }));
                }
            })
        }).catch(error => {
            console.log('Faild to uploadAsset: ', error.message);
        });
    };

    const handleUpdateGroupDesign = async () => {
        if (designs.length > 0) {
            setIsInProgress(true);
                    if (image) {
                        designs.forEach(element => {
                            const designId = element.designId;
                            if (image.hasOwnProperty(designId)) {
                                const thumbnailImgFile = image[designId];
                                if(thumbnailImgFile.type){
                                    if (element.thumbnail !== '') {
                                        api.post('common/removeAsset', {
                                            userId: props.location.state.data.userId,
                                            url: element.thumbnail
                                        }).then((data) => {
                                            if (data.message === "success") {
                                                handleAssetUpload(thumbnailImgFile, designId);
                                            }
                                        });
                                    } else {
                                        handleAssetUpload(thumbnailImgFile, designId);
                                    }
                                }else{
                                    if (element.thumbnail !== '') {
                                        fetchThumbnailFile(thumbnailImgFile).then(thumbnail => {
                                            let thumbnailImage = thumbnail.data;
                                            handleAssetUpload(thumbnailImage, designId);
                                        })
                                    }else{
                                        setThumbnailUrl(prevState => ({ ...prevState, [designId]: '' }));
                                    }
                                }
                            }else{
                                setThumbnailUrl(prevState => ({ ...prevState, [designId]: '' }));
                            }
                        });
                    } else {
                        handleSubmit();
                    }
        } else {
            dispatch({ type: 'error', message: 'Group design must have at least one single design.' });
        }
    };

    const handleSubmit = () => {

        let _id = 0
        designs.map(design => {
            _id = _id + 1
            design.id = _id;
            design.displayType = design.displayType=='thumbnail' ? design.thumbnail ? design.displayType : 'color' : design.displayType;
            delete design.designName;
        });

        setIsInProgress(true);
        api.put(`designs/${props.location.state.data.designId}`, {
            designId: props.location.state.data.designId,
            userId: props.location.state.data.userId,
            designName: designName,
            publishProducts: [],
            thumbnail: "",
            enableDesign: true,
            updatedAt: "",
            createdAt: "",
            shortId: "",
            isMultiModelDesign: true,
            multiModelDesigns: [],
            singleModelDesigns: designs
        }).then(result => {
            if (result.message === 'success') {
                setIsInProgress(false);
                history.push({
                    pathname: '/designs/'
                });
            }
        }).catch(error => console.log(error));
    };

    const handleDesignDelete = () => {
        setDialogOpen(false);
        api.remove(`designs/${props.location.state.data.designId}`)
            .then(data => {
                if (data.message === 'success') {
                    history.push({
                        pathname: '/designs/'
                    });
                }
            }).catch(error => console.log(error));
    };

    return (
        <Box>
            <DeleteConfirmDialog open={dialogOpen} setOpen={setDialogOpen} handleDelete={handleDesignDelete} selected={[props.location.state.data]} />
            {isInProgress && (
                <Backdrop  style={{ zIndex: 10000 }} open={isInProgress}>
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <LoaderElement />
                    </Box>
                </Backdrop>


            )}
            
            {designs && (
                <Box px={4} pb={0} height="100%" display="flex" flexDirection="column">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                            >
                                <Link to={'/designs'}
                                    className={classes.breadcrumb}
                                >
                                    Designs {`>`}
                                </Link>
                            </Breadcrumbs>
                        </div>
                        <div style={{ display: 'flex', alignItems: "center" }}>
                            <DeleteButton handleSubmit={() => setDialogOpen(true)} />
                            <div style={{ marginLeft: '30px' }}>
                                <SaveButton handleSubmit={handleUpdateGroupDesign} />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: "center" }}>
                    <div>
                        {!onEditName ? <Typography
                            className={classes.title}
                            color="textPrimary"
                        >
                            {designName}
                        </Typography>
                            :
                            <FormControl >
                                <TextField
                                    InputProps={{
                                        classes: {
                                            input: classes.resize,
                                        },
                                    }}
                                    className={classes.title}
                                    placeholder="Group Design"
                                    onChange={handleNameChange}
                                    value={designName} />
                            </FormControl>
                        }
                    </div>
                        <div style={{ marginLeft: '10px' }}>
                            <EditIcon style={{ color: "#263C67", cursor: 'pointer' }} onClick={()=> setOnEditName(!onEditName)} />
                        </div>
                        <div style={{ marginLeft: '15px' }}>
                            <LinkIcon style={{ color: "#263C67", cursor: 'pointer' }} onClick={() => handleSharableLink()} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: "center", marginTop: '56px' }}>
                        <div style={{ width: '150px' }}>
                            <Typography
                                variant="subtitle1"
                            >
                                Designs
                            </Typography>
                        </div>
                        <div style={{ marginRight: '15px' }}>
                            <FormControl>
                                <Select
                                    classes={{ root: classes.selectRoot }}
                                    className={classes.input}
                                    MenuProps={MenuProps}
                                    value={selectedDesignId}
                                    displayEmpty
                                    disableUnderline
                                    onChange={handleChange}
                                    disabled={isInProgress}
                                >
                                    <MenuItem disabled>Select design</MenuItem>
                                    {designs.map((design, index) => (
                                        <MenuItem key={design.designId} value={design.designId}>{design.designName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <button className={classes.thumbnailBtn} onClick={() => handlePopupDesignList()} disabled={isInProgress}>Add design to group</button>
                        </div>
                    </div>
                    <GroupDesignDetails setDisplayImage={setDisplayImage} displayImage={displayImage} isDefault={isDefault} setIsDefault={setIsDefault} setDisplayName={setDisplayName} displayName={displayName} isInProgress={isInProgress} setIsInProgress={setIsInProgress} setImage={setImage} image={image} bgColour={bgColour} setBgColour={setBgColour} setDesign={setSelectedDesign} design={selectedDesign} handleDeleteDesign={handleDeleteDesign} handleDefaultDesign={handleDefaultDesign} groupDesignData={props.location.state.data} setGroupDesigns={setDesigns} groupDesigns={designs} />
                    <DesignListDialogBox showDialogBox={showDesignListDialogBox} handleClose={handleCloseDesignListDialogBox} setGroupDesigns={setDesigns} groupDesigns={designs} groupDesignData={props.location.state.data} isInProgress={isInProgress} setIsInProgress={setIsInProgress} setImage={setImage} image={image} setSelectedDesignId={setSelectedDesignId} selectedDesignId={selectedDesignId} setSelectedDesign={setSelectedDesign} />
                </Box>
            )}
            <CustomSnackBar />
        </Box>
    );
};

export default UpdateGroupDesign;
