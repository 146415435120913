import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Box, Button, Grid, IconButton, TextField, Typography,Input } from '@material-ui/core';
import { PhotoCamera } from '@material-ui/icons';
import defaultPic from '../../images/user.png';
import { Auth } from 'aws-amplify';
import * as api from '../../services/api';
import InputLabel from '@material-ui/core/InputLabel';
import CustomBreadCrumb from './../../components/Settings/CustomBreadCrumb/CustomBreadCrumb';
import Badge from '@material-ui/core/Badge';

import PageLoader from './../../components/PageLoader/PageLoader'

import useUserStore from './../../components/User/UserStore';

import { UNKNOWN_USER_FIRST_NAME } from './../../util/constants';
import { checkUsernameIsLimited } from './../../util/';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  breadcrumb: {
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  },
  root: {
    backgroundColor: theme.palette.background.default,
    paddingLeft:'20px',
    paddingRight:'20px',
  },
  header: {
    color: '#263C67',
    fontSize: '34px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(10),
  },
  text: {
    color: '#263C67',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(2),
    color: theme.palette.info.dark,
  },
  large: {
    width: theme.spacing(19),
    height: theme.spacing(19),
  },
  textfield: {
    width: theme.spacing(46),
    width: "100%",
    maxWidth: "376px",
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#263C67',
    height:'40px',
    border: '2px solid #ECECEC',
    boxSizing: 'border-box',
    borderRadius: '5px',
    paddingLeft:'20px',
  },
  input: {
    display: 'none'
  },
  inputbg:{
    background: '#ECECEC',
  },
  iconbutton: {
    paddingLeft: theme.spacing(16)
  },
  button: {
    boxSizing: 'border-box',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '5px',
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(2),
    fontFamily: 'Poppins',
    textTransform: 'capitalize',
    padding:'8px 16px',
    fontSize: "14px",
    fontWeight: 500,
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
    },
  },
  badge:{
    background:'#E2E2E2',
    '&:hover': {
      background:'#E2E2E2',
    },
  },
  disabledStyle: {
    fontSize: 14,
    color:'#263C67',
    fontFamily: 'Poppins',
    fontWeight: '500',
  },
}))

const UserProfile = () => {
  const classes = useStyles();
  const [user, setUser] = useState({ userFirstName: UNKNOWN_USER_FIRST_NAME, userEmail: UNKNOWN_USER_FIRST_NAME });

  const [isInProgress, setIsInProgress] = useState(false);
  const [isUserFieldsChanged, setIsUserFieldsChanged] = useState(false);
  const [file, setFile] = useState(null);

  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);

  const setProfileAvatar = useUserStore(state => state.setProfileAvatar);
  const setUserFirstName = useUserStore(state => state.setUserFirstName);
  const setUserEmail = useUserStore(state => state.setUserEmail);
  const loggedInUserDetail = useUserStore(state => state.loggedInUserDetail);

  useEffect(() => {
    setIsInProgress(true)
    if(loggedInUserDetail.userFirstName !== UNKNOWN_USER_FIRST_NAME) {
      getAuthUser();
    }
  }, [loggedInUserDetail.userFirstName])

  async function getAuthUser() {
    try {
      const auth = await Auth.currentAuthenticatedUser();  
      if (auth) {
        if(!checkUsernameIsLimited(auth.username)){
          setIsUpdateDisabled(false);
        } else {
          setIsUpdateDisabled(true);
        }

        getUser();
      }
    }
    catch (err) {
      console.log('Auth user error: ', err);
      setIsInProgress(false);
    }
  }

  function getUser() {
    setUser(loggedInUserDetail);
    setIsInProgress(false);
  }

  const setFirstName = (e) => {
    setIsUserFieldsChanged(true);
    const val = e.target.value;
    setUser(prevState => {
      return { ...prevState, userFirstName: val }
    });
  }

  const setEmail = (e) => {
    setIsUserFieldsChanged(true);
    const val = e.target.value;
    setUser(prevState => {
      return { ...prevState, userEmail: val }
    });
  }

  const handleImgChange = (e) => {
    if(0 < e.target.files.length) {
      let file = e.target.files[0];
      setFile(file);
      setUser(prevState => {
        return { ...prevState, userLogo: URL.createObjectURL(file) }
      });
    } else {
      setFile(null);
      setUser(prevState => {
        return { ...prevState, userLogo: loggedInUserDetail.userLogo }
      });
    }
  };

  async function handleSubmit(e) {
    if ((file !== null && isUserFieldsChanged) || file !== null) {
      setIsInProgress(true);
      if (loggedInUserDetail.userLogo && loggedInUserDetail.userLogo !== "") {
        await api.post('common/removeAsset',
          {
            userId: loggedInUserDetail.userName,
            url: loggedInUserDetail.userLogo
          })
          .then(_ => {
            uploadAsset();
          })
          .catch(error => {
            console.log('removeAsset Error: ', error.message);
            setIsInProgress(false);
          });
      } else {
        uploadAsset();
      }
    } else if(file === null && isUserFieldsChanged) {
      setIsInProgress(true);
      updateUser();
    } else {
      console.log('Nothing has been changed to Update!');
    }
  }

  async function uploadAsset() {
    api.post('common/uploadAsset', {
      dataType: "user-data",
      userId: loggedInUserDetail.userName,
      designId: "",
      assetType: "profile",
      assetName: file.name
    })
      .then(data => {
        uploadAssetWithUrl(data.data.uploadUrl, data.data.downloadUrl);
      })
      .catch(error => {
        setIsInProgress(false);
        console.log('Faild to uploadAsset: ', error.message);
      });
  }

  function uploadAssetWithUrl(uploadUrl, downloadUrl) {
    fetch(uploadUrl, {
      method: "PUT", body: file, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded' }
    })
      .then(_ => {
        setProfileAvatar(downloadUrl);
        setUser(prevState => {
          return { ...prevState, userLogo: downloadUrl }
        }); 
        updateUser(downloadUrl);
      })
      .catch((error) => {
        setIsInProgress(false);
        console.log('uploadAssetWithUrl API call err: ', error)
      });
  }

  const updateUser = (profilePicture) => {

    api.put(`users/${loggedInUserDetail.userName}`, {
        userName : loggedInUserDetail.userName,
        userEmail : user.userEmail,
        userFullName: loggedInUserDetail.userFullName,
        userFirstName: user.userFirstName,
        userLogo : user.userLogo === null ? null : profilePicture ? profilePicture : user.userLogo 
    })
      .then(_ => {
        console.log('updateUser: ',_);
        setUserFirstName(user.userFirstName);
        setUserEmail(user.userEmail);
        setIsInProgress(false);
        setFile(null);
        setIsUserFieldsChanged(false);
      })
      .catch(error => {
        setIsInProgress(false);
        console.log('Faild to updateUser: ', error.message);
      });
  };

  if (isInProgress) {
    return(
      <PageLoader/>
    );
  } else {
    return (
      <Box className={classes.root}>
        <CustomBreadCrumb breadcrumbs={[
          {text: "Settings", isCurrent: false},
          {text: "User Profile", isCurrent: true},
        ]} style={{marginBottom: "30px"}}/>
        <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={<div><input accept="image/*" className={classes.input} id="icon-button-file" type="file" onChange={handleImgChange} />
          <label htmlFor="icon-button-file"><IconButton color="primary" aria-label="upload picture" component="span" className={classes.badge}>
              <PhotoCamera />
            </IconButton></label></div>} >
          <Avatar alt="Remy Sharp" src={user.userLogo==="" ? defaultPic : user.userLogo} className={classes.large} />
        </Badge>
        <Grid container>
          <Grid item xs={12} md={4} style={{marginRight:'20px'}}>
            <InputLabel className={classes.text} >User Name</InputLabel>
            <Input classes={{disabled:classes.disabledStyle}} disableUnderline className={classes.textfield} id="user-name" value={user.userFirstName} onChange={setFirstName} disabled={isUpdateDisabled} />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputLabel className={classes.text} >Email</InputLabel>
            <Input classes={{disabled:classes.disabledStyle}} disableUnderline className={`${classes.textfield} ${classes.inputbg}`} id="user-name" value={user.userEmail} onChange={setEmail} disabled={true} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button className={classes.button} onClick={handleSubmit}>Save</Button>
        </Grid>
      </Box>
  
    );
  }
};

export default UserProfile;
