import React,{useState,useEffect,useRef} from 'react';
import { Box,Breadcrumbs,Typography,Select,MenuItem,FormControl,Input,TextField } from '@material-ui/core';
import { createGenerateClassName, makeStyles } from '@material-ui/styles';
import { Link } from "react-router-dom";
import DeleteButton from '../../components/Design/DeleteButton';
import SaveButton from '../../components/Design/SaveButton';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import LinkIcon from '@material-ui/icons/Link';
import GroupDesignDetails from '../../components/Design/GroupDesignDetails';
import { useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import * as api from '../../services/api';
import DesignListDialogBox from '../../components/Design/DesignListDialogBox';
import axios from 'axios';
import CustomSnackBar from '../../components/Messages/CustomSnackBar';
import { useMessageStore } from '../../components/Messages/MessageStore/MessageStore';
import { useHistory } from "react-router-dom";
import LoaderElement from '../../components/PageLoader/LoaderElement';
import DeleteConfirmDialog from './../../components/Design/DeleteConfirmDialog';
import Backdrop from '@material-ui/core/Backdrop';
import { useEnvironmentStore, useSceneStore } from 'ogmo-renderer';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
    breadcrumb: {
      fontFamily: 'Poppins,sans-serif',
      lineHeight: '24px',
      color: '#263C67',
      fontSize: '16px',
      fontWeight: 500,
      cursor: 'pointer',
      textDecoration: 'none',
    },
    preview: {
      borderRadius: '4px',
      alignItems: 'center',
      height: '30vh',
      width: '40vw',
      border: 'solid 1px #C4C4C4',
      marginTop: '30px',
      marginBottom: '30px',
    },
    loader: {
      color: '#3183ae',
    },
    title: {
      fontFamily: 'Poppins,sans-serif',
      fontWeight: 500,
      fontSize: '26px',
      lineHeight: '36px',
      color: '#263C67',
      width: '300px',
    },
    resize:{
        fontSize:26,
      },
      thumbnailDiv: {
        width: 40,
        height: 40,
        border: 'none',
        borderRadius: '100%',
        objectFit: 'cover' ,
      },
    editbtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      boxSizing: 'border-box',
      background: '#3D6ACF',
      color: 'white',
      border: 'transparent',
      borderRadius: '5px',
      padding:'8px 16px',
      cursor:'pointer',
      "&:hover": {
        backgroundColor: "rgba(29, 76, 146,0.8)",
      },
    },
    input: {
        fontSize: 14,
        color:'#263C67',
        fontFamily: 'Poppins',
        fontWeight: '500',
        borderRadius: '5px',
        alignItems: 'center',
        padding: '6px',
        width: 300,
        height:'40px',
        border: '2px solid #ECECEC',
        boxSizing: 'border-box',
      },
    thumbnailBtn: {
        backgroundColor: '#3D6ACF',
        padding:'3px 8px',
        border: '2px solid #3D6ACF',
        boxSizing: 'border-box',
        borderRadius: '5px',
        color: 'white',
        fontFamily: 'Poppins,sans-serif',
        lineHeight: '24px',
        fontSize: '12px',
        fontWeight: 500,
        cursor:'pointer',
        "&:hover": {
          backgroundColor: "rgba(29, 76, 146,0.8)",
        },
      },
      selectRoot: {
          '&:focus':{
            backgroundColor:'transparent'
          },
        }
  }));

function GroupDesign(props) {

    const hiddenFileInput = useRef(null);
    const classes = useStyles();
    const location = useLocation();
    const [designs, setDesigns] = useState([]);
    const [designName, setDesignName] = useState("Group Design");
    const [designInfo, setDesignInfo] = useState("");
    const [selectedDesign, setSelectedDesign] = useState();
    const [authuser, setAuthuser] = useState('');
    const [showDesignListDialogBox, setShowDesignListDialogBox] = useState(false);
    const [totalDesignCount, setTotalDesignCount] = useState(0);
    const [isDefault,setIsDefault] = useState(false)
    const [bgColour,setBgColour]  = useState();
    const [image,setImage] = useState();
    const [thumbnailUrl,setThumbnailUrl] = useState();
    const [displayName, setDisplayName] = useState('');
    const [onEditName,setOnEditName] = useState(false);
    const dispatch = useMessageStore(state => state.dispatch);
    const [groupDesignThumbnail,setGroupDesignThumbnail] = useState();
    const [groupDesignThumbnailUrl,setGroupDesignThumbnailUrl] = useState();
    const [isInProgress, setIsInProgress] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [displayImage,setDisplayImage] = useState();
    let history = useHistory();
    let designId = ''  
    let groupDesigns = []

    const setModelPath = useSceneStore((state) => state.setModelPath);
    const resetSceneDimensions = useSceneStore((state) => state.resetSceneDimensions);
    const setBackgroundColor = useEnvironmentStore((state) => state.setBackgroundColor);

    useEffect(() => {

        if (designs.length !== 0) {
            designs.map(singleDesign => {
                if (singleDesign.thumbnail) {
                    setImage(prevState => ({ ...prevState, [singleDesign.designId]: singleDesign.thumbnail }))
                    setBgColour(prevState => ({ ...prevState, [singleDesign.designId]: singleDesign.color }))
                }else{
                    setBgColour(prevState => ({ ...prevState, [singleDesign.designId]: singleDesign.color }))
                }
            })
        }
    }, [designs])
    
    async function fetchThumbnailFile(thumbnail) {

        let thumbnailImage = await axios.get(`${thumbnail}?x-request=blob`, {
            responseType: 'blob',
        })
        return thumbnailImage;
    }

    const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: 200,
          },
        },
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        }
      };

    const handleChange = (event) => {
        setSelectedDesign(event.target.value);
    };
 
    async function getAuthUser() {
        try {
            const auth = await Auth.currentAuthenticatedUser();
            if (auth) {
                setAuthuser(auth);
            }
        }
        catch (err) {
            console.log('Auth user error: ', err)
        }
    }

    useEffect(() => {
        getAuthUser()
    }, [])

    const handleGroupDesignDelete = event => {
        history.push({
            pathname: `/designs/`
        });
      };

    const handleDeleteDesign = (deletedDesign) => {
        if (designs.length > 1) {
            const newDesigns = designs.filter(design => design.designId !== deletedDesign.designId);
            setImage(null)
            setModelPath(null);
            resetSceneDimensions();
            setBackgroundColor(null);
            setDesigns(newDesigns);
            setSelectedDesign(newDesigns[0]);
        } else {
            dispatch({ type: 'error', message: 'Group design must have at least one single design.' });
        }
    }

    const handleDefaultDesign = (defaultDesign) => {
  
        designs.sort(function(x,y){ return x == defaultDesign ? -1 : y == defaultDesign ? 1 : 0; });
        setIsDefault(true)  
    }

    const handleNameChange = event => {
        setDesignName(event.target.value);
      };

    const handlePopupDesignList = () => {
        setShowDesignListDialogBox(true);
    };

    const handleCloseDesignListDialogBox = () => {
        setShowDesignListDialogBox(false);
    };

    const handleSharableLink = () => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_VIEWER_URL}${designInfo.shortId}`).then(() => {
            dispatch({ type: 'success', message: 'Link Copied to Clipboard.' });
        });
    };

    const handleFileInput = (event) => {
        setGroupDesignThumbnail(URL.createObjectURL(event.target.files[0]));
        setGroupDesignThumbnailUrl(event.target.files[0]);
    };

    useEffect(() => {
        if (thumbnailUrl) {
            designId = designInfo.designId;
            let lengthOfThumbnails = Object.keys(image).length;
            let lengthOfThumbnailURLs = Object.keys(thumbnailUrl).length;

            if (lengthOfThumbnails === lengthOfThumbnailURLs) {
                handleSubmit();
            }
        }
    }, [thumbnailUrl])

    const newGroupDesign = 
        {
            userId: authuser.username,
            designName: designName ,
            publishProducts: [],
            thumbnail:"",
            enableDesign:true,
            updatedAt:"",
            createdAt:"",
            shortId:"",
            isMultiModelDesign: true,
            multiModelDesigns:[],
            singleModelDesigns: []
         
      };

    useEffect(() => {
        if (selectedDesign) {
            setDisplayName(selectedDesign.name)
            setModelPath(null);
            resetSceneDimensions();
            setBackgroundColor(null);
        }
        if (designs) {

            if (designs.indexOf(selectedDesign) === 0) {
                setIsDefault(true)
            }
            else {
                setIsDefault(false)
            }
        }
    }, [selectedDesign])

    const uploadAsset = async (designId) => {
     
       let data= await api.post('common/uploadAsset', {
          dataType: "user-data",
          userId: authuser.username,
          designId: designId,
          assetType: "design",
          assetName: "thumbnail.png"
        })
        return data  
      };

      const uploadAssetWithUrl= async (uploadUrl,file) => {
        const options = {
          headers: {'Content-Type': 'image/*'},
        } 
        let response = await axios.put(uploadUrl,file,options)
        return response;
      }
   
    const submitGroupDesign = async () => {
        if (designs.length > 0) {
            setIsInProgress(true)
            api.post('designs', newGroupDesign)
                .then(data => {

                    setDesignInfo(data.data)
                    designId = data.data.designId;
                    if (image) {
                        let _designId = ''

                        designs.map((singleDesign) => {
                            _designId = singleDesign.designId
                            let thumbnailImgFile = image[_designId] ? image[_designId] : ''
                            if (thumbnailImgFile !== '') {
                                if (thumbnailImgFile.type) {
                                    uploadAsset(designId).then(data => {

                                        setThumbnailUrl(prevState => ({ ...prevState, [singleDesign.designId]: data.data.downloadUrl }))
                                        uploadAssetWithUrl(data.data.uploadUrl, thumbnailImgFile)

                                    }).catch(error => {

                                        console.log('Faild to uploadAsset: ', error.message);
                                    });
                                }
                                else {

                                    fetchThumbnailFile(thumbnailImgFile)
                                        .then(thumbnail => {
                                            let thumbnailImage = thumbnail.data;
                                            uploadAsset(designId).then(data => {
                                                setThumbnailUrl(prevState => ({ ...prevState, [singleDesign.designId]: data.data.downloadUrl }))
                                                uploadAssetWithUrl(data.data.uploadUrl, thumbnailImage)

                                            }).catch(error => {

                                                console.log('Faild to uploadAsset: ', error.message);
                                            });
                                        })
                                }
                            }
                        })
                    }
                    else {
                        handleSubmit();
                    }
                })
                .catch(error => {
                    console.log('Faild to create group design: ', error.message);
                    setIsInProgress(false)
                });
        } else {
            dispatch({ type: 'error', message: 'Group design must have at least one single design.' });
        }
    }
    
    
    const handleSubmit = () => {
        let _id = 0
        let _designId = ''

        designs.map((singleDesign) => {
            _designId = singleDesign.designId
            _id = _id + 1

            groupDesigns.push({
                id: _id,
                name: singleDesign.name,
                color: bgColour[_designId] ? bgColour[_designId] : '#808080',
                thumbnail: thumbnailUrl ? thumbnailUrl[_designId] ? thumbnailUrl[_designId] : '' : '',
                designId: singleDesign.designId,
                shortId: singleDesign.shortId,
                displayType : singleDesign.displayType=='thumbnail' ? singleDesign.thumbnail ? singleDesign.displayType : 'color' : singleDesign.displayType, 

            })
        })
        newGroupDesign.singleModelDesigns = groupDesigns;
        updateGroupDesign()
    };

    async function updateGroupDesign() {
        try {
            let data = await api.put(`designs/${designId}`,
                {
                    userId: authuser.username,
                    designName: designName,
                    publishProducts: [],
                    thumbnail: '',
                    enableDesign: true,
                    updatedAt: "",
                    createdAt: "",
                    shortId: "",
                    isMultiModelDesign: true,
                    multiModelDesigns: [],
                    singleModelDesigns: groupDesigns

                })
            setIsInProgress(false)
            history.push({
                pathname: `/designs/`
            });
            groupDesigns = []
        }
        catch (error) {
            console.log('Faild to updateGroupDesign: ', error.message);
            groupDesigns = []
        }
    };

    return (
        <Box height="100%">
            
            <DeleteConfirmDialog open={dialogOpen} setOpen={setDialogOpen} handleDelete={handleGroupDesignDelete} selected={designs} groupDesign={true} />
            {isInProgress && (
                <Backdrop  style={{ zIndex: 10000 }} open={isInProgress}>
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <LoaderElement />
                    </Box>
                </Backdrop>


            )}
            <CustomSnackBar />
            <Box px={4} pb={0} height="100%" display="flex" flexDirection="column">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>
                        <Breadcrumbs
                            aria-label="breadcrumb"
                        >
                            <Link to={'/designs'}
                                className={classes.breadcrumb}
                            >
                                Designs {`>`}
                            </Link>
                        </Breadcrumbs>
                    </div>
                    <div style={{ display: 'flex', alignItems: "center" }}>
                        <DeleteButton handleSubmit={() => setDialogOpen(true)}/>
                        <div style={{ marginLeft: '30px' }}>
                            <SaveButton handleSubmit={submitGroupDesign} />
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: "center" }}>
                    <div>
                        {!onEditName ? <Typography
                            className={classes.title}
                            color="textPrimary"
                        >
                            {designName}
                        </Typography>
                            :
                            <FormControl >
                                <TextField
                                    InputProps={{
                                        classes: {
                                            input: classes.resize,
                                        },
                                    }}
                                    className={classes.title}
                                    placeholder="Group Design"
                                    onChange={handleNameChange}
                                    value={designName} />
                            </FormControl>
                        }
                    </div>
                    <div style={{ marginLeft: '10px' }}>
                        <EditIcon style={{ color: "#263C67",cursor: 'pointer'}} onClick={()=> setOnEditName(!onEditName)}/>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: "center", marginTop: '56px' }}>
                    
                        <div style={{ width: '150px' }}>
                            <Typography
                                variant="subtitle1"
                            >
                                Designs
                            </Typography>
                        </div>
                    {selectedDesign && <div style={{ marginRight: '15px' }}>
                        <FormControl>
                            <Select
                                classes={{ root: classes.selectRoot }}
                                className={classes.input}
                                MenuProps={MenuProps}
                                value={selectedDesign}
                                displayEmpty
                                disableUnderline
                                onChange={handleChange}
                            >
                                <MenuItem disabled>Select design</MenuItem>
                                {designs.map((design, index) => (
                                    <MenuItem key={index}  value={design} >{design.designName}</MenuItem>
                                 ))} 
                            </Select>
                        </FormControl>
                    </div>
                    }
                     {!selectedDesign && <div style={{ marginRight: '15px' }}>
                        <FormControl>
                            <Select
                                className={classes.input}
                                MenuProps={MenuProps}
                                value={selectedDesign}
                                displayEmpty
                                disableUnderline
                                onChange={handleChange}
                            >
                                <MenuItem disabled>Select design</MenuItem>
                                {designs.map((design, index) => (
                                    <MenuItem key={index}  value={design} >{design.name}</MenuItem>
                                 ))} 
                            </Select>
                        </FormControl>
                    </div>
                    }
                    <div>
                        <button className={classes.thumbnailBtn} onClick={() => handlePopupDesignList()}>Add design to group</button>
                    </div>

                </div>
                <GroupDesignDetails displayImage ={displayImage}  setDisplayImage={setDisplayImage} setDisplayName={setDisplayName} displayName={displayName} setImage={setImage} image={image} bgColour={bgColour} setBgColour={setBgColour} design={selectedDesign} setDesign={setSelectedDesign} handleDeleteDesign={handleDeleteDesign} handleDefaultDesign={handleDefaultDesign} isDefault={isDefault} designsList={designs}/>
                <DesignListDialogBox showDialogBox={showDesignListDialogBox} handleClose={handleCloseDesignListDialogBox} setGroupDesigns={setDesigns} groupDesigns={designs} setGroupDesigns={setDesigns} totalDesignCount={totalDesignCount} setTotalDesignCount={setTotalDesignCount} setSelectedDesign={setSelectedDesign} />
            </Box>
        </Box>
    );
}

export default GroupDesign;