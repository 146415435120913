import React, { useEffect, useRef, useState } from 'react'
import { useGLTF, useHelper } from '@react-three/drei'
import { BoxHelper } from 'three'
import { useSceneStore } from './Store'
import * as THREE from 'three'

const Helper = (props) => {
    useHelper(props.objectRef, BoxHelper, 'cyan')
    return (<group />)
}

const Model = (props) => {
    const { scene, nodes, materials, animations } = useGLTF(props.path)
    const [selected, setSelected] = useState(false)
    const objectRef = useRef(null)

    const setSceneDimensions = useSceneStore((state) => state.setSceneDimensions)

    useEffect(()=> {
        
        const box = new THREE.Box3().setFromObject(scene);
        const center = box.getCenter(new THREE.Vector3());
        const size = box.getSize(new THREE.Vector3()).length();

        setSceneDimensions({x: center.x, y: center.y, z: center.z}, 
            size,
            {x: box.min.x, y: box.min.y, z: box.min.z},
            {x: box.max.x, y: box.max.y, z: box.max.z});
    },[scene])

    return (
        <primitive castShadow ref={objectRef} object={scene} onClick={(event) => setSelected(!selected)}>
            {/* {selected && <Helper objectRef={objectRef} />} */}
        </primitive>
    )
}

export { Model }