import React, { useEffect } from 'react'
import { useThree } from '@react-three/fiber'
import * as THREE from 'three'
import { useEnvironmentStore } from './Store';

const Background = () => {

    const { scene } = useThree();
    const backgroundColor = useEnvironmentStore((state) => state.backgroundColor)

    useEffect(()=> {
        scene.background = new THREE.Color(backgroundColor)
    }, [backgroundColor])

	return (
        <group/>
    )
}

export { Background }

