import React,{useEffect} from 'react';
import {
  Typography,
  TableCell,
  TableRow,
  Tooltip,
  Box,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import Thumbnail from '../../images/thumbnail.png';
import * as api from '../../services/api';
import LinkIcon from '@material-ui/icons/Link';

const sharableLinkEndpoint = process.env.REACT_APP_VIEWER_URL;

const useStyles = makeStyles(theme => ({
  inner: {
    minWidth: 1050
  },
  avatar: {
    height: '44px',
    width: '44px',
    borderRadius: 0
  },
  image: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    height: '45px',
    width: '45px',
    borderRadius: '50%'
  },
  menuIcon: {
    marginRight: theme.spacing(1),
    color: '#C4C4C4'
  },
  designPublish: {
    fontWeight: designUseStyleValue =>
      designUseStyleValue.designPublished ? 'bold' : 'normal'
  },
  designName: {
    width: '50%'
  },
  okButton: {
    backgroundColor: '#3e8eb8',
    color: '#ffffff',
    '&:hover': { backgroundColor: '#24739C' }
  },
  cancelButton: {
    color: '#3e8eb8',
  },
  loader: {
    color: '#3183ae'
  },
  linkTitle: {
    fontWeight: 600,
    fontSize: 13,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  popupText: {
    fontSize: 12,
    marginLeft: theme.spacing(1),
    width: 250,
    backgroundColor: '#d9ebf9'
  },
  textstyle: {
    color: '#263C67',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '19px',
  },
  thumbnail: {
    height: '40px',
    borderRadius: '5px',
  },
  thumbDiv: {
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    background: '#F0F0F0',
    borderRadius: '5px',
    marginRight:'30px',
  },
  checkboxStyle: {
    color: '#AEAEAE',
  },
  rowcellStyle:{
    cursor:'pointer',
  }
}));

export default function DesignsTableRow({
  design, handleCheckboxClick, isItemSelected, openSnackBar, setShowGroupDesignDialog
}) {
  const classes = useStyles();
  const history = useHistory();

  const updatedOn = new Date(design.updatedAt);
  const updatedDate = updatedOn.toLocaleDateString();
  const updatedTime = updatedOn.toLocaleTimeString();

  const date = new Date(design.createdAt);
  const createdDate = date.toLocaleDateString();
  const createdTime = date.toLocaleTimeString();

  const handleClick = event => {
    event.preventDefault();
    if (design.isMultiModelDesign) {
      history.push({
        pathname:`/updateGroupDesign/${design.designId}`,
        state: { data: design }
      });
    }else{
      history.push(`/updateDesign/${design.designId}`)
    }
  };
  const copyLink =  data => {
    asyncCopy(data);
    openSnackBar()
  }

  const asyncCopy = async (data) => {
    await navigator.clipboard.writeText(sharableLinkEndpoint + data.shortId);
  }

  return (

    <TableRow
      hover
      key={design.id}
      role="checkbox"
      selected={isItemSelected}>
         <TableCell padding="checkbox">
            <Checkbox
            className={classes.checkboxStyle}
            checked={isItemSelected}
            onChange={(event) => handleCheckboxClick(event, design)} />
          </TableCell>
      <TableCell className={`${classes.designName} ${classes.rowcellStyle}`} onClick={event => handleClick(event)}>
        <Box
          alignItems="center"
          display="flex"
        >
          <div className={classes.thumbDiv}><img className={classes.thumbnail} src={design.thumbnail ? design.thumbnail: Thumbnail} alt={'design thumbnail'}/></div>
          <Typography className={classes.textstyle}>{design.designName}</Typography>
        </Box>
      </TableCell>
      <TableCell className={classes.rowcellStyle} display="flex" onClick={event => handleClick(event)}>
        <Typography className={classes.textstyle}>{design.isMultiModelDesign ? 'Group' : 'Single'}</Typography>
      </TableCell>
      <TableCell className={classes.rowcellStyle} display="flex" onClick={event => handleClick(event)}>
        <Typography className={classes.textstyle}>{createdDate === 'Invalid Date' ? updatedDate : createdDate}</Typography>
        <Typography className={classes.textstyle}>{createdTime === 'Invalid Date' ? updatedTime : createdTime}</Typography>
      </TableCell>
      <TableCell className={classes.rowcellStyle} display="flex" onClick={event => handleClick(event)}>
        <Typography className={classes.textstyle}>{design.publishProducts.length === 0 ? 'Unlinked' : 'Linked' }</Typography>
      </TableCell>
      <TableCell>
        <Tooltip title="Get sharable link">
          <IconButton aria-label="delete" onClick={_ => { copyLink(design) }} >
            <LinkIcon fontSize="small" style={{ color: '#3D6ACF', fontSize: '24px' }} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow >
  );
}
