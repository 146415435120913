import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import DesignTableHead from './DesignTableHead';
import DesignTableRow from './DesignsTableRow';
import * as api from '../../services/api';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  Box,
  CardActions,
  TablePagination,
  Typography
} from '@material-ui/core';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import Snackbar from '@material-ui/core/Snackbar';
import DeleteConfirmDialog from './DeleteConfirmDialog';

import LoaderElement from '../PageLoader/LoaderElement';
import { useHistory } from "react-router-dom";

const messages = {
  DELETE_SUCCESS : "Design Deleted!",
  LINK_COPIED : "Link Copied to Clipboard"
}

const useStyles = makeStyles(theme => ({
  content: {
    padding: 0,
    minHeight: 'calc(100vh - 400px)'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tablePagination: {
    fontSize: 12
  },
  snack: {
    background: '#FBFBFB',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    justifyContent: 'center'
  },
  root: {
    boxShadow: '0px 4px 8px rgba(73, 73, 73, 0.10)',
  }
}));

const DesignsTable = ({
  designs,
  showDate,
  isDesignsAreLoading,
  handleRowsPerPageChange,
  handlePageChange,
  rowsPerPage,
  count,
  page,
  setShowGroupDesignDialog
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialogOpen,setDialogOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const history = useHistory();

  const handleClick = () => {
    setDialogOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = designs.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleCheckboxSingleClick = (event, design) => {
    const selectedIndex = selected.indexOf(design);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected.push(design);
    } else if (selectedIndex === 0) {
      newSelected.pop();
    }

    setSelected(newSelected);
  };

  const handleCheckboxClick = (event, design) => {
    const selectedIndex = selected.map(x => x.designId).indexOf(design.designId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, design);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.map(x => x.designId).indexOf(id.designId) !== -1;

  const handleDelete = (event) => {
    setDialogOpen(false);
    deleteDesign();
  };

  const deleteDesign = () => {
    api.remove(`designs/${selected[0].designId}`)
      .then(data => {
        setSnackbarMessage(messages.DELETE_SUCCESS);
        setOpen(true);
        window.location.reload();
      })
      .catch(error => {
        console.log('Design delete error: ',error);
        setSnackbarMessage(error.message);
        setOpen(true);
      });
  };

  const openSnackBar = () => {
    setSnackbarMessage(messages.LINK_COPIED);
    setOpen(true)
  }


  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
        {selected.length === 1 ? <EnhancedTableToolbar numSelected={selected.length} handleClick={handleClick} /> : null }
        {dialogOpen && <DeleteConfirmDialog open={dialogOpen} setOpen={setDialogOpen} handleDelete={handleDelete} selected={selected}/>}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={open}
          onClose={handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
            className: classes.snack
          }}
          message={
              <div id="message-id" style={{display:'flex',alignItems:'center'}}>
                <svg xmlns="http://www.w3.org/2000/svg" style={{width:'28px',height:'28px',color:'#14af7e'}} viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <Typography style={{marginLeft:'10px'}} variant="subtitle1">{snackbarMessage}</Typography>
              </div>
          }
          autoHideDuration={3000}
        />
          <Box>
            {isDesignsAreLoading? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems= "center"
                m={4}
                mb={-5}
                style={{height: 'calc(100vh - 400px)'}} 
              >
                <LoaderElement/>
              </Box>
            ):(
              <Table>
                <DesignTableHead onSelectAllClick={handleSelectAllClick} numSelected={selected.length} rowCount={designs.length}/>
                <TableBody>
                  {designs.map((design, index) => {
                    const isItemSelected = isSelected(design);
                    return (
                      <DesignTableRow
                        design={design}
                        index={index}
                        key={design.designId}
                        showDate={showDate}
                        openSnackBar={openSnackBar}
                        handleCheckboxClick = {handleCheckboxSingleClick}
                        isItemSelected={isItemSelected}
                        setShowGroupDesignDialog={setShowGroupDesignDialog}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </Box>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          backIconButtonProps={{
            'aria-label': 'previous page'
          }}
          className={classes.tablePagination}
          component="div"
          nextIconButtonProps={{
            'aria-label': 'next page'
          }}
          onRowsPerPageChange={handleRowsPerPageChange}
          onPageChange={(event, page) => handlePageChange(event,page)}
          rowsPerPageOptions={[5, 10, 25]}
          rowsPerPage={rowsPerPage}
          count={count}
          page={page}
        />
      </CardActions>
    </Card>
  );
};

export default DesignsTable;
