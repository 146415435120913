import create from 'zustand'

let editorStore = (set) => ({
    inspectorOpen: true,
    inspectorItem: 0,
    setInspectorOpen: (status) => set((state) => ({ inspectorOpen: status })),
    setInspectorItem: (item) => set((state) => ({ inspectorItem: item }))
})

export const useEditorStore = create(editorStore)
