import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    padding: 0
  },
  paper: {
    padding: '30px'
  },
  actionsRoot: {
    justifyContent: 'center'
  },
  modalCenteredContent: {
    textAlign: 'center',
    color: '#263C67'
  },
  cancelbutton: {
    backgroundColor: '#3D6ACF',
    border: '2px solid #3D6ACF',
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: 'white',
    fontFamily: 'Poppins,sans-serif',
    lineHeight: '21px',
    fontSize: '14px',
    fontWeight: 600,
    padding: '8px 16px',
    width: '100px',
    height: '42px',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
    },
  },
  dialogContentRoot: {
    justifyContent: 'center',
    display: 'flex',
    padding: '30px 24px'
  }
}))

export default function ScansPopup({ showScansPopup, setShowScansPopup }) {
  const classes = useStyles();

  const handleClose = () => {
    setShowScansPopup(false);
  };

  return (
    <div>
      <Dialog
        open={showScansPopup}
        onClose={handleClose}
        fullWidth={true}
        maxWidth='xs'
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.paper
        }}>
        <DialogTitle id="max-width-dialog-title" classes={{
          root: classes.titleRoot
        }}>
          <p className={classes.modalCenteredContent} style={{ fontWeight: 700, fontFamily: 'Poppins', fontSize: '24px', lineHeight: '36px', color: "#263C67" }}>3D Scanning feature will be available soon!</p>
          <p className={classes.modalCenteredContent}>Stay tuned with OGMO for more updates</p>
        </DialogTitle>
        <DialogContent classes={{
          root: classes.dialogContentRoot
        }}>
          <img src={process.env.PUBLIC_URL + '/3D_scan.png'} />
        </DialogContent>
        <DialogActions classes={{
          root: classes.actionsRoot
        }}>
          <button className={classes.cancelbutton} onClick={handleClose} color="primary">
            Close
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}