import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Typography,
    Toolbar,
    Tooltip,
    IconButton
  } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      flex: '1 1 100%',
    },
    dltbtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width : '40px',
        height: '40px',
        borderRadius: '100%',
        '&:hover': {
          background: '#EAEAEA',
        }
    },
  }));
  
export default function EnhancedTableToolbar(props) {
    const classes = useToolbarStyles();
    const { numSelected } = props;
  
    return (
      <>
        <Toolbar
          className={classes.root}
        >
          {numSelected > 0 ? (
            <Typography className={classes.title} variant="subtitle1" component="div">
              {numSelected} selected
            </Typography>
          ) : null }
    
          {numSelected > 0 ? (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <div>
                <Tooltip title="Delete" data-test="delete_selected_design">
                  <div className={classes.dltbtn} onClick={props.handleClick}>
                    <IconButton aria-label="delete" >
                      <DeleteIcon fontSize="small" style={{ color: '#3D6ACF' }} />
                    </IconButton>
                  </div>
                </Tooltip>
              </div>
            </div>
          ) : null }
        </Toolbar>
      </>
    );
  };