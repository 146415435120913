/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, ListItem, Button, IconButton } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom:'4px',
  },
  subitem: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom:'16px',
  },
  root : {
    borderRadius: 0,
    '&$disabled': {
      color: 'white',
      opacity:'50%',
    },
  },
  label: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
},
sublabel: {
  paddingLeft: '40px',
},
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#ffffff',
    fontSize: 16,
    lineHeight: '24px',
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    borderRadius: '10px',
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': { 
      backgroundColor: '#322F45',
      borderRadius: '10px',
    }
  },
  buttonsub: {
    color: '#ffffff',
    fontSize: 13,
    lineHeight: '21px',
    padding: '7px 7px',
    paddingLeft:'15px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'nowrap',
    '&:hover': { 
      backgroundColor: '#3f3c52',
    }
  },
  icon: {
    width:'25px',
    height:'25px',
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center',
    margin: '0px 10px 0px 10px',
  },
  active: {
    color: 'white',
    backgroundColor:'#322F45',
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: 'white'
    }
  },
  activeSubBtn: {
    color: 'white',
    backgroundColor:'#3f3c52',
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: '0px',
  },
  activeWIthSub: {
    borderRadius: '10px 10px 0 0',
  },
  sublist: {
    width:'100%',
    height:'auto',
    backgroundColor: '#322F45',
    borderRadius: '0 0 10px 10px',
    marginTop:'-8px',
    paddingTop:'20px',
    paddingBottom:'5px',
  },
  disabled: {
    color: 'white',
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = ({ pages, className, ...rest }) => {
  const classes = useStyles();
  const [selectedTitle, setSelectedTitle] = useState('');
  const [isSubLinksSelected, setIsSubLinksSelected] = useState(false);

  const handleSubLinkSelect = (title)=>{
    setSelectedTitle(title)
    setIsSubLinksSelected(!isSubLinksSelected)
  }

  const handleCloseSubLink = ()=>{
    setIsSubLinksSelected(false);
  }

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
      aria-label="Main navigation"
    >
      {pages.map(page => (
        <React.Fragment key={page.title}>
        <ListItem
          className={classes.item}
          disableGutters
          key={page.title}
        >
          {
            page.sub.length === 0 ? (
              <Button
                classes= {{
                  root : classes.root,
                  label:classes.label,
                  disabled: classes.disabled,
                }}
                activeclassname={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to={page.href}
                onClick={ e => { page.onClick? page.onClick(e) : handleCloseSubLink()}}
              >
                  <div style={{display:'flex'}}>
                    {page.icon && <div className={classes.icon}>{page.icon}</div>}
                    {page.title}
                  </div>
              </Button>
            ) : (
              <Button
                classes= {{
                  root : classes.root,
                  label:classes.label,
                }}
                activeclassname={classes.active}
                className={isSubLinksSelected? clsx(classes.button, classes.active, classes.activeWIthSub) : classes.button}
                onClick = {e => {page.onClick? page.onClick(e) : handleSubLinkSelect(page.title)}}
              >
                  <div style={{display:'flex'}}>
                    {page.icon && <div className={classes.icon}>{page.icon}</div>}
                    {page.title}
                  </div>
              { isSubLinksSelected ? <KeyboardArrowUpOutlinedIcon/> : <KeyboardArrowRightOutlinedIcon/> }
              </Button>
            )
          }
        </ListItem>
        {isSubLinksSelected && selectedTitle==page.title && 
        <div className={classes.sublist}>
          {page.sub.map(
            subPage=>(
            <ListItem 
              className={classes.subitem}
              disableGutters
              key={subPage.title}>
              <Button
                classes= {{
                  label:classes.sublabel,
                }}
                activeclassname={classes.activeSubBtn}
                className={classes.buttonsub}
                component={CustomRouterLink}
                to={page.href+subPage.href}
                key={subPage.title}
              >{subPage.title}</Button>
            </ListItem>
            ))}
        </div>}
        </React.Fragment> 
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
