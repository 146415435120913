import React, { useEffect } from "react";
import { useMessageStore } from "./MessageStore/MessageStore";

const useSnackbar = () => {
    const open = useMessageStore(state => state.messageOpen);
    const setOpen = useMessageStore(state => state.setOpen);
    const snackPack = useMessageStore(state => state.snackPack);
    const messageInfo = useMessageStore(state => state.messageInfo);
    const shiftSnackPack = useMessageStore(state => state.shiftSnackPack);
    const setMessageInfo = useMessageStore(state => state.setMessageInfo);

    useEffect(() => {
        if (snackPack.length && !messageInfo) {
            setMessageInfo({ ...snackPack[1] });
            shiftSnackPack();
            setOpen(true);
        } else if (snackPack && snackPack.length && messageInfo && open) {
            setOpen(false);
        }
    }, [snackPack, messageInfo, open]);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    }

    const handleExited = () => {
        setMessageInfo(undefined);
    }

    return (
        { open, handleSnackbarClose, handleExited, messageInfo }
    );
}

export default useSnackbar;