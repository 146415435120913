import React, { useEffect } from 'react'
import { Box } from "@material-ui/core"
import { useEditorStore } from '../EditorStore/EditorStore';
import { InspectorItems } from './Consts'
import { SceneInspectorComponent } from '../InspectorSubComponents/SceneInspectorComponent';
import { LightingInspectorComponent } from '../InspectorSubComponents/LightingInspectorComponent';
import { ARInspectorComponent } from '../InspectorSubComponents/ARInspectorComponent';

const Inspector = (props) => {

    const inspectorItem = useEditorStore(state => state.inspectorItem)
    const setInspectorItem = useEditorStore(state => state.setInspectorItem);

    useEffect(() => {
        return function cleanup() {
            setInspectorItem(0);
        }
    }, []);

    return (
        <Box width='17vw' minWidth='240px' height="100%" borderRight={1} style={{ background: '#161616',fontFamily:'Poppins,sans-serif',lineHeight: '24px',
        fontSize: '16px',fontWeight: 500 }}>
            <>
                {inspectorItem === InspectorItems.Scene &&
                    <SceneInspectorComponent />
                }
                {inspectorItem === InspectorItems.Lighting &&
                    <LightingInspectorComponent envMaps={props.envMaps} />
                }
                {inspectorItem === InspectorItems.AR &&
                    <ARInspectorComponent />
                }
            </>
        </Box>
    )
}

export { Inspector }