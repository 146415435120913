import create from "zustand";
import { Severity } from "../constants/consts";

let messageStore = (set) => ({
    messageOpen: false,
    snackPack: [],
    setOpen: (status) => set(state => ({ messageOpen: status })),
    messageInfo: undefined,
    setMessageInfo: (info) => set(state => ({ messageInfo: info })),
    dispatch: args => set(state => reducer(state, args)),
    shiftSnackPack: () => set(state => ({ snackPack: state.snackPack.shift() }))
});

const reducer = (state, { message, type }) => {
    switch (type) {
        case Severity.error:
            return {
                ...state,
                snackPack: [state.snackPack, [message, Severity.error]]
            }
        case Severity.success:
            return {
                ...state,
                snackPack: [state.snackPack, [message, Severity.success]]
            }
        case Severity.warning:
            return {
                ...state,
                snackPack: [state.snackPack, [message, Severity.warning]]
            }
        case Severity.info:
            return {
                ...state,
                snackPack: [state.snackPack, [message, Severity.info]]
            }
        default:
            return {
                ...state
            }
    }
}

export const useMessageStore = create(messageStore);