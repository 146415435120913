import React from 'react'
import { ContactShadows } from '@react-three/drei'
import { useEnvironmentStore, useSceneStore } from './Store'

const Shadows = () => {

    const size = useSceneStore((state) => state.modelSize)
    const min = useSceneStore((state) => state.modelMin)

    const shadowOpacity = useEnvironmentStore((state) => state.shadowOpacity)
    const shadowBlur = useEnvironmentStore((state) => state.shadowBlur)
    const shadowBias = useEnvironmentStore((state) => state.shadowBias)

    return (
        <ContactShadows position={[0, min.y - size * 0.01 + shadowBias, 0]} rotation-x={Math.PI / 2}  opacity={shadowOpacity} width={size * 1.2} height={size * 1.2} blur={shadowBlur} far={size * 0.1} resolution={1024}/>
    )
}

export { Shadows }

