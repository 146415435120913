import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteWrapper = props => {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Component {...matchProps} />
      )}
    />
  );
};

RouteWrapper.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any,
  path: PropTypes.string
};

export default RouteWrapper;
