import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  cancelbutton: {
    border: '2px solid #3D6ACF',
    boxSizing: 'border-box',
    background: 'transparent',
    borderRadius: '5px',
    color: '#3D6ACF',
    fontFamily: 'Poppins,sans-serif',
    lineHeight: '21px',
    fontSize: '14px',
    padding:'8px 16px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    cursor:'pointer',
    marginRight: '30px',
    "&:hover": {
      border: '2px solid rgba(29, 76, 146,0.8)',
    },
  },
  savebutton: {
    backgroundColor: '#3D6ACF',
    border: '2px solid #3D6ACF',
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: 'white',
    fontFamily: 'Poppins,sans-serif',
    lineHeight: '21px',
    fontSize: '14px',
    fontWeight: 600,
    padding:'8px 16px',
    cursor:'pointer',
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
    },
  },
  dialogStyle: {
    position:'absolute',
    top:'10px',
    padding:'40px 60px'
  },
  titleStyle: {
    fontFamily: 'Poppins,sans-serif',
    lineHeight: '21px',
    fontSize: '14px',
    fontWeight: 500,
    color:'#263C67',
    marginBottom:'12px',
  },
  description: {
    fontFamily: 'Poppins,sans-serif',
    lineHeight: '18px',
    fontSize: '12px',
    fontWeight: 500,
    color:'#6D6D6D',
    marginBottom:'20px',
  }
}));

export default function DeleteConfirmDialog({open,setOpen,handleDelete,selected,groupDesign}) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        classes={{
          paper: classes.dialogStyle
        }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialogStyle}
      >
        <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
          {groupDesign ?
            <div className={classes.titleStyle}>
              Are you sure you want to delete this Group Design
            </div>
            :
            <div className={classes.titleStyle}>
              Are you sure you want to delete {selected.map(design => design.designName + ",")}
            </div>
          }
         
          <div className={classes.description}>
            You cannot reverse this action
          </div>
          <div>
            <button className={classes.cancelbutton} onClick={handleClose} color="primary">
              Cancel
            </button>
            <button className={classes.savebutton} onClick={handleDelete} color="primary" autoFocus data-test="delete_confirmation_btn">
              Delete Design
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}