import React, { createRef, useState, useEffect, useRef } from "react";
import { OGMOCanvas } from "ogmo-renderer";
import { Box } from '@material-ui/core'
import { Inspector } from '../../components/Editor/Inspector/Inspector';
import { HeaderBar } from '../../components/Editor/HeaderBar/HeaderBar';
import { useEditorStore } from '../../components/Editor/EditorStore/EditorStore';
import { MainMenu } from '../../components/Editor/MainMenu/MainMenu';
import { useEnvironmentStore, useSceneStore } from 'ogmo-renderer';
import * as api from '../../services/api';
import { makeStyles } from '@material-ui/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import Backdrop from '@material-ui/core/Backdrop';
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/react";
import Resizer from "react-image-file-resizer";
import CustomSnackBar from "../../components/Messages/CustomSnackBar";
import { useMessageStore } from "../../components/Messages/MessageStore/MessageStore";

const Editor = (props) => {
    const inspectorOpen = useEditorStore(state => state.inspectorOpen)
    const open = useMessageStore(state => state.messageOpen);
    const ref = createRef(null);
    const [width, setWidth] = useState(320);
    const [thumbnail, setThumbnail] = useState('')
    const [toggle, setToggle] = useState()
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const setEnvironmentMap = useEnvironmentStore((state) => state.setEnvironmentMap);
    const setEnvironmentMapExplicitPath = useEnvironmentStore((state) => state.setEnvironmentMapExplicitPath);
    const setBackgroundColor = useEnvironmentStore((state) => state.setBackgroundColor);
    const setAmbientIntensity = useEnvironmentStore((state) => state.setAmbientIntensity);
    const setShadowOpacity = useEnvironmentStore((state) => state.setShadowOpacity);
    const setShadowBlur = useEnvironmentStore((state) => state.setShadowBlur);
    const setShadowBias = useEnvironmentStore((state) => state.setShadowBias);
    const setModelPath = useSceneStore((state) => state.setModelPath);
    const resetSceneDimensions = useSceneStore((state) => state.resetSceneDimensions);

    const setCameraPosition = useSceneStore((state) => state.setCameraPosition);
    const setCaptureScreenState = useSceneStore((state) => state.setCaptureScreenState);
    const saveCameraPisition = useSceneStore((state) => state.saveCameraPisition)
    // const resetCameraPosition = useSceneStore((state) => state.resetCameraPosition)

    const imageThumbnail = useEnvironmentStore((state) => state.image);
    const timeStamp = useEnvironmentStore((state) => state.imageTimeStamp);
    const [designId, setDesignId] = useState();
    const [designData, setDesignData] = useState();
    const [envMaps, setEnvMaps] = useState([]);
    const [captureText, setCaptureText] = useState(false);
    const [flashEffect, setFlashEffect] = useState(false);

    const environmentMap = useEnvironmentStore((state) => state.environmentMap);
    const environmentMapPath = useEnvironmentStore((state) => state.environmentMapExplicitPath);
    const ambientIntensity = useEnvironmentStore((state) => state.ambientIntensity);
    const shadowOpacity = useEnvironmentStore((state) => state.shadowOpacity);
    const shadowBlur = useEnvironmentStore((state) => state.shadowBlur);
    const shadowBias = useEnvironmentStore((state) => state.shadowBias);
    const size = useSceneStore((state) => state.modelSize);
    const backgroundColor = useEnvironmentStore((state) => state.backgroundColor);
    const cameraPosition = useSceneStore((state) => state.cameraPosition);

    const override = css`
         margin: 0 0 0 25px;
        `;

    useEffect(() => {
        setDesignId(props.match.params.designId);

    }, [])

    useEffect(() => {
        if (designId) {
            fetchData()
        }
    }, [designId])

    async function fetchData() {
        try {
            let [designInfo, envMaps] = await Promise.all([
                api.get(`designs/${designId}`),
                api.get("common/envMaps")
            ]);
            if (designInfo.message === "success" && envMaps.message === "success") {
                setDesignData(designInfo);
                setThumbnail(designInfo.data.thumbnail)
                setEnvironmentMap(designInfo.data['sceneInfo']['environmentMapId']);
                setModelPath(designInfo.data['sceneInfo']['modelPath']);
                setEnvironmentMapExplicitPath(designInfo.data['sceneInfo']['environmentMapPath']);
                setBackgroundColor(designInfo.data['sceneInfo']['backgroundColor']);
                setAmbientIntensity(designInfo.data['sceneInfo']['ambientIntensity']);
                setShadowOpacity(designInfo.data['sceneInfo']['shadowOpacity']);
                setShadowBlur(designInfo.data['sceneInfo']['shadowBlur']);
                setShadowBias(designInfo.data['sceneInfo']['shadowBias']);
                setCameraPosition(designInfo.data['sceneInfo']['cameraPosition']);
                setEnvMaps(envMaps.data);
            }
        }
        catch (error) {
            console.log('error', error);
        }
    }

    const dataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(",");
        const byteString =
            splitDataURI[0].indexOf("base64") >= 0
                ? atob(splitDataURI[1])
                : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
        return new Blob([ia], { type: mimeString });
    };

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                400,
                "PNG",
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });
    useEffect(() => {
        if (toggle) {
            openToast()
            uploadThumbnail()
        }

    }, [timeStamp, imageThumbnail])

    useEffect(() => {
        if (toggle) {
            setCaptureScreenState(true)
        }
    }, [cameraPosition])

    function handleOnClickSavePreview() {
        setOpenBackdrop(true);
        saveCameraPisition()
        setToggle(Math.random())
    }

    function setProccesing() {
        setFlashEffect(true)
        setTimeout(() => {
            setFlashEffect(false)
        }, 1000);
        setCaptureText(true)
    }

    async function uploadThumbnail() {

        const newFile = dataURIToBlob(imageThumbnail);
        const image = await resizeFile(newFile);
        const split = image.split(',', [2])
        let buff = new Buffer.from(split[1], 'base64');
        uploadAsset(buff)
    }

    function openToast() {

        toast(<img width={width} src={imageThumbnail} alt="Screenshot" />, {
            className: "toast-container",
            bodyClassName: "toast-body",
            toastClassName: "toast-wrapper",
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            closeButton: false

        })
    }

    async function uploadAsset(image) {


        let data = await api.post('common/uploadAsset', {
            dataType: "user-data",
            userId: designData.data.userId,
            designId: designData.data.designId,
            assetType: "design",
            assetName: "thumbnail.png",
        }).catch(error => {
            console.log(error.message);
            setOpenBackdrop(false);
        });

        await uploadAssetWithUrl(data.data.uploadUrl, data.data.downloadUrl, image);

    };

    async function uploadAssetWithUrl(uploadUrl, downloadUrl, file) {
        const response = await fetch(uploadUrl, {
            method: "PUT", body: file, headers: {'Content-Type': 'image/png'}
        }).catch(error => {
            console.log(error.message);
            setOpenBackdrop(false);
        });

        await updateDesign(downloadUrl);

    }

    async function removeAsset() {
        if (thumbnail != '') {
            api.post('common/removeAsset', {              
                userId: designData.data.userId,
                url: thumbnail
            })
        }       
    }

    async function updateDesign(thumbnailDownloadUrl) {
        
        try {
            let data = await api.put(`designs/${designData.data.designId}`, {
                userId: designData.data.userId,
                designName: designData.data.designName,
                publishProducts: designData.data.publishProducts,
                thumbnail: thumbnailDownloadUrl,
                sceneInfo: {
                    modelPath: designData.data.sceneInfo.modelPath,
                    cameraPosition: {
                        "x": cameraPosition.x,
                        "y": cameraPosition.y,
                        "z": cameraPosition.z
                    },
                    environmentMapId: environmentMap,
                    environmentMapPath: environmentMapPath,
                    backgroundColor: backgroundColor,
                    ambientIntensity: ambientIntensity,
                    shadowOpacity: shadowOpacity,
                    shadowBlur: shadowBlur,
                    shadowBias: shadowBias
                },
                enableDesign: true,
                updatedAt: designData.data.updatedAt,
                createdAt: designData.data.createdAt,
                shortId: designData.data.shortId
            })
            await removeAsset(thumbnail)
            setThumbnail(thumbnailDownloadUrl)
            setOpenBackdrop(false);
        }
        catch (error) {
            setOpenBackdrop(false);
            console.log('Faild to updateDesign: ', error.message);
        }

    };

    return (
        <Box height="100%">
            <Helmet>
                <title>{`Edit ${designData ? designData.data.designName : ``} | OGMO - Augmented Reality & 3D Visualizer`}</title>
            </Helmet>
            <HeaderBar handleOnClickSavePreview={handleOnClickSavePreview} captureText={captureText} setOpenBackdrop={setOpenBackdrop} thumbnail={thumbnail} designData={designData && designData.data} />
            <Box width="100%" display="flex">
                <CustomSnackBar />
                <Box>
                    <MainMenu designData={designData && designData.data} />

                </Box>
                {inspectorOpen && <Box style={{ zIndex: 1 }}>
                    <Inspector envMaps={envMaps} />
                </Box>}
                <Backdrop style={{ zIndex: 5 }} open={openBackdrop}><div style={{ marginLeft: "20%", color: "#D4D4D4", fontSize: "25px", fontWeight: "550" }}>Saving<br /><PropagateLoader css={override} color="#D4D4D4" size={23} /></div></Backdrop>
                <ToastContainer style={{ marginLeft: "20%", marginTop: "60px", marginRight: 0 }}
                    className="toast-container"
                    bodyClassName="toast-body"
                    toastClassName="toast-wrapper"
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    closeButton={false}
                />
                <Box className={flashEffect ? "flashScreenshot" : ''} ref={ref} width="100%">
                    <OGMOCanvas id={'abc'} />
                </Box>
            </Box>
        </Box>
    )
}

export default Editor;