import React from 'react'
import { Box, Button, IconButton } from "@material-ui/core"
import ImageIcon from '@material-ui/icons/Image';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { useEditorStore } from '../EditorStore/EditorStore';
import { InspectorItems } from '../Inspector/Consts'
import scene from "../../../images/Vector.png";
import ar from "../../../images/ar.png";
import lighting from "../../../images/lighting.png";
import link from "../../../images/link.png";
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles,withStyles } from '@material-ui/styles';
import { useMessageStore } from '../../Messages/MessageStore/MessageStore';

const DarkTooltip = withStyles((theme) => ({
    tooltip: {
      background: 'rgba(101, 101, 101, 0.9)',
      fontFamily: "Poppins",
      lineHeight: "21px",
      fontWeight:'400',
      fontSize: '12px',
      borderRadius: '5px',
    },
  }))(Tooltip);
  

const useStyles = makeStyles(theme => ({
    btnStyle: {
        width: '100%',
        minWidth: 'auto',
    }
}));

const MainMenu = (props) => {
    const classes = useStyles();
    const setInspectorOpen = useEditorStore(state => state.setInspectorOpen)
    const setInspectorItem = useEditorStore(state => state.setInspectorItem)
    const inspectorItem = useEditorStore(state => state.inspectorItem)

    const dispatch = useMessageStore(state => state.dispatch)

    return (
        <Box width={50} height={'calc(100vh - 50px)'} boxShadow={3} alignItems="center" style={{ backgroundColor: 'rgba(65, 65, 65, 1)' }}>
            <DarkTooltip title="General Scene" placement="right-end">
                <Button className={classes.btnStyle} style={inspectorItem === 0 ? { backgroundColor: 'rgba(94, 94, 94, 1)' } : null}
                    onClick={() => {
                        setInspectorItem(InspectorItems.Scene)
                        // setInspectorOpen(!inspectorOpen)
                    }} data-test='general_scene_btn'>
                        <img src={scene} alt="setting"/>
                </Button>
            </DarkTooltip>
            <DarkTooltip title="Lighting" placement="right-end">
                <Button className={classes.btnStyle} style={inspectorItem === 1 ? { backgroundColor: 'rgba(94, 94, 94, 1)' } : null}
                    onClick={() => {
                        setInspectorItem(InspectorItems.Lighting)
                        // setInspectorOpen(!inspectorOpen)
                    }} data-test='lighting_component_btn'>
                        <img src={lighting} alt="Lighting"/>
                </Button>
            </DarkTooltip>
            <DarkTooltip title="AR Settings" placement="right-end">
                <Button className={classes.btnStyle} style={inspectorItem === 2 ? { backgroundColor: 'rgba(94, 94, 94, 1)' } : null}
                    onClick={() => {
                        setInspectorItem(InspectorItems.AR)
                        // setInspectorOpen(!inspectorOpen)
                    }} data-test='ar_settings_btn'>
                        <img src={ar} alt="ar setting"/>
                </Button>
            </DarkTooltip>
            <DarkTooltip title="Sharable Link" placement="right-end">
                <Button className={classes.btnStyle}
                    onClick={() => {
                        navigator.clipboard.writeText(`${process.env.REACT_APP_VIEWER_URL}${props.designData.shortId}`).then(() => {
                            dispatch({ type: 'success', message: 'Link Copied to Clipboard.' });
                        });
                    }}>
                        <img src={link} alt="sharable link"/>
                </Button>
            </DarkTooltip>
        </Box>
    )
}

export { MainMenu }