import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import UpgradeAlert from '../UpgradeAlert/UpgradeAlert';

const useStyles = makeStyles(theme => ({
  card: {
    width:'240px',
    borderRadius: '10px',
    marginRight:'20px',
    background: props => props.pricePlanName === "Starter" ? 'linear-gradient(180deg, #29b9d6 0%, #29b9d6 100%)' : props.pricePlanName === "Growth" ? 'linear-gradient(180deg, #429bdb 0%, #429bdb 100%)' : props.pricePlanName === "Premium" ? 'linear-gradient(180deg, #1D4C92 0%, #3D6ACF 100%)' : props.pricePlanName === "Enterprise" ? 'linear-gradient(180deg, #093973 0%, #093973 100%)' : 'transparent',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
    marginBottom:'20px',
    '&:hover': {
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.6)'
    }
  },
  contentRoot: {
    padding:'10px',
    "&:last-child": {
      paddingBottom: '10px',
    }
  },
  titleDiv: {
    height: '64px',
    boxSizing:'border-box',
  },
  title: {
    boxSizing:'border-box',
    color: props => props.pricePlanName === "Free" ? '#263C67' : '#fff',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '24px',
    paddingTop: theme.spacing(2),
    paddingBottom:'8px'
  },
  currentPlan: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    color: props => props.pricePlanName === "Free" ? '#A4AAB6' : '#fff',
  },
  price: {
    color: props => props.pricePlanName === "Free" ? '#263C67' : '#fff',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '40px',
    align: 'center',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(7),
  },
  priceCustom: {
    color: props => props.pricePlanName === "Free" ? '#263C67' : '#fff',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '45px',
    align: 'center',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(8),
  },
  priceMonth: {
    color: props => props.pricePlanName === "Free" ? '#263C67' : '#fff',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '20px',
    align: 'center',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(7),
  },
  currencysymbol: {
    color: props => props.pricePlanName === "Free" ? '#263C67' : '#fff',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '32px',
    paddingTop: theme.spacing(3),
  },
  detailtext: {
    color: props => props.pricePlanName === "Free" ? '#263C67' : '#fff',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '16px',
    paddingLeft: '10px'
  },
  detail: {
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2)
  },
  divider: {
    height:'4px',
    color:'#E9E9E9',
  },
  upgradebtn: {
    width: '100%',
    height: '50px',
    marginTop:'20px',
    marginBottom:'20px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '21px',
    color: props => props.pricePlanName === "Free" ? '#263C67' : '#fff',
    background: 'rgba(232, 232, 232, 0.6)',
    borderRadius: '5px',
    border: 'transparent',
    cursor:'Pointer',
    "&:hover": {
      backgroundColor: "rgba(232, 232, 232, 0.8)",
    },
  },
  currentPlanBtn: {
    width: '100%',
    height: '50px',
    marginTop:'20px',
    marginBottom:'20px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '21px',
    background: 'transparent',
    borderRadius: '5px',
    border: '2px solid #263C67',
    color: props => props.pricePlanName === "Free" ? '#263C67' : '#fff'
  }
}))

const PlanCard = ({plan, ...props}) => {
  const classes = useStyles(plan);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };


  return (
    <>
          <Card className={classes.card}>
            <CardContent classes={{ root: classes.contentRoot }}>
              <Grid container justifyContent='center' direction="column" alignItems="center" >
                <div className={classes.titleDiv}>
                  <Typography className={classes.title}>
                    {plan.pricePlanName}
                  </Typography>
                </div>
                <Grid container direction="row" alignItems="flex-start" justifyContent='center'>
                  {plan.pricePlanName !== "Enterprise" && <Typography className={classes.currencysymbol}>
                    $
                  </Typography>}
                  {plan.price !== "custom" ? <Typography className={classes.price}>
                    {plan.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<span className={classes.priceMonth}>/month</span>
                  </Typography> : <Typography className={classes.priceCustom}>
                    Custom <br/>
                  </Typography>}
                </Grid>
              </Grid>
              <Divider variant="middle" className={classes.divider} />
              <Grid container direction="column" alignItems="center" justifyContent='center'
                className={classes.detail}>
                {plan.details.map((detail, index) => (
                  <Grid container direction="row" alignItems="center" justifyContent="flex-start" key={index} style={{ marginBottom: '10px' }}>
                    <CheckCircleOutlineIcon style={{ color: plan.pricePlanName === "Free" ? '#263C67' : '#fff' }} />
                    <Typography className={classes.detailtext}>
                      {detail}
                    </Typography>
                  </Grid>
                ))}
                {!props.current && <button className={classes.upgradebtn} onClick={handleClickOpen}>Upgrade Now</button>}
                {props.current && <button className={classes.currentPlanBtn}>Your Current Plan</button>}
              </Grid>
            </CardContent>
          </Card>
      <UpgradeAlert open={open} setOpen={setOpen}/>
    </>
  );
};

export default PlanCard;
