import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles(theme => ({

  title: {
    color: '#263C67',
    fontSize: '24px',
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    paddingBottom: theme.spacing(4),
  },
  content: {
    color: '#263C67',
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    padding: theme.spacing(1)
  },
  dot: {
    display: "inline-block",
    backgroundColor: '#263C67',
    borderRadius: "50%",
    width: '8px',
    height: '8px',
    left: 0,
    top: 0
  },
  button: {
    background: '#3D6ACF',
    borderRadius: '5px',
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(2),
    fontFamily: 'Poppins',
    color: '#FFFFFF'
  }
}))

const PaymentDetailCard = (props) => {
  const classes = useStyles();
  const [shadow, setShadow] = useState(1)


  return (
    <Box boxShadow={shadow} >
      <Card variant="outlined" onMouseOver={() => setShadow(3)}
        onMouseOut={() => setShadow(1)}>
        <CardContent>
          <Typography className={classes.title}>
            Payment Info
            </Typography>
          <Card variant="outlined">
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Typography className={classes.content}>Visa</Typography>
              <div className={classes.dot}></div>
              <div className={classes.dot}></div>
              <div className={classes.dot}></div>
              <div className={classes.dot}></div>
              <div> </div>
              <div className={classes.dot}></div>
              <div className={classes.dot}></div>
              <div className={classes.dot}></div>
              <div className={classes.dot}></div>
              <div> </div>
              <div className={classes.dot}></div>
              <div className={classes.dot}></div>
              <div className={classes.dot}></div>
              <div className={classes.dot}></div>
              <Typography className={classes.content}>{props.lastdigit}</Typography>
              <CheckCircleOutlineIcon />
            </Grid>
          </Card>
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Button className={classes.button}>Add a Card</Button>
            <Button className={classes.button}>Change</Button>
          </Grid>
        </CardContent>
      </Card>
    </Box>

  );
};

export default PaymentDetailCard;
