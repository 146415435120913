import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Box,
  Breadcrumbs,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  button: {
    backgroundColor: '#3D6ACF',
    color: '#FBFDFF',
    padding: '6px',
    textTransform: 'capitalize',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 600,
    padding: '8px 16px',
    borderRadius: '5px',
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
    },
    marginLeft : '5px',
  },
  breadcrumb: {
    fontSize: '28px',
    lineHeight: '42px',
    fontWeight: 500,
    color: "#263C67",
    cursor: 'pointer'
  },
  designCount: {
    marginLeft: 25,
    color: '#576077'
  }
}));

export default function DesignsToolBar() {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      mb={7}>
      <Box
        alignItems="flex-end"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end">
        <Button
          className={classes.button}
          component={Link}
          to="/groupDesign"
          data-test="add_design"
        >
          Add  Group design
        </Button>
        <Button
          className={classes.button}
          component={Link}
          to="/createDesign"
          data-test="add_design"
        >
          Add design
        </Button>
      </Box>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography
          className={classes.breadcrumb}
        >Designs</Typography>
      </Breadcrumbs>
    </Box>
  );
}

DesignsToolBar.propTypes = {
  className: PropTypes.string
};
