const Environments = {
    "SunsetForest": {
        "ID" : "SunsetForest",
        "Name": "Sunset Forest",
        "HDR": "https://download.polyhaven.com/HDRIs/1k/sunset_forest_1k.hdr",
        "JPG": "https://hdrihaven.com/files/hdri_images/tonemapped/1500/sunset_forest.jpg"
    },
    "ChristmasStudio": {
        "ID" : "ChristmasStudio",
        "Name" : "Christmas Studio",
        "HDR": "https://download.polyhaven.com/HDRIs/1k/christmas_photo_studio_01_1k.hdr",
        "JPG": "https://hdrihaven.com/files/hdri_images/tonemapped/1500/christmas_photo_studio_01.jpg"
    },
    "Concrete": {
        "ID" : "Concrete",
        "Name" : "Concrete Building",
        "HDR": "https://download.polyhaven.com/HDRIs/1k/reinforced_concrete_02_1k.hdr",
        "JPG": "https://hdrihaven.com/files/hdri_images/tonemapped/1500/reinforced_concrete_02.jpg"
    },
}
Object.freeze(Environments)

export { Environments }