import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Breadcrumbs, Button, Card, Grid, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from "react-router-dom";
import frame from '../../images/frame.png';
import { Auth } from 'aws-amplify';
import woo from '../../images/woo.png';
import * as api from '../../services/api';
import CustomBreadCrumb from './../../components/Settings/CustomBreadCrumb/CustomBreadCrumb';
import PageLoader from './../../components/PageLoader/PageLoader'

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  container: {
    paddingLeft:'20px',
    paddingRight:'20px',
  },
  breadcrumb: {
    direction: 'row',
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  },
  body2: {
    padding: "20px 20px 20px 20px"
  },
  para1: {
    fontFamily: "Poppins,sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    textAlign:'left',
    color: '#263C67',
    lineHeight: '24px',
  },
  cardStyle: {
    width:'fit-content',
    padding: "30px 15px",
    border: '1px solid #ECECEC',
    boxSizing: 'border-box',
    boxShadow: '0px 3px 23px rgba(117, 117, 117, 0.14)',
    borderRadius: "10px"
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 2fr',
    gridColumnGap: '15px',
    gridRowGap: '20px',
  },
  griditem1: {
    display:'flex', 
    alignItems:'center',
    justifyContent:'flex-end'
  },
  griditem2: {
    display:'flex', 
    alignItems:'center',
    fontFamily: "Poppins,sans-serif",
    fontSize: "22px",
    fontWeight: 500,
    color: '#2F4474',
    lineHeight: '33px',
  },
  griditem3_active: {
    display:'flex', 
    alignItems:'center',
    paddingLeft: '30px',
    fontFamily: "Poppins,sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    color: '#1B9F48',
    lineHeight: '24px',
  },
  griditem3_inactive: {
    display:'flex', 
    alignItems:'center',
    paddingLeft: '30px',
    fontFamily: "Poppins,sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    color: '#ff0000',
    lineHeight: '24px',
  },
  griditem5: {
    display:'flex', 
    alignItems:'center',
    fontFamily: "Poppins,sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    color: '#3D6ACF',
    lineHeight: '24px',
  },
  griditem6: {
    display:'flex', 
    alignItems:'center',
    paddingLeft: '30px',
  },
  griditem8: {
    display:'flex', 
    alignItems:'center',
    fontFamily: "Poppins,sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    color: '#ff0000',
    lineHeight: '24px',
    gridColumnStart: '2',
    gridColumnEnd: '4',
    maxWidth: '380px',
    textAlign: 'justify',
  },
  buttonStyle: {
    backgroundColor: '#3D6ACF',
    color: '#fff',
    padding: '6px',
    textTransform: 'capitalize',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 500,
    padding: '8px 16px',
    borderRadius: '5px',
    whiteSpace: 'nowrap',
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
    },
  },
  img: {
    padding: "20px 50px 20px 20px",
    width: 378,
    marginTop: "50px"
  },
  para2: {
    fontFamily: "Poppins,sans-serif",
    fontSize: "24px",
    fontWeight: "500px",
    marginBottom: "7px"
  },
  para3: {
    fontFamily: "Poppins,sans-serif",
    fontSize: "16px",
    fontWeight: "500px",
    marginTop: "7px"
  },
  paragraphLink: {
    color: '#3D6ACF',
    textDecoration: 'none'
  }
}))


const EComIntegration = () => {
  const classes = useStyles();

  const [hasPlatform, setHasPlatform] = useState('');
  const [isInProgress, setIsInProgress] = useState(false);
  const [platform, setPlatform] = useState('');
  const knowledgeHubLink = process.env.REACT_APP_DOCS_URL;

  useEffect(() => {
    loadPlatform();
  }, [])

  async function loadPlatform() {
    try {
      const auth = await Auth.currentAuthenticatedUser();
      if (auth) {
        getPlatform(auth.username);
      }
    }
    catch (err) {
      console.log('Auth user error: ', err)
    }
  }

  async function getPlatform(userId) {
    setIsInProgress(true);
    await api.get(`platforms?userId=${userId}`)
      .then(data => {
        setPlatform(data.data);
        const platformName = data.data.platform;
        if (platformName!==undefined) {
          setHasPlatform(true);
        } else {
          setHasPlatform(false);
        }
        setIsInProgress(false);
      })
      .catch(error => {
        console.log('getPlatform API Error: ', error.message);
        setIsInProgress(false);
      });
  }

  if (isInProgress) {
    return(
      <PageLoader/>
    );
  }

  else if (hasPlatform) {
    return (
      <Box className={classes.container}>
        <CustomBreadCrumb breadcrumbs={[
        { text: "Settings", isCurrent: false },
        { text: "Integrations", isCurrent: true },
      ]} />
        <Box style={{marginTop: '30px'}}>
            <div>
              <div className={classes.para1}>Showing the current ECommmerce intergration of your account.</div>
              <div className={classes.para1}>
                <a href={knowledgeHubLink} target="_blank" className={classes.paragraphLink}>
                  Learn More
                </a> about OGMO Intergrations.</div>
            </div>
            <div style={{marginTop: '50px'}}>
              <Card className={classes.cardStyle}>
              <div className={classes.gridContainer}>
                <div className={classes.griditem1}><img src={woo} alt="logo" /></div>
                <div className={classes.griditem2}>Woocommerce</div>
                <div className={platform.platformStatus === 'connected' ? classes.griditem3_active : classes.griditem3_inactive}>{platform.platformStatus === 'connected' ? `Active` : `Inactive`}</div>
                <div></div>
                <div className={classes.griditem5}><Link href={platform.host}>
                  {platform.host}
                </Link></div>
                <div className={classes.griditem6}><Link href={`${platform.host}/wp-admin`}>
                  <Button className={classes.buttonStyle}>
                    Visit Admin
                  </Button>
                </Link></div>
                <div></div>
                <div className={classes.griditem8}>
                  {platform.platformStatus !== 'connected' &&
                    `This could mean OGMO plugin is deactivated 
                  or something wrong with the connection to 
                  your website.`
                  }
                </div>
              </div>
              </Card>
            </div>
        </Box>
      </Box>
    )
  } 
  else {
    return (
      <Box className={classes.container}>
        <CustomBreadCrumb breadcrumbs={[
        { text: "Settings", isCurrent: false },
        { text: "Integrations", isCurrent: true },
      ]} />
        <Box>
          <Grid
            container
            className={classes.body2}
            spacing={0}
            align="center"
            justifyContent="center"
            direction="column"
          >
            <Grid item>
              <img className={classes.img} src={frame} />
              <Box>
                <p className={classes.para2}>You currently do not have any integrations.</p>
              </Box>
              <Box>
                <p className={classes.para3}>
                  <a href={knowledgeHubLink} target="_blank" className={classes.paragraphLink}>
                    Learn More
                  </a> about how to integrate your OGMO account.
                  </p>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
}

export default EComIntegration;
