import {
  makeStyles,
  Grid,
  Card,
  Avatar,
  Box,
  Button,
  LinearProgress,
  Snackbar
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import * as api from '../../services/api';
import { Auth } from 'aws-amplify';
import avatar from '../../images/user.png';

import useUserStore from './../../components/User/UserStore';

import PageLoader from './../../components/PageLoader/PageLoader'
import CustomSnackBar from "../../components/Messages/CustomSnackBar";
import { useMessageStore } from "../../components/Messages/MessageStore/MessageStore";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: "1",
    paddingLeft: "20px",
  },
  titleStyle: {
    fontSize: "28px",
    fontWeight: 500,
    color: theme.palette.info.dark,
  },
  cardStyle: {
    padding: "25px 25px 25px 25px",
    borderRadius: "10px"
  },
  cardHeaderContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatarStyle: {
    width: "120px",
    height: "120px",
    marginRight: "30px",
  },
  cardTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    color: theme.palette.info.dark
  },
  cardTitle: {
    fontSize: "24px",
    fontWeight: 500,
    marginTop: "-24px",
    marginBottom: "-24px",
  },
  cardDesc: {
    fontSize: "14px",
    fontWeight: 500,
  },
  cardBottomContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "3em",
    color: theme.palette.info.dark
  },
  cardTitle2: {
    fontSize: "24px",
    fontWeight: 500,
    color: theme.palette.info.dark,
    marginTop: 0,
    marginBottom: 0
  },
  cardDesc2: {
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.info.dark,
    marginBottom: 0,
  },
  buttonStyle: {
    backgroundColor: theme.palette.secondary.main,
    padding: '8px 16px',
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "initial",
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
    },
  },
  card2Style: {
    padding: "20px 20px 20px 20px",
    height: "120px"
  },
  card2Title: {
    fontSize: "16px",
    lineHeight: "24px",
    minHeight: "29px",
    fontWeight: 500,
    color: theme.palette.info.main,
  },
  card2Number: {
    fontSize: "36px",
    fontWeight: "fontWeightMedium",
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 500,
    color: theme.palette.info.main,
  },
  progressbarStyle: {
    height: "10px",
    borderRadius: "9px",
  },
  progressbarStylePrimary: {
    backgroundColor: theme.palette.disabled.main
  },
  progressbarStyleNormal: {
    backgroundColor: '#1D4C92',
  },
  progressbarStyleLimit: {
    backgroundColor: '#D81F40',
  },
  totalNumberStyle: {
    position: "relative",
    color: theme.palette.info.main,
  },
  totalTextStyle: {
    textAlign: "right",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: "500",
    marginBottom: "10px",
    marginTop: 0,
  },
}));

function Dashboard() {
  const [isInProgress, setIsInProgress] = useState(true);
  const [alertOpen, setAlertOpen] = useState(false);
  const [apiCallVal, setApiCallVal] = useState({
    designSummeryAPICallVal: '',
    pricePlansAPICallVal: '',
    pricePlanDefsAPICallDef: ''
  });
  const [designSummery, setDesignSummery] = useState('');
  const [price, setPrice] = useState({ price: '', designCount: '', designViewCount: '' });
  const [planName, setPlanName] = useState('');

  const loggedInUserDetail = useUserStore(state => state.loggedInUserDetail);
  const dispatch = useMessageStore(state => state.dispatch);

  const classes = useStyles();

  const content = [
    {
      text: "Total designs used",
      count: designSummery.designCount,
      total: price.designCount,
      percent: parseInt(designSummery.designCount,10) > parseInt(price.designCount,10) ? 100 : 100 / (parseInt(price.designCount,10) / parseInt(designSummery.designCount,10)),
      dataTest:"total_designs_used"
    },
    {
      text: "Total views made",
      count: designSummery.designViewCount,
      total: price.designViewCount,
      percent: parseInt(designSummery.designViewCount,10) > parseInt(price.designViewCount,10) ? 100 : 100 / (parseInt(price.designViewCount,10) / parseInt(designSummery.designViewCount,10)),
      dataTest:"total_views_made"
    },
  ];

  async function loadDashboard() {
    try {
      const auth = await Auth.currentAuthenticatedUser();
      if (auth) {
        pricePlan(auth.username);
      }
    }
    catch (err) {
      console.log('Auth user error: ', err)
    }
  }

  useEffect(() => {
    loadDashboard();
  }, [])

  useEffect(() => {
    if (apiCallVal.designSummeryAPICallVal === 'successfull' && apiCallVal.pricePlansAPICallVal === 'successfull' && apiCallVal.pricePlanDefsAPICallDef === 'successfull') {
      setIsInProgress(false);
    }
    else if (apiCallVal.designSummeryAPICallVal === 'unsuccessfull' || apiCallVal.pricePlansAPICallVal === 'unsuccessfull' || apiCallVal.pricePlanDefsAPICallDef === 'unsuccessfull'){
      setIsInProgress(false);
      setAlertOpen(true);
      dispatch({ type: 'error', message: 'Loading error page' });
    }
  });

function pricePlan(userId) {
  getDesignSummery(userId);
  api.get(`billing/pricePlans?userId=${userId}`)
    .then(data => {
      const plan= data.data;
      setApiCallVal(oldValue => ({ ...oldValue, pricePlansAPICallVal: 'successfull' }))
      api.get(`billing/pricePlanDefs/${plan.pricePlanDefId}`)
        .then(data => {
          const planDef= data.data;
          setPlanName(planDef.pricePlanName);
          if (planDef.pricePlanType === "defined") {
            setPrice({price:planDef.price, designCount:planDef.designCount, designViewCount:planDef.designViewCount});
          } else {
            setPrice({price:plan.price, designCount:plan.designCount, designViewCount:plan.designViewCount});
          }
          setApiCallVal(oldValue => ({ ...oldValue, pricePlanDefsAPICallDef: 'successfull' }))
        })
        .catch(error => {
          console.log('pricePlanDef Error: ', error.message);
          setApiCallVal(oldValue => ({ ...oldValue, pricePlanDefsAPICallDef: 'unsuccessfull' }));
        });
      })
      .catch(error => {
        console.log('pricePlan Error: ', error.message);
        setApiCallVal(oldValue => ({ ...oldValue, pricePlansAPICallVal: 'unsuccessfull' }));
    });
}

function getDesignSummery(userId) {
  api.get(`users/designsummery/${userId}`)
  .then(data => {
    setDesignSummery(data.data);
    setApiCallVal(oldValue => ({ ...oldValue, designSummeryAPICallVal: 'successfull' }));
  })
  .catch(error => {
    console.log('Design Summery Error: ', error.message);
    setApiCallVal(oldValue => ({ ...oldValue, designSummeryAPICallVal: 'unsuccessfull' }));
    });
}

  if (!isInProgress) {
    return (
      <div className={classes.root}>
        <CustomSnackBar />
        <p className={classes.titleStyle}>Dashboard</p>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={6}>
            <Card className={classes.cardStyle}>
              <Box className={classes.cardHeaderContainer}>
                <Box>
                  <Avatar
                    className={classes.avatarStyle}
                    src={loggedInUserDetail.userLogo}
                    alt="jason"
                  />
                </Box>
                <Box className={classes.cardTextContainer}>
                  <Box>
                    <p className={classes.cardTitle} data-test="user_first_name">Hello {loggedInUserDetail.userFirstName}!</p>
                  </Box>
                  <Box>
                    <p className={classes.cardDesc}>
                      Let's have a look on your progress so far.
                    </p>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.cardBottomContainer}>
                <Box>
                  <Box>
                    <p className={classes.cardDesc2}>Your Monthly Subscription</p>
                  </Box>
                  <Box>
                    <p className={classes.cardTitle2} data-test="plan_name">{planName}</p>
                  </Box>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    component={Link}
                    to="/settings/billing"
                  >
                    Upgrade Subscription
                  </Button>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid container item spacing={4} xs={12} md={6} lg={6}>
            {content.map((item, index) => {
              return (
                <Grid key={index} item xs={6}>
                  <Card className={classes.card2Style}>
                    <Box className={classes.card2Title}>{item.text}</Box>
                    <p className={classes.card2Number} data-test={item.dataTest}>{item.count}</p>
                    <div className={classes.totalNumberStyle}>

                      {item.total !== "unlimited" ? <p className={classes.totalTextStyle}>
                        Out of {item.total}
                      </p> :
                        <br />
                      }

                      {item.total !== "unlimited" ? <LinearProgress
                        className={classes.progressbarStyle}
                        classes={{ colorPrimary: classes.progressbarStylePrimary, barColorPrimary: item.percent < 80 ? classes.progressbarStyleNormal : classes.progressbarStyleLimit }}
                        variant="determinate"
                        value={item.percent}
                      /> : <p className={classes.totalTextStyle}>
                        You have unlimited designs
                      </p>}
                    </div>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
          {/* <Grid item xs={12} md={6} lg={6}>
            <Card className={classes.cardStyle}>
              <p className={classes.card2Title}>Payment Card</p>
              <Box>
                <br />
                <Button variant="contained" className={classes.buttonStyle} disabled={true}>
                  Add a Card
                        </Button>
              </Box>
            </Card>
          </Grid> */}
        </Grid>
      </div>
    );
  } else {
    return (
      <PageLoader />
    );
  }

}

export default Dashboard;
