import React, { useEffect, useState } from 'react'
import { ChromePicker, MaterialPicker } from 'react-color';
import { useEnvironmentStore } from 'ogmo-renderer';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, Input, InputBase, makeStyles, NativeSelect, TextField, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Union from "../../../images/Union.png";
import PhotoIcon from '@material-ui/icons/Photo';
import './colorpicker.css';

const BootstrapInput = withStyles((theme) => ({
    root: {
        width:'64px',
        boxSizing:'border-box',
        backgroundColor: 'rgba(109, 109, 109, 1)',
        borderRadius: '5px',
        height:'36px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    },
    input: {
        lineHeight: '21px',
        fontSize: '14px',
        position: 'relative',
        paddingLeft:'10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        color: 'white',
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Poppins',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            outline:'none',
        },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    input: {
        width: 'auto',
        height:'36px',
        lineHeight: '18px',
        fontSize: '12px',
        borderRadius: '5px',
        padding:'0px 3px',
        position: 'relative',
        backgroundColor: 'rgba(109, 109, 109, 1)',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        color: 'white',
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'poppins',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            outline:'none',
        },
    },
}));

function hexToRGB(h) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(h);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : "";
}

function hexToRGBCSS(h) {
    let r = 0, g = 0, b = 0;

    // 3 digits
    if (h.length == 4) {
        r = "0x" + h[1] + h[1];
        g = "0x" + h[2] + h[2];
        b = "0x" + h[3] + h[3];

        // 6 digits
    } else if (h.length == 7) {
        r = "0x" + h[1] + h[2];
        g = "0x" + h[3] + h[4];
        b = "0x" + h[5] + h[6];
    }

    return "rgb(" + +r + "," + +g + "," + +b + ")";
}

const SceneInspectorComponent = () => {

    const backgroundColor = useEnvironmentStore((state) => state.backgroundColor)
    const setBackgroundColor = useEnvironmentStore((state) => state.setBackgroundColor)

    const [color, setColor] = useState(backgroundColor);
    const [mode, setMode] = useState("Hex");
    const [cssColour, setCSSColour] = useState();
    const [updateVal, setUpdateVal] = useState({
        r: '',
        g: '',
        b: ''
    });
    const [hexVal, setHexVal] = useState();
    const [expanded, setExpanded] = useState(true);

    const classes = useStyles();

    useEffect(() => {
        if (backgroundColor !== null) {
            setColor(backgroundColor);
            setHexVal(backgroundColor.toUpperCase());
            setCSSColour(hexToRGBCSS(backgroundColor));
            setUpdateVal(oldValue => ({ ...oldValue, r: hexToRGB(backgroundColor).r, g: hexToRGB(backgroundColor).g, b: hexToRGB(backgroundColor).b }));
        }
    }, [backgroundColor]);

    const handleColorChange = (color) => {
        setColor(color.hex);
        setBackgroundColor(color.hex);
    };

    const handleColorChangeComplete = (color) => {
        setColor(color.hex);
        setBackgroundColor(color.hex);
    };

    const rgbToHex = (rgb) => {
        let sep = rgb.indexOf(",") > -1 ? "," : " ";
        rgb = rgb.substr(4).split(")")[0].split(sep);

        let r = (+rgb[0]).toString(16),
            g = (+rgb[1]).toString(16),
            b = (+rgb[2]).toString(16);

        if (r.length == 1)
            r = "0" + r;
        if (g.length == 1)
            g = "0" + g;
        if (b.length == 1)
            b = "0" + b;

        return "#" + r + g + b;
    }

    async function manageCSSState(e) {
        setCSSColour(e.target.value);
        return e;
    }

    const handleRGBBackgroundColour = (e) => {
        e.persist();
        manageCSSState(e).then((event) => {
            setBackgroundColor(rgbToHex(event.target.value));
            setColor(rgbToHex(event.target.value));
        })
    }

    function RGBToHex2({ r, g, b }) {
        r = r !== undefined ? r.toString(16) : updateVal.r && updateVal.r.toString(16);
        g = g !== undefined ? g.toString(16) : updateVal.g && updateVal.g.toString(16);
        b = b !== undefined ? b.toString(16) : updateVal.b && updateVal.b.toString(16);

        if (r && r.length == 1)
            r = "0" + r;
        if (g && g.length == 1)
            g = "0" + g;
        if (b && b.length == 1)
            b = "0" + b;
        return "#" + r + g + b;
    }

    async function manageRGB(e) {
        setUpdateVal(oldValue => ({ ...oldValue, [e.target.name]: (parseInt(e.target.value) >= 0 && parseInt(e.target.value) < 256) ? parseInt(e.target.value) : (parseInt(e.target.value) > 255 && 255) || (parseInt(e.target.value) < 0 && 0) }));
        return e;
    }

    const handleValues = (e) => {
        e.persist();
        manageRGB(e).then((event) => {
            if (event.target.value.length > 0) {
                setBackgroundColor(RGBToHex2({ [event.target.name]: parseInt(event.target.value) }));
                setColor(RGBToHex2({ [event.target.name]: parseInt(event.target.value) }));
            }
        })
    };

    async function manageHex(e) {
        setHexVal(e.target.value.toUpperCase());
        return e;
    }

    const handleHexVal = (e) => {
        e.persist();
        manageHex(e).then((event) => {
            if (event.target.value.length === 7) {
                setBackgroundColor(event.target.value);
                setColor(event.target.value);
            }
        })
    }

    return (
            <Accordion style={{ background: 'linear-gradient(180deg, rgba(52, 52, 52, 1), rgba(52, 52, 52, 0.5))', color: 'white', display: 'flex', flexDirection: 'column' }} data-test="scene_component" expanded={expanded} onChange={() => { setExpanded(!expanded)}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'rgba(178, 178, 178, 1)' }} />} data-test="background"><PhotoIcon style={{marginRight:'8px'}}/>Background</AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <ChromePicker
                        color={color}
                        onChange={handleColorChange}
                        onChangeComplete={handleColorChangeComplete}
                        disableAlpha={true}
                        styles={{
                            default: {
                                picker: {
                                background: 'linear-gradient(180deg, rgba(52, 52, 52, 1), rgba(52, 52, 52, 0.5))',
                                boxShadow: '0',
                              }
                            }
                          }}
                    />
                    <div style={{ marginTop: '20px', display:'flex', alignItems:'center' }}>
                        <FormControl style={{marginRight: '20px'}} data-test="color_type">
                            <NativeSelect
                                id="demo-customized-select-native"
                                value={mode}
                                onChange={(e) => setMode(e.target.value)}
                                input={<BootstrapInput />}
                                autoWidth= {true}
                                
                            >
                                <option style={{ backgroundColor: 'rgba(109, 109, 109, 1)' }} value={"Hex"}>HEX</option>
                                <option style={{ backgroundColor: 'rgba(109, 109, 109, 1)' }} value={"RGB"}>RGB</option>
                                <option style={{ backgroundColor: 'rgba(109, 109, 109, 1)' }} value={"CSS"}>CSS</option>
                            </NativeSelect>
                        </FormControl>
                    {mode === "Hex" && <Input margin='dense' className={classes.input} value={hexVal} onChange={e => handleHexVal(e)} data-test="hex_value" />}
                    {mode === "CSS" && <Input margin='dense' className={classes.input} value={cssColour} onChange={e => handleRGBBackgroundColour(e)} data-test="CSS_value" />}
                    {mode === "RGB" && <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '35px' }} data-test="RGB_value">
                            <Input margin='dense' className={classes.input} name="r" type="number" value={updateVal.r} style={{ width: '30%' }} onChange={e => handleValues(e)} />
                            <Input margin='dense' className={classes.input} name="g" type="number" value={updateVal.g} style={{ width: '30%' }} onChange={e => handleValues(e)} />
                            <Input margin='dense' className={classes.input} name="b" type="number" value={updateVal.b} style={{ width: '30%' }} onChange={e => handleValues(e)} />
                        </div>}
                    </div>
                </AccordionDetails>
            </Accordion>
    )
}

export { SceneInspectorComponent }