import React,{useState,useRef} from 'react';
import { Typography, Input, Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    fontWeight: 550
  },
  input: {
    fontSize: 14,
    color:'#263C67',
    fontFamily: 'Poppins',
    fontWeight: '500',
    borderRadius: '5px',
    alignItems: 'center',
    padding: '6px',
    width: 300,
    height:'40px',
    border: '2px solid #ECECEC',
    boxSizing: 'border-box',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  disabledinput: {
    fontSize: 14,
    color:'#263C67',
    fontFamily: 'Poppins',
    fontWeight: '500',
    borderRadius: '5px',
    alignItems: 'center',
    padding: '6px',
    width: 300,
    height:'55px',
    border: '2px dashed #ECECEC',
    boxSizing: 'border-box',
  },
  disabledStyle: {
    fontSize: 14,
    color:'#263C67',
    fontFamily: 'Poppins',
    fontWeight: '500',
  },
  paragraph: {
    maxWidth: '630px',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#505050',
  },
  replaceBtn: {
    position: 'relative',
    boxSizing: 'border-box',
    marginRight: '18px',
    background:'#1D4C92',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
    background: '#1D4C92',
    color: 'white',
    border: 'transparent',
    borderRadius: '5px',
    padding:'8px 16px',
    whiteSpace: 'nowrap',
    cursor:'pointer',
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
    },
  },
  loadbarDiv: {
    marginTop: '30px',
    width: '450px',
    height: '100px',
    border: '2px dashed #CBCBCB',
    boxSizing: 'border-box',
    borderRadius: '10px',
  },
  loadbar: {
    marginTop: '50px',
    marginLeft: '40px',
    marginRight: '40px',
  },
}));

const UpdateDesignDetails = (props) => {

  const classes = useStyles();
  const design = props.design;

  const knowledgeHubLink = `${process.env.REACT_APP_DOCS_URL}docs/ogmo-essentials/designs`;

  return (
    <Box mt={4}>
      <div>
        <FormControl>
          <Typography
            variant="subtitle1"
          >Design name</Typography>
          <Input
            autoFocus
            className={classes.input}
            defaultValue={design.designName}
            disableUnderline
            onChange={props.handleNameChange}
            placeholder='Add Design Name'
            required
            data-test="update_design_name"
          />
        </FormControl>
      </div>
      <div style={{marginTop:'16px'}}>
        <FormControl>
          <Typography
            variant="subtitle1">
            3D File</Typography>
          <div className={classes.paragraph}>
            <p>Once you replace a new GLB, it will update the existing Design.</p>
            <p>Learn more about OGMO Designs in <a href={knowledgeHubLink} target="_blank" style={{textDecoration:'none',color:'#3D6ACF'}}>OGMO Knowledge Hub.</a></p>
          </div>
        </FormControl>
      </div>
    </Box>
  );
};

export default UpdateDesignDetails;
