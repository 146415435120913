import create from 'zustand'
import { UNKNOWN_USER_FIRST_NAME } from './../../util/constants'

const useUserStore = create(set => ({
    loggedInUserDetail: { userFirstName: UNKNOWN_USER_FIRST_NAME, userEmail: UNKNOWN_USER_FIRST_NAME, userLogo: null},
    setUserFirstName: (status) => set(state=> ({ loggedInUserDetail: {...state.loggedInUserDetail, userFirstName: status} })),
    setUserEmail: (status) => set(state=> ({ loggedInUserDetail: {...state.loggedInUserDetail, userEmail: status} })),
    setProfileAvatar: (status) => set(state=> ({ loggedInUserDetail: {...state.loggedInUserDetail, userLogo: status} })),
    setLoggedInUserDetail: (status) => set(_=> ({ loggedInUserDetail: status })),
    resetLoggedInUserDetail: () => set(_=> ({ loggedInUserDetail: { userFirstName: UNKNOWN_USER_FIRST_NAME, userEmail: UNKNOWN_USER_FIRST_NAME, userLogo: null} })),
}))

export default useUserStore;