import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Grid, TextField, Typography,Input } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import InputLabel from '@material-ui/core/InputLabel';
import CustomBreadCrumb from './../../components/Settings/CustomBreadCrumb/CustomBreadCrumb'

import { checkUsernameIsLimited } from './../../util/';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  container: {
    paddingLeft:'20px',
    paddingRight:'20px',
  },
  breadcrumb: {
    fontSize: '34px',
    lineHeight: '51px',
    fontWeight: 500,
    color: theme.palette.info.dark,
  },
  breadcrumbSm: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    color: theme.palette.info.dark,
  },
  body: {
    padding: "10px 10px 10px 0px",
    spacing:"0px",
    align: "center",
    justify: "center",
  },
  item1: {
    display: "flex",
    padding: "10px 10px 10px 0px",
    fontFamily: "Poppins,sans-serif",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "500",
    marginTop: "7px",
    textAlign:'left',
    display: "flex",
    padding: "10px 10px 10px 0px",
    justifyContent:'space-between',
    color: theme.palette.info.dark,
  },
  confirm: {
    margin: '0px 560px 0px 0px',
  },
  buttonStyle: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: "14px",
    padding:'8px 16px',
    fontWeight: "500",
    textTransform: "initial",
    marginTop: "7px",
    color: "white",
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
    },
  },
  inputField: {
    width: "100%",
    maxWidth: "376px",
    height:'40px',
    border: '2px solid #ECECEC',
    boxSizing: 'border-box',
    borderRadius: '5px',
    paddingLeft:'20px',
  },
  inputFieldLabel: {
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "3em",
    color: '#263C67',
    fontFamily: 'Poppins',
    lineHeight: '24px',
    marginBottom:'20px',
  }
}))

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Password = () => {
  const classes = useStyles();

  const [currentPass, setCurrentPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [isPasswordError, setIsPasswordError] = useState(false); 
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const [open, setOpen] = useState(true);
  const [error, setError] = useState('Error on changing password');
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const auth = await Auth.currentAuthenticatedUser();  
      if (auth) {
        if(!checkUsernameIsLimited(auth.username)){
          setIsUpdateDisabled(false);
        } else {
          setIsUpdateDisabled(true);
        }
      }
    }
    catch (err) {
      console.log('Auth user error: ', err);
    }
  }
  
  async function handleSubmit() {
    setIsPasswordError(false);
    if (newPass===confirmPass) {
      Auth.currentAuthenticatedUser()
      .then(user => {
          return Auth.changePassword(user, currentPass, newPass);
      })
      .then(data => {
        setIsPasswordSet(true);
        window.location.reload();
      })
      .catch(err => {
        setError(err.message);
        setIsPasswordError(true);
        setTimeout(() => {
          setIsPasswordError(false);
        }, 6000);
        console.log(err.message);
      });
    } else {
      setError('Confirm password not matched with New Password');
      setIsPasswordError(true);
      setTimeout(() => {
        setIsPasswordError(false);
      }, 6000);
    }
  }

  const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
		setOpen(false);
	};

  return (
    <Box className={classes.container}>
      <CustomBreadCrumb breadcrumbs={[
        { text: "Settings", isCurrent: false },
        { text: "Password", isCurrent: true },
      ]} />
      <Box>
        <Grid
          container
          className={classes.body}
          spacing={3}
        >
          <Grid item xs={12}>
            <Typography className={classes.item1}>Change your password below. Once your password changed, you will be
              <br />
              directed to Sign in Screen. </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="currentPassword" className={classes.inputFieldLabel}>Current Password</InputLabel>
            <Input
              id="currentPassword"
              type="password"
              autoComplete="current-password"
              disableUnderline
              disabled={isUpdateDisabled}
              onChange={e => setCurrentPass(e.target.value)}
              className={classes.inputField}
            />
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="newPassword" className={classes.inputFieldLabel}>New Password</InputLabel>
              <Input
                id="newPassword"
                type="password"
                disableUnderline
                autoComplete="new-password"
                disabled={isUpdateDisabled}
                onChange={e => { setNewPass(e.target.value) }}
                className={classes.inputField}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel htmlFor="confirmPassword" className={classes.inputFieldLabel}>Confirm Password</InputLabel>
              <Input
                id="confirmPassword"
                type="password"
                disableUnderline
                autoComplete="confirm-password"
                disabled={isUpdateDisabled}
                onChange={e => { setConfirmPass(e.target.value) }}
                className={classes.inputField}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Button
              variant="contained"
              disabled={isUpdateDisabled}
              className={classes.buttonStyle}
              onClick={handleSubmit}
            >
              Update Password
            </Button>
          </Grid>
        </Grid>
        {isPasswordError &&
          <Snackbar open={open} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {error}
            </Alert>
          </Snackbar>
        }
        {isPasswordSet &&
          <Snackbar open={open} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Password Changed
            </Alert>
          </Snackbar>
        }
      </Box>
    </Box>
  );
};

export default Password;
