import create from 'zustand'

const DEFAULT_MODAL_CENTER = {x: 0, y: 0, z: 0};
const DEFAULT_MODAL_MIN = {x: -1, y: -1, z: -1};
const DEFAULT_MODAL_MAX = {x: 1, y: 1, z: 1};
const DEFAULT_MODAL_SIZE = 1;

let environmentStore = (set) => ({
    environmentMap: "",
    environmentMapExplicitPath :"",
    backgroundColor : '#ffffff',
    ambientIntensity : 1.0,
    shadowOpacity : 0.5,
    shadowBlur : 0.5,
    shadowBias : 0.0,
    image : '',
    imageTimeStamp: '',
    toggleUseBackgroundColor: () => set((state) => ({ useBackgroundColor: !state.useBackgroundColor })),
    setBackgroundColor: (color) => set((state) => ({ backgroundColor: color })),
    setEnvironmentMap: (id) => set((state) => ({ environmentMap: id })),
    setEnvironmentMapExplicitPath: (path) => set((state) => ({ environmentMapExplicitPath: path })),
    setAmbientIntensity: (intensity) => set((state) => ({ ambientIntensity: intensity })),
    setShadowOpacity: (opacity) => set((state) => ({ shadowOpacity: opacity })),
    setShadowBlur: (blur) => set((state) => ({ shadowBlur: blur })),
    setShadowBias: (bias) => set((state) => ({ shadowBias: bias })),
    setImage: (image,timeStamp) => set((state) => ({ image: image, imageTimeStamp: timeStamp }))
})

let sceneStore = (set) => ({
    modelPath: "",
    modelCenter: DEFAULT_MODAL_CENTER,
    modelMin: DEFAULT_MODAL_MIN,
    modelMax: DEFAULT_MODAL_MAX,
    modelSize: DEFAULT_MODAL_SIZE,
    cameraPosition: {x: 0, y: 0, z: 0},
    saveCameraPosition : false,
    captureScreen : false,
    setModelPath: (path) => set((state) => ({ modelPath: path })),
    resetSceneDimensions: () => set((_) => ({
        modelCenter: DEFAULT_MODAL_CENTER,
        modelMin: DEFAULT_MODAL_MIN,
        modelMax: DEFAULT_MODAL_MAX,
        modelSize: DEFAULT_MODAL_SIZE,
    })),
    setSceneDimensions: (center, size, min, max) => set((state) => ({ modelCenter: center, modelSize: size, modelMin : min, modelMax : max})),
    saveCameraPisition: () => set((state) => ({ saveCameraPosition: true })),
    setCameraPosition: (position) => set((state) => ({ cameraPosition: position, saveCameraPosition: false })),
    resetCameraPosition: () => set((state) => ({ cameraPosition: {x :0, y:0, z:0} })),
    setCaptureScreenState: (capture) => set((state) => ({ captureScreen: capture }))
})

export const useEnvironmentStore = create(environmentStore)
export const useSceneStore = create(sceneStore)