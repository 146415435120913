import React, { useState, useEffect } from 'react'
import { useEnvironmentStore, Environments, useSceneStore } from 'ogmo-renderer';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardActionArea, CardMedia, FormControl, Grid, Input, InputBase, makeStyles, MenuItem, Select, Slider, TextField, Typography, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import skybox from "../../../images/skybox.png";
import shadow from "../../../images/shadow.png";
import Tooltip from '@material-ui/core/Tooltip';
import CloudIcon from '@material-ui/icons/Cloud';
import HelpIcon from '@material-ui/icons/Help';
import CircularProgress from '@material-ui/core/CircularProgress';

const DarkTooltip = withStyles((theme) => ({
    tooltip: {
      background: 'rgba(101, 101, 101, 0.9)',
      fontFamily: "Poppins",
      lineHeight: "21px",
      fontWeight:'400',
      fontSize: '12px',
      borderRadius: '5px',
      marginTop:'-20px',
      marginLeft:'100px',
    },
  }))(Tooltip);

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        lineHeight: '21px',
        fontSize: '14px',
        borderRadius: '5px',
        position: 'relative',
        background: 'linear-gradient(180deg, #6D6D6D 0%, #434343 100%)',
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        color: 'white',
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            'Poppins',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const CustomSlider = withStyles({
    root: {
      color: '#1B50C6',
      height: 6,
    },
    thumb: {
      height: 20,
      width: 20,
      marginTop: -7,
      marginLeft: -10,
      backgroundColor: '#fff',
    },
    track: {
      height: 6,
      borderRadius: 3,
    },
    rail: {
      color: '#7C7A7A',
      height: 6,
      borderRadius: 3,
    },
  })(Slider);

const useStyles = makeStyles((theme) => ({
    MuiAccordionroot: {
        "&.MuiAccordion-root:before": {
          display:'none'
        },
      },
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    input: {
        width:'50px',
        boxSizing:'border-box',
        lineHeight: '21px',
        fontSize: '14px',
        borderRadius: '5px',
        position: 'relative',
        background: '#373737',
        padding: '7px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        color: 'white',
        fontFamily: [
            'Poppins',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: '5px',
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    icon: {
        fill: '#B3B3B3',
        marginRight:'12px',
    },
    menuItems:{
        fontSize: '13px',
        fontWeight:'400',
        backgroundColor: 'rgba(109, 109, 109, 1) !important',
        color:'white',
        borderRadius: '5px',
        fontFamily: [
            'poppins',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            outline:'none',
        },
    }
}));

const LightingInspectorComponent = (props) => {

    const classes = useStyles();

    const setEnvironmentMap = useEnvironmentStore((state) => state.setEnvironmentMap)
    const setEnvironmentMapExplicitPath = useEnvironmentStore((state) => state.setEnvironmentMapExplicitPath)
    const setAmbientIntensity = useEnvironmentStore((state) => state.setAmbientIntensity)
    const setShadowBlur = useEnvironmentStore((state) => state.setShadowBlur)
    const setShadowOpacity = useEnvironmentStore((state) => state.setShadowOpacity)
    const setShadowBias = useEnvironmentStore((state) => state.setShadowBias)

    const environmentMap = useEnvironmentStore((state) => state.environmentMap)
    const ambientIntensity = useEnvironmentStore((state) => state.ambientIntensity)
    const shadowOpacity = useEnvironmentStore((state) => state.shadowOpacity)
    const shadowBlur = useEnvironmentStore((state) => state.shadowBlur)
    const shadowBias = useEnvironmentStore((state) => state.shadowBias)
    const size = useSceneStore((state) => state.modelSize)

    const [shadowBiasScale, setShadowBiasScale] = useState(0);
    const [selectedEnv, setSelectedEnv] = useState();
    const [expanded, setExpanded] = useState({ skyboxAccordion: true, shadowAccordion: true });
    const [envMapThumbnailLoader, setEnvMapThumbnailLoader] = useState(true);

    useEffect(() => {
        props && props.envMaps.map((item, index) => {
            if (item.envMapId === environmentMap) {
                setSelectedEnv(item);
                setEnvMapThumbnailLoader(false);
            }
        });
    }, [props.envMaps]);

    useEffect(() => {
        setShadowBiasScale(parseFloat(size === 0 ? 0 : (shadowBias / (size * 0.01)).toFixed(2)));
    }, [shadowBiasScale])

    const menu_items = [];

    props && props.envMaps.map((item, index) => {
        menu_items.push(
            <option className={classes.menuItems} key={index} value={item.envMapId}>{item.displayName}</option>
        )
    })

    function handleEnvMap(event) {
        return new Promise((resolve) => {
            setEnvironmentMap(event.target.value);
            resolve(event.target.value);
        });
    }

    const handleChangeSkybox = async (event) => {
        setEnvMapThumbnailLoader(true);
        const selectedEnvId = await handleEnvMap(event);
        props && props.envMaps.map((item, index) => {
            if (item.envMapId === selectedEnvId) {
                setSelectedEnv(item);
                setEnvironmentMapExplicitPath(item.envMap);
                setEnvMapThumbnailLoader(false);
            }
        })
    }

    const handleChangeIntensity = (event, value) => {
        setAmbientIntensity(isNaN(value) ? 0 : value);
    }

    const handleChangeShadowBlur = (event, value) => {
        setShadowBlur(isNaN(value) ? 0 : value);
    }

    const handleChangeShadowOpacity = (event, value) => {
        setShadowOpacity(isNaN(value) ? 0 : value);
    }

    const handleChangeShadowBias = (event, value) => {
        setShadowBiasScale(isNaN(value) ? 0 : value);
        setShadowBias(parseFloat(size * 0.01 * (isNaN(value) ? 0 : value)));
    }

    return (
        <Box data-test="lighting_component">
            <Accordion style={{ background: 'linear-gradient(180deg, rgba(52, 52, 52, 1), rgba(52, 52, 52, 0.5))', color: 'white', display: 'flex', flexDirection: 'column' }} expanded={expanded.skyboxAccordion} onChange={() => setExpanded(oldValues => ({ ...oldValues, skyboxAccordion: !expanded.skyboxAccordion }))}>
                <DarkTooltip title="Skybox allows you to select the background light for your scene" placement="bottom-end">
                    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'rgba(178, 178, 178, 1)' }} />} data-test="skybox"><CloudIcon style={{ marginRight: '8px' }} />Skybox<HelpIcon style={{ marginLeft: '8px', marginTop: '1px', width: '16px', color: "#B2B2B2" }} /></AccordionSummary>
                </DarkTooltip>
                <AccordionDetails style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} data-test="skybox_accordion">
                    <FormControl style={{ background: 'linear-gradient(180deg, #6D6D6D 0%, #434343 100%)', minWidth: '97%', borderRadius: '5px' }}>
                        <Select
                            native
                            labelId="skybox-select-label"
                            id="skybox-simple-select"
                            value={environmentMap}
                            onChange={handleChangeSkybox}
                            input={<BootstrapInput />}
                            inputProps={{
                                classes: {
                                    icon: classes.icon,
                                },
                            }}
                        >
                            {menu_items}
                        </Select>
                    </FormControl>
                    <Card className={classes.root} style={{ backgroundColor: 'rgba(52, 52, 52, 0.5)', margin: '20px 0', width: '100%' }}>
                        <CardActionArea>
                            {envMapThumbnailLoader ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 140 }}><CircularProgress /></div> :
                                <CardMedia
                                    className={classes.media}
                                    component='div'
                                    image={selectedEnv && selectedEnv.thumbnail}
                                    title={selectedEnv && selectedEnv.displayName}
                                    style={{ backgroundColor: 'rgba(52, 52, 52, 0.5)' }}
                                />
                            }
                        </CardActionArea>
                    </Card>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', minWidth: '97%' }}>
                        <Typography variant="subtitle2" gutterBottom style={{ color: 'rgba(255, 255, 255, 1)' }} >Ambient Intensity</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <CustomSlider min={0.0} max={2.0} step={0.01}
                                    value={ambientIntensity} onChange={handleChangeIntensity} aria-labelledby="continuous-Customslider" />
                            </Grid>
                            <Grid item xs={3}>
                                <Input margin='dense' value={ambientIntensity} onChange={(e) => handleChangeIntensity(e.target.event, parseFloat(e.target.value))} className={classes.input} />
                            </Grid>
                        </Grid>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion classes={{ root: classes.MuiAccordionroot }} style={{ background: 'linear-gradient(180deg, rgba(52, 52, 52, 1), rgba(52, 52, 52, 0.5))', color: 'white', display: 'flex', flexDirection: 'column' }} expanded={expanded.shadowAccordion} onChange={() => setExpanded(oldValues => ({ ...oldValues, shadowAccordion: !expanded.shadowAccordion }))}>
                <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'rgba(178, 178, 178, 1)' }} />} data-test="shadows"><img src={shadow} style={{ marginTop:'1px',marginRight: '12px',height:'20px',width:'20px' }} />Shadows</AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} data-test="shadows_accordion">
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', minWidth: '97%' }}>
                        <Typography variant="subtitle2" gutterBottom style={{ color: 'rgba(255, 255, 255, 1)' }} >Shadow Blur</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <CustomSlider min={0.0} max={1.0} step={0.01}
                                    value={shadowBlur} onChange={handleChangeShadowBlur} aria-labelledby="continuous-Customslider" />
                            </Grid>
                            <Grid item xs={3}>
                                <Input margin='dense' value={shadowBlur} onChange={e => handleChangeShadowBlur(e.target.event, parseFloat(e.target.value))} className={classes.input} />
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', minWidth: '97%' }}>
                        <Typography variant="subtitle2" gutterBottom style={{ color: 'rgba(255, 255, 255, 1)' }}>Shadow Opacity</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <CustomSlider min={0.0} max={1.0} step={0.01}
                                    value={shadowOpacity} onChange={handleChangeShadowOpacity} aria-labelledby="continuous-Customslider" />
                            </Grid>
                            <Grid item xs={3}>
                                <Input margin='dense' value={shadowOpacity} onChange={e => handleChangeShadowOpacity(e.target.value, parseFloat(e.target.value))} className={classes.input} />
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', minWidth: '97%' }}>
                        <Typography variant="subtitle2" gutterBottom style={{ color: 'rgba(255, 255, 255, 1)' }}>Shadow Height</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <CustomSlider min={-1.0} max={1.0} step={0.01}
                                    value={shadowBiasScale} onChange={handleChangeShadowBias} aria-labelledby="continuous-Customslider" />
                            </Grid>
                            <Grid item xs={3}>
                                <Input margin='dense' value={shadowBiasScale} onChange={e => handleChangeShadowBias(e.target.event, parseFloat(e.target.value))} className={classes.input} />
                            </Grid>
                        </Grid>
                    </div>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export { LightingInspectorComponent }