import React, {useState,useEffect} from 'react';
import { Redirect } from 'react-router-dom'
import { Input, FormControl} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Auth } from 'aws-amplify'
import SignLogo from '../../images/signLogo.png';
import GoogleLogo from '../../images/googleLogo.png';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  container:{
    position:'relative',
    width:'100%',
    minHeight:'100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    maxWidth:'760px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom:'100px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth:'400px',
  },
  submit: {
    background: '#3D6ACF',
    borderRadius: '5px',
    height:'40px',
    color: '#FBFDFF',
    textTransform: 'capitalize',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '600',
    boxShadow:'none',
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
      boxShadow:'none',
    },
  },
  googlebtn:{
    background: '#FFFFFF',
    borderRadius: '5px',
    height:'40px',
    color: 'rgba(0,0,0,0.54)',
    textTransform: 'capitalize',
    fontFamily: 'Roboto,sans-serif',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '500',
    boxShadow:'0 2px 4px 0 rgba(0, 0, 0, 0.25)',
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
    padding:'6px 8px',
    "&:hover": {
      background: '#ECECEC',
      borderRadius: '5px',
      boxShadow:'0 2px 4px 0 rgba(0, 0, 0, 0.25)',
    },
  },
  googleText:{
    marginLeft:'24px',
  },
  ogmoLogo: {
    width: 190,
    height: 47,  
  },
  logoText: {
    color: '#203256',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '500',
    textAlign:'center',
  },
  heading:{
    color: '#293A5D',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '42px',
    fontWeight: '600',
    textAlign:'center',
    marginBottom:'12px',
  },
  subheading:{
    color: '#000',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    textAlign:'center',
  },
  formControl:{
    width:'100%',
    marginBottom:'24px',
  },
  lastnamediv: {
    paddingLeft:'0px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft:'10px',
    },
  },
  input: {
    fontSize: 14,
    fontFamily: 'Poppins',
    borderRadius: '5px',
    alignItems: 'center',
    padding: '6px',
    width:'100%',
    height: '40px',
    border: '1px solid #C4C4C4',
    boxSizing: 'border-box',
    marginTop: '12px',
  },
  inputRed: {
    border: '1px solid #E52E2A',
  },
  inputlabel:{
    color: '#000',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '16px',
    fontWeight: '500',
  },
  privacytext:{
    color: '#000',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    lineHeight: '24px',
    marginBottom:'24px',
  },
  snack: {
    background: '#f44336',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
  },
  footer:{
    position:'absolute',
    bottom:'0',
    height:'100px',
    display:'flex',
    alignItems:'center',
    color: '#6B6B6B',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '500',
    textAlign:'center',
  },
  passwordTip: {
    color: '#E62E2A',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '500',
    marginTop: '12px',
  },
}));

export default function SignUp() {
  const [formType, updateFormType] = useState('Register')
  const [userName, updateUserName] = useState('ConfirmRegister')

  function renderSignUp() {
    switch(formType) {
      case 'Register':
        return (
          <Register
          setFormType={updateFormType}
          setUserName={updateUserName}
          />
        )
      case 'ConfirmRegister':
        return (
          <ConfirmRegister
          setFormType={updateFormType}
          userName={userName}
          />
        )
      case 'Login':
        return <Redirect to='/signin'/>
    }
  }

  return renderSignUp()
}

function Register(props) {

  const classes = useStyles();
  const [state , setState] = useState({
    firstName : "",
    lastName : "",
    email : "",
    password : ""
  })
  const [errormsg,setErrormsg] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);

  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {
    if(state.password.length >= 10){
      setPasswordError(false);
    }
  },[state.password]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };
  
  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }

  async function handleSubmitClick(e){
    e.preventDefault();
    const username = state.email;
    const password = state.password
    const email = state.email
    const firstName = state.firstName
    const lastName = state.lastName
    const name = firstName.concat(" ", lastName);
    try {
      await Auth.signUp({
        username, password, attributes: { email, name }
      })
      props.setUserName(username);
      props.setFormType("ConfirmRegister");
    } catch (err) {
      console.log('error signing up..', err);
      if(err.name.includes('InvalidPasswordException'))
      {
        setErrormsg('Password must be atleast 10 characters long.')
        setAlertOpen(true);
        setPasswordError(true);
      }
      else if(err.name.includes('UsernameExistsException'))
      {
        setErrormsg('An account with the given email already exists.');
        setAlertOpen(true);
      }
      else if(err.name.includes('InvalidParameterException'))
      {
        setErrormsg('Invalid email address format.');
        setAlertOpen(true);
      }
    }

  }

  return (
    <Container component="main" className={classes.container}>
      <CssBaseline />
      <Snackbar anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} 
        open={alertOpen} 
        autoHideDuration={3000} 
        onClose={handleClose} 
        ContentProps={{
          "aria-describedby": "message-id",
          className: classes.snack
        }}
        message={
            <div id="message-id" style={{display:'flex',alignItems:'center'}}>
              <ErrorOutlineIcon/>
              <div style={{marginLeft:'10px'}}>{errormsg}</div>
              <CloseIcon onClick={()=>{setAlertOpen(false)}}/>
            </div>
        } />
      <div className={classes.paper}>
        <div style={{marginBottom:'24px'}}>
          <img src={SignLogo} alt="logo"/>
        </div>
        <div style={{marginBottom:'36px'}}>
          <div className={classes.heading}>Let’s get started with OGMO!</div>
          <div className={classes.subheading}>Create an account to Sign up.</div>
        </div>
        <div className={classes.form}>
          <form onSubmit={handleSubmitClick}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl}>
                  <Typography className={classes.inputlabel}>First Name</Typography>
                  <Input
                    type="text"
                    autoComplete="fname"
                    name="firstName"
                    id="firstName"
                    className={classes.input}
                    disableUnderline
                    onChange={handleChange}
                    value={state.firstName}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={`${classes.formControl} ${classes.lastnamediv}`}>
                  <Typography className={classes.inputlabel}>Last Name</Typography>
                  <Input
                    type="text"
                    id="lastName"
                    name="lastName"
                    autoComplete="lname"
                    className={classes.input}
                    disableUnderline
                    value={state.lastName}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <Typography className={classes.inputlabel}>Email</Typography>
                  <Input
                    type="email"
                    id="email"
                    name="email"
                    autoComplete="email"
                    className={classes.input}
                    disableUnderline
                    value={state.email}
                    onChange={handleChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <Typography className={classes.inputlabel}>Password</Typography>
                  <Input
                    name="password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    className={passwordError ? `${classes.input} ${classes.inputRed}`: classes.input}
                    disableUnderline
                    value={state.password}
                    onChange={handleChange}
                    required
                  />
                  <div className={classes.passwordTip}> 
                    {state.password.length > 0 && state.password.length < 10 ? `Minimum 10 characters` : null}
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              Create Account
            </Button>
          </form>
          <div className={classes.logoText} style={{color:'#000',marginTop:'15px',marginBottom:'15px'}}>OR</div>
          <Button
            fullWidth
            variant="contained"
            className={classes.googlebtn}
            onClick={() => {Auth.federatedSignIn({provider: 'Google'})}}
          >
            <img width='18px' height='18px' src={GoogleLogo} alt='google logo'/>
            <div className={classes.googleText}>
              Sign in with Google
            </div>
          </Button>
          <div className={classes.logoText} style={{color:'#000',marginTop:'20px'}}>
            Already have an account? <Link href={'/signin'} style={{color:'#3D6ACF'}}>Sign in</Link>
          </div>
        </div>
      </div>
      <footer className={classes.footer}>
        &copy; {new Date().getFullYear()} OGMO. All Rights Reserved
      </footer>
    </Container>
  );
}

function ConfirmRegister(props) {
  const classes = useStyles();

  const [state , setState] = useState({
    confirmationCode : ""
  })
  const [alertOpen, setAlertOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };
  
  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }

  async function handleSubmitClick(e){
    e.preventDefault();
    try {
      const username = props.userName;
      const confirmationCode = state.confirmationCode
      await Auth.confirmSignUp(username, confirmationCode)
      props.setFormType("Login");
    } catch (err) {
      console.log('error signing up..', err)
      if (err.name.includes('CodeMismatchException'))
        {
          setAlertOpen(true);
        }
    }
  }

  return (
    <Container component="main" className={classes.container}>
      <CssBaseline />
      <Snackbar anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} open={alertOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Confirmation code is wrong
        </Alert>
      </Snackbar>
      <div className={classes.paper}>
        <div style={{marginBottom:'24px'}}>
          <img src={SignLogo} alt="logo"/>
        </div>
        <div style={{marginBottom:'36px'}}>
          <div className={classes.heading}>Verification Code</div>
          <div className={classes.subheading}>Please add the verification code sent to your email.</div>
        </div>
        <form onSubmit={handleSubmitClick} className={classes.form}>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <Typography className={classes.inputlabel}>Verification Code</Typography>
              <Input
                type="text"
                id="confirmationCode"
                name="confirmationCode"
                autoComplete="confirmationCode"
                className={classes.input}
                disableUnderline
                value={state.email}
                onChange={handleChange}
                required
              />
            </FormControl>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
          >
            Verify
          </Button>
        </form>
      </div>
      <footer className={classes.footer}>
        &copy; {new Date().getFullYear()} OGMO. All Rights Reserved
      </footer>
    </Container>
  );
}