import React, { useState, useRef } from 'react';
import { Typography, Input, Box } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { MAX_GLB_SIZE as maxSize} from './../../util/constants';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    //width: '100%',
  },
  colorPrimary: {
    backgroundColor: '#C4C4C4',
  },
  bar: {
    backgroundColor: '#1D4C92',
  },
}))(LinearProgress);

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
    fontWeight: 550
  },
  input: {
    fontSize: 14,
    color: '#263C67',
    fontFamily: 'Poppins',
    fontWeight: '500',
    borderRadius: '5px',
    alignItems: 'center',
    padding: '6px',
    width: 300,
    height: '40px',
    border: '2px solid #ECECEC',
    boxSizing: 'border-box',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  disabledinput: {
    fontSize: 14,
    color: '#263C67',
    fontFamily: 'Poppins',
    fontWeight: '500',
    borderRadius: '5px',
    alignItems: 'center',
    padding: '6px',
    width: 300,
    height: '55px',
    border: '2px dashed #ECECEC',
    boxSizing: 'border-box',
    display: 'none',
  },
  disabledStyle: {
    fontSize: 14,
    color: '#263C67',
    fontFamily: 'Poppins',
    fontWeight: '500',
  },
  paragraph: {
    maxWidth: '630px',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#505050',
  },
  replaceBtn: {
    position: 'relative',
    boxSizing: 'border-box',
    marginRight: '18px',
    background: '#1D4C92',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    background: '#1D4C92',
    color: 'white',
    border: 'transparent',
    borderRadius: '5px',
    padding: '8px 16px',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
    },
  },
  loadbarDiv: {
    width: '40vw',
    marginTop: '30px',
    height: '50px',
    border: '2px dashed #CBCBCB',
    boxSizing: 'border-box',
    borderRadius: '10px',
  },
  loadbar: {
    marginTop: '23px',
    marginLeft: '23px',
    marginRight: '23px',
  },
}));

const Uploader = ({ handleReplace, isReplaceGlb, replacedFileName, progress, ...props }) => {

  const classes = useStyles();
  const hiddenFileInput = useRef(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText,setAlertText] = useState()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };

  const fileValidate = event => {

    if (event.target.files[0].name.split('.').pop() === 'glb') {
      if (event.target.files[0].size <= maxSize) {
        handleReplace(event);
      }
      else {
        setAlertText("Maximum upload file size: 50 MB")
        setAlertOpen(true);
      }
    }
    else{
      setAlertText("Incorrect File Type")
      setAlertOpen(true);
    }
  }

  return (
    <>
      {isReplaceGlb &&
        <div className={classes.loadbarDiv} style={{ marginBottom: '18px' }}>
          <BorderLinearProgress className={classes.loadbar} variant="determinate" value={progress} />
        </div>
      }
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={alertOpen}
        onClose={handleClose}
        autoHideDuration={3000} >
        <Alert onClose={handleClose} severity="error">
          {alertText}
        </Alert>
      </Snackbar>
      <input
        accept=".glb"
        ref={hiddenFileInput}
        onChange={fileValidate}
        type="file"
        style={{ display: "none" }}
        multiple={false}
        id="replaceGlbFile"
        required
        data-test="replace_glb_file"
      />
      {!isReplaceGlb &&
        <div style={{ display: 'inline', width: 'fit-content', float: 'left' }}>
          <Input
            classes={{ disabled: classes.disabledStyle }}
            disabled
            autoFocus
            className={classes.disabledinput}
            defaultValue={replacedFileName}
            disableUnderline
            required />
          <button className={classes.replaceBtn} onClick={() => hiddenFileInput.current.click()}>Replace GLB</button>
        </div>
      }
    </>
  );
};

export default Uploader;
