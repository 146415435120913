import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import * as api from '../../services/api';
import { makeStyles } from '@material-ui/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Auth } from 'aws-amplify';
import SaveButton from '../../components/Design/SaveButton';
import UpdateDesignDetails from '../../components/Design/UpdateDesignDetails';
import { OGMOCanvas } from "ogmo-renderer";
import { Link } from "react-router-dom";
import { useEnvironmentStore, useSceneStore } from 'ogmo-renderer';
import DeleteButton from '../../components/Design/DeleteButton';
import Computer from '../../images/computer.png';
import axios from 'axios';
import LoaderElement from '../../components/PageLoader/LoaderElement';

import DeleteConfirmDialog from './../../components/Design/DeleteConfirmDialog';
import Uploader from './../../components/Design/Uploader';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  breadcrumb: {
    fontFamily: 'Poppins,sans-serif',
    lineHeight: '24px',
    color: '#263C67',
    fontSize: '16px',
    fontWeight: 500,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  preview: {
    borderRadius: '4px',
    alignItems: 'center',
    height: '30vh',
    width: '40vw',
    border: 'solid 1px #C4C4C4',
    marginTop: '30px',
    marginBottom: '30px',
  },
  loader: {
    color: '#3183ae',
  },
  title: {
    fontFamily: 'Poppins,sans-serif',
    fontWeight: 500,
    fontSize: '28px',
    lineHeight: '42px',
    color: '#263C67',
  },
  editbtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    background: '#3D6ACF',
    color: 'white',
    border: 'transparent',
    borderRadius: '5px',
    padding:'8px 16px',
    cursor:'pointer',
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
    },
  },
}));

const UpdateDesign = (props) => {

  const setEnvironmentMap = useEnvironmentStore((state) => state.setEnvironmentMap);
  const setEnvironmentMapExplicitPath = useEnvironmentStore((state) => state.setEnvironmentMapExplicitPath);
  const setBackgroundColor = useEnvironmentStore((state) => state.setBackgroundColor);
  const setAmbientIntensity = useEnvironmentStore((state) => state.setAmbientIntensity);
  const setShadowOpacity = useEnvironmentStore((state) => state.setShadowOpacity);
  const setShadowBlur = useEnvironmentStore((state) => state.setShadowBlur);
  const setShadowBias = useEnvironmentStore((state) => state.setShadowBias);
  const setModelPath = useSceneStore((state) => state.setModelPath);
  const setCameraPosition = useSceneStore((state) => state.setCameraPosition);
  const resetSceneDimensions = useSceneStore((state) => state.resetSceneDimensions);

  const [designName, setDesignName] = useState('');
  const [design, setDesign] = useState('');
  const [authuser, setAuthuser] = useState('');
  const [isInProgress, setIsInProgress] = useState(false);
  const [assetUrl, setAssetUrl] = useState('');
  const [replaceFile, setReplaceFile] = useState('');
  const [isReplaceGlb,setIsReplaceGlb] = useState(false);
  const [replacedFileName,setReplacedFileName]= useState('');
  const [uploadPercentage,setUploadPercentage] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  async function getAuthUser() {
    try {
      const auth = await Auth.currentAuthenticatedUser();
      if (auth) {
        setAuthuser(auth);
      }
    }
    catch (err) {
      console.log('Auth user error: ', err)
    }
  }

  useEffect(() => {
    getAuthUser();
    getDesign(props.match.params.designId);
  }, [])

  function saveUpdates() {
    updateDesign(assetUrl).then(() => designRedirect());
  }

  const designRedirect = () => {
    setModelPath(null);
    resetSceneDimensions();
    setBackgroundColor(null);
    history.push({
      pathname: `/designs/`
  });
  };

  const handleDesignNameChange = event => {
    setDesignName(event.target.value);
  };

  const handleReplaceGlb = event => {
      setModelPath(null);
      resetSceneDimensions();
      setBackgroundColor(null);
      setReplaceFile(event.target.files[0]);
      setReplacedFileName(event.target.files[0].name);
      replaceGlb(event.target.files[0])
  };

  const getDesign = (designId) => {
    setIsInProgress(true);
    api.get(`designs/${designId}`)
      .then(data => {
        setModelPath(data.data['sceneInfo']['modelPath']);
        setEnvironmentMap(data.data['sceneInfo']['environmentMapId']);
        setEnvironmentMapExplicitPath(data.data['sceneInfo']['environmentMapPath']);
        setBackgroundColor(data.data['sceneInfo']['backgroundColor']);
        setAmbientIntensity(data.data['sceneInfo']['ambientIntensity']);
        setShadowOpacity(data.data['sceneInfo']['shadowOpacity']);
        setShadowBlur(data.data['sceneInfo']['shadowBlur']);
        setShadowBias(data.data['sceneInfo']['shadowBias']);
        setCameraPosition(data.data['sceneInfo']['cameraPosition']);

        setAssetUrl(data.data.sceneInfo.modelPath);
        setDesignName(data.data.designName);
        setIsInProgress(false);
        setDesign(data.data);
      })
      .catch(error => {
        setIsInProgress(false);
        console.log('Faild to removeAsset: ', error.message);
      });
  };

  const handleDesignDelete = event => {
    event.preventDefault();
    setIsInProgress(true);
    setDialogOpen(false);
    api.remove(`designs/${design.designId}`)
      .then(data => {
        setIsInProgress(false);
        designRedirect();
      })
      .catch(error => {
        setIsInProgress(false);
        console.log('Faild to delete design: ', error.message);
      });
  };

  const replaceGlb = (file) => {
    setIsReplaceGlb(true);
    api.post('common/removeAsset', {
      userId: authuser.username,
      url: design.sceneInfo.modelPath
    })
      .then(data => {
        uploadAsset(file);
      })
      .catch(error => {
        setIsInProgress(false);
        setIsReplaceGlb(false);
        console.log('Faild to removeAsset: ', error.message);
      });
  };

  const uploadAsset = (file) => {
    api.post('common/uploadAsset', {
      dataType: "user-data",
      userId: authuser.username,
      designId: design.designId,
      assetType: "design",
      assetName: "ball.glb"
    })
      .then(data => {
        setAssetUrl(data.data.downloadUrl);
        uploadAssetWithUrl(data.data.uploadUrl, data.data.downloadUrl, file);
      })
      .catch(error => {
        setIsInProgress(false);
        setIsReplaceGlb(false);
        console.log('Faild to uploadAsset: ', error.message);
      });
  };

  function uploadAssetWithUrl(uploadUrl, downloadUrl, file) {
    const options = {
      headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'model/gltf-binary' },
      onUploadProgress: (progressEvent) => {
        const {loaded,total} = progressEvent;
        let percent = Math.floor( loaded * 100 / total )
        setUploadPercentage(percent);
      }
    }

    axios.put(uploadUrl,file,options)
      .then((responseJson) => {
        updateDesign(downloadUrl);
      })
      .catch((error) => {
        setIsInProgress(false);
        setIsReplaceGlb(false);
        console.log('uploadAssetWithUrl API call err: ', error)
      });
  }

  const updateDesign = async (downloadUrl) => {
    // setIsInProgress(true);
    setModelPath(downloadUrl);
    setEnvironmentMap(design['sceneInfo']['environmentMapId']);
    setEnvironmentMapExplicitPath(design['sceneInfo']['environmentMapPath']);
    setBackgroundColor(design['sceneInfo']['backgroundColor']);
    setAmbientIntensity(design['sceneInfo']['ambientIntensity']);
    setShadowOpacity(design['sceneInfo']['shadowOpacity']);
    setShadowBlur(design['sceneInfo']['shadowBlur']);
    setShadowBias(design['sceneInfo']['shadowBias']);
    setCameraPosition(design['sceneInfo']['cameraPosition']);

    await api.put(`designs/${design.designId}`, {
      userId: design.userId,
      designName: designName,
      publishProducts: design.publishProducts,
      thumbnail: design.thumbnail,
      sceneInfo: {
        modelPath: downloadUrl,
        cameraPosition: design.sceneInfo.cameraPosition,
        environmentMapId: design.sceneInfo.environmentMapId,
        environmentMapPath: design.sceneInfo.environmentMapPath,
        backgroundColor: design.sceneInfo.backgroundColor,
        ambientIntensity: design.sceneInfo.ambientIntensity,
        shadowOpacity: design.sceneInfo.shadowOpacity,
        shadowBlur: design.sceneInfo.shadowBlur,
        shadowBias: design.sceneInfo.shadowBias
      },
      enableDesign: true,
      updatedAt: design.updatedAt,
      createdAt: design.createdAt,
      shortId: design.shortId
    })
      .then(data => {
        setIsInProgress(false);
        setIsReplaceGlb(false);
        setUploadPercentage(0);
      })
      .catch(error => {
        setIsInProgress(false);
        setIsReplaceGlb(false);
        console.log('Faild to updateDesign: ', error.message);
      });
  };

  return (
    <Box>
      <DeleteConfirmDialog open={dialogOpen} setOpen={setDialogOpen} handleDelete={handleDesignDelete} selected={[design]}/>
      {design && (
      <Box px={4} pb={4}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div>
            <Breadcrumbs
              aria-label="breadcrumb"
            >
              <Link to={'/designs'}
                className={classes.breadcrumb}
              >
                Designs {`>`}
              </Link>
            </Breadcrumbs>
          </div>
            {isInProgress && (
              <Box
                display="flex"
                justifyContent="center"
              >
                <LoaderElement/>
              </Box>
            )}
          {/* {!isReplaceGlb && */}
            <div style={{display:'flex',alignItems:"center"}}>
              <DeleteButton handleSubmit={() => setDialogOpen(true)} />
              <div style={{marginLeft:'30px'}}>
                <SaveButton  handleSubmit={saveUpdates} />
              </div>
            </div>
          {/* } */}
        </div>
        <Typography
          className={classes.title}
          color="textPrimary"
        >
        {designName}
      </Typography>
      <UpdateDesignDetails
        handleNameChange={handleDesignNameChange}
        design={design}
      />
      <Box className={classes.preview}>
        <OGMOCanvas id={'preview'} />
      </Box>
      <Uploader
        handleReplace={handleReplaceGlb}
        isReplaceGlb={isReplaceGlb}
        replacedFileName={replacedFileName}
        progress={uploadPercentage}
      />
          {!isReplaceGlb &&
            <Link style={{ textDecoration: 'none' }} to={`/editor/${design.designId}`}><button className={classes.editbtn} data-test="edit_in_3D_editor"><img style={{ marginRight: "8px" }} width="20px" height="20px" src={Computer} alt="edit logo" /> Edit in 3D Editor</button></Link>
          }
      </Box>
      )}
    </Box>
  );
};
export default UpdateDesign;
