import React from "react";
import { Box } from "@material-ui/core";
import LoaderElement from './LoaderElement';

const PageLoader = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexGrow={1}
      alignItems="center"
    >
      <LoaderElement/>
    </Box>
  );
}

export default PageLoader;