import React, {useState,useEffect} from 'react';
import { Redirect } from 'react-router-dom';
import { Input, FormControl} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Auth } from 'aws-amplify';
import SignLogo from '../../images/signLogo.png';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  container:{
    position:'relative',
    width:'100%',
    minHeight:'100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    maxWidth:'760px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom:'100px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth:'400px',
  },
  ogmoLogo: {
    width: 190,
    height: 47,  
  },
  logoText: {
    color: '#203256',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '500',
    textAlign:'center',
  },
  heading:{
    color: '#293A5D',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '42px',
    fontWeight: '600',
    textAlign:'center',
    marginBottom:'12px',
  },
  subheading:{
    color: '#000',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
    textAlign:'center',
  },
  formControl:{
    width:'100%',
    marginBottom:'24px',
  },
  input: {
    fontSize: 14,
    fontFamily: 'Poppins',
    borderRadius: '5px',
    alignItems: 'center',
    padding: '6px',
    width:'100%',
    height: '40px',
    border: '1px solid #C4C4C4',
    boxSizing: 'border-box',
    marginTop: '12px',
  },
  inputRed: {
    border: '1px solid #E52E2A',
  },
  inputlabel:{
    color: '#000',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '16px',
    fontWeight: '500',
  },
  snack: {
    background: '#f44336',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
  },
  submit: {
    background: '#3D6ACF',
    borderRadius: '5px',
    height:'40px',
    color: '#FBFDFF',
    textTransform: 'capitalize',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '600',
    boxShadow:'none',
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
      boxShadow:'none',
    },
  },
  footer:{
    position:'absolute',
    bottom:'0',
    height:'100px',
    display:'flex',
    alignItems:'center',
    color: '#6B6B6B',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '500',
    textAlign:'center',
  },
  passwordTip: {
    color: '#E62E2A',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '500',
    marginTop: '12px',
  },
}));

export default function ForgotPassword() {
  const [formType, updateFormType] = useState('ForgotPassword')
  const [userName, updateUserName] = useState('ConfirmForgotPassword')
  function renderForgotPassword() {
    switch(formType) {
      case 'ForgotPassword':
        return (
          <ForgotPass
          setFormType={updateFormType}
          setUserName={updateUserName}
          />
        )
      case 'ConfirmForgotPassword':
        return (
          <ConfirmForgotPass
          setFormType={updateFormType}
          userName={userName}
          />
        )
      case 'Login':
        return <Redirect to='/signin'/>
    }
  }

  return renderForgotPassword()
}

function ForgotPass(props) {

  const classes = useStyles();
  const [state , setState] = useState({
    email : ""
  })

  const [errormsg,setErrormsg] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };
  
  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }

  async function handleSubmitClick(e){
    e.preventDefault();
    const email = state.email
    try {
      await Auth.forgotPassword(email)
      .then(data =>{
        props.setUserName(email);
        props.setFormType("ConfirmForgotPassword");
      })
      .catch(err => {
        console.log('error in forgot password..',err)
        if(err.name.includes('LimitExceededException'))
        {
          setErrormsg('Attempt limit exceeded, please try after some time.');
          setAlertOpen(true);
        }
      });
      
    } catch (err) {
      console.log('error in forgot password..', err)
    }
  }

  return (
    <Container component="main" className={classes.container}>
      <CssBaseline />
      <Snackbar anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} 
        open={alertOpen} 
        autoHideDuration={3000} 
        onClose={handleClose} 
        ContentProps={{
          "aria-describedby": "message-id",
          className: classes.snack
        }}
        message={
            <div id="message-id" style={{display:'flex',alignItems:'center'}}>
              <ErrorOutlineIcon/>
              <div style={{marginLeft:'10px'}}>{errormsg}</div>
              <CloseIcon onClick={()=>{setAlertOpen(false)}}/>
            </div>
        } />
      <div className={classes.paper}>
        <div style={{marginBottom:'24px'}}>
          <img src={SignLogo} alt="logo"/>
        </div>
        <div style={{marginBottom:'36px',maxWidth:'500px'}}>
          <div className={classes.heading}>Forgot password</div>
          <div className={classes.subheading}>Enter your email below. We will send you a verification code to reset your password.</div>
        </div>
        <div className={classes.form}>
          <form onSubmit={handleSubmitClick}>
            <Grid container>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <Typography className={classes.inputlabel}>Email</Typography>
                    <Input
                      type="email"
                      id="email"
                      name="email"
                      autoComplete="email"
                      className={classes.input}
                      disableUnderline
                      value={state.email}
                      onChange={handleChange}
                      required
                    />
                </FormControl>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              Send Code
            </Button>
          </form>
          <div className={classes.logoText} style={{color:'#000',marginTop:'20px'}}>
          Back to <Link href={'/signin'} style={{color:'#3D6ACF'}}>Sign in</Link>
          </div>
        </div>
      </div>
      <footer className={classes.footer}>
        &copy; {new Date().getFullYear()} OGMO. All Rights Reserved
      </footer>
    </Container>
  );
}

function ConfirmForgotPass(props) {
  const classes = useStyles();

  const [state , setState] = useState({
    confirmationCode : "",
    newPassword: ""
  })
  const [errormsg,setErrormsg] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {
    if(state.newPassword.length >= 10){
      setPasswordError(false);
    }
  },[state.newPassword]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };
  
  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }

  async function handleSubmitClick(e){
    e.preventDefault();
    try {
      const username = props.userName;
      const code = state.confirmationCode
      const new_password = state.newPassword
      await Auth.forgotPasswordSubmit(username, code, new_password)
      .then(data =>{
        props.setFormType("Login");
      })
      .catch(err => {
        console.log('error Resetting Password..',err)
        if(err.name.includes('InvalidPasswordException'))
        {
          setPasswordError(true);
        }
        else if (err.name.includes('CodeMismatchException'))
        {
          setErrormsg('Confirmation code is wrong');
          setAlertOpen(true);
        }
      });      
    } catch (err) {
      console.log('error Resetting Password..', err)
    }
  }

  return (
    <Container component="main" className={classes.container}>
      <CssBaseline />
      <Snackbar anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} 
        open={alertOpen} 
        autoHideDuration={3000} 
        onClose={handleClose} 
        ContentProps={{
          "aria-describedby": "message-id",
          className: classes.snack
        }}
        message={
            <div id="message-id" style={{display:'flex',alignItems:'center'}}>
              <ErrorOutlineIcon/>
              <div style={{marginLeft:'10px'}}>{errormsg}</div>
              <CloseIcon onClick={()=>{setAlertOpen(false)}}/>
            </div>
        } />
      <div className={classes.paper}>
        <div style={{marginBottom:'24px'}}>
          <img src={SignLogo} alt="logo"/>
        </div>
        <div style={{marginBottom:'36px',maxWidth:'500px'}}>
          <div className={classes.heading}>Reset your password</div>
          <div className={classes.subheading}>Please enter the code sent to your email and reset your password.</div>
        </div>
        <div className={classes.form}>
          <form onSubmit={handleSubmitClick}>
            <Grid container>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <Typography className={classes.inputlabel}>Confirmation Code</Typography>
                    <Input
                      type="text"
                      id="confirmationCode"
                      name="confirmationCode"
                      autoComplete="confirmationCode"
                      className={classes.input}
                      disableUnderline
                      value={state.confirmationCode}
                      onChange={handleChange}
                      required
                    />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <Typography className={classes.inputlabel}>New Password</Typography>
                    <Input
                      type="password"
                      name="newPassword"
                      id="newPassword"
                      autoComplete="current-password"
                      className={passwordError ? `${classes.input} ${classes.inputRed}`: classes.input}
                      disableUnderline
                      value={state.newPassword}
                      onChange={handleChange}
                      required
                    />
                    <div className={classes.passwordTip}> 
                    {state.newPassword.length > 0 && state.newPassword.length < 10 ? `Minimum 10 characters` : null}
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              Submit
            </Button>
          </form>
        </div>
      </div>
      <footer className={classes.footer}>
        &copy; {new Date().getFullYear()} OGMO. All Rights Reserved
      </footer>
    </Container>
  );
}