import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  titleRoot: {
    paddingBottom: 0
  },
  paper: {
    padding: '30px'
  },
  actionsRoot: {
    justifyContent: 'center'
  },
  modalCenteredContent: {
    textAlign: 'center',
  },
  cancelbutton: {
    backgroundColor: '#3D6ACF',
    border: '2px solid #3D6ACF',
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: 'white',
    fontFamily: 'Poppins,sans-serif',
    lineHeight: '21px',
    fontSize: '14px',
    fontWeight: 600,
    padding: '8px 16px',
    width: '100px',
    height: '42px',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
    },
  },
}))

export default function UpgradeAlert({ open, setOpen }) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth='xs'
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.paper
        }}>
        <DialogTitle id="max-width-dialog-title" classes={{
          root: classes.titleRoot
        }}>
          <h2 className={classes.modalCenteredContent} style={{ fontWeight: 700, fontFamily: 'Poppins', fontSize: '24px', color: "#263C67" }}>OGMO Subscription Upgrade</h2>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ fontWeight: 500, fontFamily: 'Poppins', fontSize: '16px' }}>
            <p className={classes.modalCenteredContent}>To upgrade your OGMO subscription plan,</p>
            <p className={classes.modalCenteredContent}>please contact us via <Link href="mailto:hello@ogmo.xyz">hello@ogmo.xyz</Link></p>
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{
          root: classes.actionsRoot
        }}>
          <button className={classes.cancelbutton} onClick={handleClose} color="primary">
            Ok
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}