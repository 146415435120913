import React from 'react';
import { Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  button: {
    padding:'8px 16px',
    border: '2px solid #3D6ACF',
    boxSizing: 'border-box',
    background: 'transparent',
    borderRadius: '5px',
    color: '#3D6ACF',
    fontFamily: 'Poppins,sans-serif',
    lineHeight: '24px',
    fontSize: '14px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    cursor:'pointer',
    "&:hover": {
      background: '#ECECEC',

    },
  }
}));

const DeleteButton = ({ handleSubmit }) => {
  const classes = useStyles();

  const buttonLabel = 'Delete Design';

  return (
    <Box
      display="flex"
      mb={1}
      mt={0}
    >
      <button
        className={classes.button}
        onClick={handleSubmit}
        data-test="delete_design">
        {buttonLabel}
      </button>
    </Box>
  );
};
export default DeleteButton;
