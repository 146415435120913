import React, { useEffect, useRef } from 'react'
import { ContactShadows, OrbitControls } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import * as THREE from 'three'
import { useSceneStore } from './Store'

const Camera = () => {

    const center = useSceneStore((state) => state.modelCenter)
    const size = useSceneStore((state) => state.modelSize)

    const orbitControls = useRef(null);
    const camera = useThree(state => state.camera)

    const saveCameraPosition = useSceneStore((state) => state.saveCameraPosition)
    const cameraPosition = useSceneStore((state) => state.cameraPosition)
    const setCameraPosition = useSceneStore((state) => state.setCameraPosition)

    useEffect(() => {
        if(saveCameraPosition) {
            setCameraPosition({x : camera.position.x, y : camera.position.y, z : camera.position.z})
        }
    },[saveCameraPosition])

    useEffect(() => {

        orbitControls.current.maxDistance = size * 10;
        camera.near = size / 100;
        camera.far = size * 100;
        camera.fov = 54;

        if(cameraPosition.x === 0 && cameraPosition.y === 0 && cameraPosition.z ===0) {
            camera.position.copy(center);
            camera.position.x += -size / 1.5;
            camera.position.y += size / 2.5;
            camera.position.z += size / 1.5;
        } else {
            camera.position.x = cameraPosition.x;
            camera.position.y = cameraPosition.y;
            camera.position.z = cameraPosition.z;
        }
        
        camera.updateProjectionMatrix();
        orbitControls.current.target.copy(center);

    }, [center, size, cameraPosition]);

    return (
        <OrbitControls ref={orbitControls} /> 
    )
}

export { Camera }