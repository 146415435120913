import { Auth } from 'aws-amplify';

export const ViewerHost = process.env.REACT_APP_VIEWER_HOST;
const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;

export const get = (url) => {
  return client(url);
};

export const post = (url, body) => {
  return client(url, { data: body });
};

export const remove = (url) => {
  return client(url, { method: 'DELETE' });
};

export const put = (url, body) => {
  return client(url, { method: 'PUT', data: body });
};

const client = (
  endpoint,
  { data, headers: customHeaders, ...customConfig } = {}
) => {
  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      'Content-Type':'application/json',
      ...customHeaders,
    },
    ...customConfig,
  };
  const url = apiEndPoint.concat(endpoint);
  
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const session = await Auth.currentSession();
      if (session) {
        const idToken = session.getIdToken().getJwtToken();
        config.headers.Authorization = 'Bearer '.concat(idToken);
      }
    } catch (error) {
      Auth.signOut();
      return reject(error);
    }

    fetch(url, config).then(async (response) => {
      if (response.status === 401 || response.status === 403) {
        Auth.signOut();
        return reject('Please re-authenticate.');
      }
      const data = await response.json();
      if (response.ok) {
        return resolve(data);
      } else {
        return reject(data);
      }
    });
  });
};
