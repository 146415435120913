import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import * as api from '../../services/api';
import { makeStyles } from '@material-ui/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Auth } from 'aws-amplify';
import SaveButton from '../../components/Design/SaveButton';
import DesignDetails from '../../components/Design/DesignDetails';
import { OGMOCanvas } from "ogmo-renderer";
import { Link } from "react-router-dom";
import { useSceneStore } from 'ogmo-renderer';
import DeleteButton from '../../components/Design/DeleteButton';
import axios from 'axios';
import LoaderElement from '../../components/PageLoader/LoaderElement';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  breadcrumb: {
    fontFamily: 'Poppins,sans-serif',
    lineHeight: '24px',
    color: '#263C67',
    fontSize: '16px',
    fontWeight: 500,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  backbtn: {
    fontFamily: 'Poppins,sans-serif',
    lineHeight: '24px',
    color: '#3D6ACF',
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  loader: {
    color: '#3183ae',
  },
  title: {
    fontFamily: 'Poppins,sans-serif',
    fontWeight: 500,
    fontSize: '28px',
    lineHeight: '42px',
    color: '#263C67',
  },
  paragraph: {
    maxWidth: '630px',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#505050',
  }
}));

const CreateDesign = () => {

  const setModelPath = useSceneStore((state) => state.setModelPath);
  const resetSceneDimensions = useSceneStore((state) => state.resetSceneDimensions);
  const [designName, setDesignName] = useState('');
  const [design, setDesign] = useState('');
  const [authuser, setAuthuser] = useState('');
  const [isCreateDesignInProgress, setIsCreateDesignInProgress] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [assetUrl, setAssetUrl] = useState('');
  const [glbModel, setGlbModel] = useState('');
  const [replaceFile, setReplaceFile] = useState('');
  const [isReplaceGlb,setIsReplaceGlb] = useState(false);
  const [replacedFileName,setReplacedFileName]= useState('');
  const [uploadPercentage,setUploadPercentage] = useState(0);
  const [isUploadGlb,setIsUploadGlb] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  const knowledgeHubLink = `${process.env.REACT_APP_DOCS_URL}docs/ogmo-essentials/designs`;

  async function getAuthUser() {
    try {
      const auth = await Auth.currentAuthenticatedUser();
      if (auth) {
        setAuthuser(auth);
      }
    }
    catch (err) {
      console.log('Auth user error: ', err)
    }
  }

  useEffect(() => {
    getAuthUser();
    setModelPath(null);
    resetSceneDimensions();
  }, [])

  function saveUpdates() {
      updateDesign(assetUrl.downloadUrl,design).then(() => designRedirect());
  }

  const designRedirect = () => {
    setModelPath(null);
    resetSceneDimensions();
    history.push('/designs')
  };

  const handleDesignNameChange = event => {
    event.persist();
    setDesignName(event.target.value);
  };

  const handleReplaceGlb = event => {
    setModelPath(null);
    resetSceneDimensions();
    setReplaceFile(event.target.files[0]);
    setReplacedFileName(event.target.files[0].name);
    replaceGlb(event.target.files[0])
  };

  const newDesign = {
    userId: authuser.username,
    designName: designName,
    publishProducts: [
    ],
    thumbnail: "",
    sceneInfo: {
      modelPath: "",
      cameraPosition: null,
      environmentMapId: "",
      environmentMapPath: "",
      backgroundColor: "",
      ambientIntensity: null,
      shadowOpacity: null,
      shadowBlur: null,
      shadowBias: null
    },
    enableDesign: true,
    updatedAt: "",
    createdAt: "",
    shortId: ""
  };

  const handleSubmit = event => {
    if(event[0]){
      let file=event[0];
      setGlbModel(file);
      // if (designName == '') {
      //   console.log('Please add a design name');
      //   setIsCreateDesignInProgress(false);
      // }
      // else {
        setIsUploading(true);
        api.post('designs', newDesign)
          .then(data => {
            uploadAsset(data.data, file);
          })
          .catch(error => {
            setIsUploading(false);
            console.log('Faild to create design: ', error.message);
          });
      // }
    }
  };

  const handleDesignDelete = event => {
    event.preventDefault();
    setIsCreateDesignInProgress(true);
    api.remove(`designs/${design.designId}`)
      .then(data => {
        setIsCreateDesignInProgress(false);
        designRedirect();
      })
      .catch(error => {
        setIsCreateDesignInProgress(false);
        console.log('Faild to delete design: ', error.message);
      });
  };

  const replaceGlb = (file) => {
    setIsReplaceGlb(true);
    api.post('common/removeAsset', {
      userId: authuser.username,
      url: assetUrl.downloadUrl
    })
      .then(data => {
        uploadAsset(design, file);
      })
      .catch(error => {
        setIsCreateDesignInProgress(false);
        setIsReplaceGlb(false);
        console.log('Faild to removeAsset: ', error.message);
      });
  };

  const uploadAsset = (Data, file) => {
    setIsUploadGlb(true)   
    api.post('common/uploadAsset', {
      dataType: "user-data",
      userId: authuser.username,
      designId: Data.designId,
      assetType: "design",
      assetName: "ball.glb"
    })
      .then(data => {
        uploadAssetWithUrl(data.data.uploadUrl, data.data.downloadUrl, Data, file);
        setAssetUrl(data.data);
      })
      .catch(error => {
        setIsUploading(false);
        setIsCreateDesignInProgress(false);
        setIsReplaceGlb(false);
        console.log('Faild to uploadAsset: ', error.message);
      });
  };

  function uploadAssetWithUrl(uploadUrl, downloadUrl, Data, file) {
    const options = {
      headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'model/gltf-binary' },
      onUploadProgress: (progressEvent) => {
        const {loaded,total} = progressEvent;
        let percent = Math.floor( loaded * 100 / total )
        setUploadPercentage(percent);
      }
    }

    axios.put(uploadUrl,file,options)
      .then((responseJson) => {
        updateDesign(downloadUrl, Data);
      })
      .catch((error) => {
        setIsUploading(false);
        setIsReplaceGlb(false);
        console.log('uploadAssetWithUrl API call err: ', error)
      });
  }

  const updateDesign = async (downloadUrl, Data) => {
    setModelPath(downloadUrl);
    await api.put(`designs/${Data.designId}`, {
      userId: Data.userId,
      designName: designName,
      publishProducts: Data.publishProducts,
      thumbnail: Data.thumbnail,
      sceneInfo: {
        modelPath: downloadUrl,
        cameraPosition: {
          "x": 0,
          "y": 0,
          "z": 0
        },
        environmentMapId: Data.sceneInfo.environmentMapId,
        environmentMapPath: Data.sceneInfo.environmentMapPath,
        backgroundColor: "#ffffff",
        ambientIntensity: 1,
        shadowOpacity: 0.5,
        shadowBlur: 0.5,
        shadowBias: 0
      },
      enableDesign: true,
      updatedAt: Data.updatedAt,
      createdAt: Data.createdAt,
      shortId: Data.shortId
    })
      .then(data => {
        setDesign(data.data);
        setIsUploading(false);
        setIsReplaceGlb(false);
        setUploadPercentage(0);
        setIsUploadGlb(false) 
      })
      .catch(error => {
        setIsUploading(false);
        setIsReplaceGlb(false);
        console.log('Faild to updateDesign: ', error.message);
      });
  };

  return (
    <Box px={4}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <div>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link to={'/designs'}
              className={classes.breadcrumb}
            >
              Designs {`>`}
            </Link>
          </Breadcrumbs>
        </div>
        {!isUploading && design.designId ? (<div style={{display:'flex',alignItems:"center"}}>
          <DeleteButton handleSubmit={handleDesignDelete} />
          <div style={{marginLeft:'30px'}}>
          <SaveButton  handleSubmit={saveUpdates} />
          </div>
        </div>) : <div><Link className={classes.backbtn} to={'/designs'}>Cancel</Link></div>
        }
      </div>
      <Typography
        className={classes.title}
        color="textPrimary"
      >
        New Design
      </Typography>
      <div className={classes.paragraph}>
        <p>Please upload a GLB.  A Design will be created in your OGMO under same file name once your GLB upload is completed.
          You can edit the design name thereafter.
        </p>
        <p>
        Learn more about OGMO Designs in <a href={knowledgeHubLink} target="_blank" style={{textDecoration:'none',color:'#3D6ACF'}}>OGMO Knowledge Hub.</a>
        </p>
      </div>
      <Box py={4}>
        {isCreateDesignInProgress && (
          <Box
            display="flex"
            justifyContent="center"
          >
            <LoaderElement/>
          </Box>
        )}
        <DesignDetails
          design = {design}
          isUploading= {isUploading}
          isReplaceGlb = {isReplaceGlb}
          handleNameChange={handleDesignNameChange}
          handleUpload={handleSubmit}
          handleReplace={handleReplaceGlb}
          replacedFileName={replacedFileName}
          progress={uploadPercentage}
          isUploadGlb= {isUploadGlb}
        />
      </Box>
    </Box>
  );
};
export default CreateDesign;
