import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: '30px'
    },
    actionsRoot: {
        justifyContent: 'center'
    },
    cancelbutton: {
        backgroundColor: '#3D6ACF',
        border: '2px solid #3D6ACF',
        boxSizing: 'border-box',
        borderRadius: '5px',
        color: 'white',
        fontFamily: 'Poppins,sans-serif',
        lineHeight: '21px',
        fontSize: '14px',
        fontWeight: 600,
        padding: '8px 16px',
        width: '100px',
        height: '42px',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: "rgba(29, 76, 146,0.8)",
        },
    },
}));

const ComingSoonAlert = ({ setShowGroupDesignDialog, showGroupDesignDialog }) => {

    const classes = useStyles();

    const handleClose = () => {
        setShowGroupDesignDialog(false);
    }

    return (
        <div>
            <Dialog
                open={showGroupDesignDialog}
                onClose={handleClose}
                fullWidth={true}
                maxWidth='xs'
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paper: classes.paper
                }}>
                <h2 className={classes.modalCenteredContent} style={{ fontWeight: 700, fontFamily: 'Poppins', fontSize: '24px', color: "#263C67", textAlign: 'center' }}>Coming Soon</h2>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ fontWeight: 500, fontFamily: 'Poppins', fontSize: '16px', textAlign: 'center' }}>
                        Edit group design will be available soon.
                    </DialogContentText>
                </DialogContent>
                <DialogActions classes={{
                    root: classes.actionsRoot
                }}>
                    <button className={classes.cancelbutton} onClick={handleClose} color="primary">
                        Ok
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ComingSoonAlert;