import React, { useEffect, useState } from 'react';
import { Box, Button, Input, Paper,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as api from '../../services/api';
import { Auth } from 'aws-amplify';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import DesignsTable from '../../components/Design/DesignsTable';
import DesignsToolBar from '../../components/Design/DesignsToolBar';
import CloseIcon from '@material-ui/icons/Close';
import ComingSoonAlert from '../../components/Design/CommingSoonAlert/ComingSoonAlert';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  container: {
    paddingLeft:'20px',
    paddingRight:'20px',
  },
  input: {
    width: '200px',
    height: '40px',
    border: '1px solid #C4C4C4',
    boxSizing: 'border-box',
    borderRadius: '5px',
  },
  adornment: {
    paddingLeft: '15px',
    color: '#D6D6D6',
  },
  endadornment: {
    height:'16px',
    width:'16px',
    marginRight: '15px',
    color: '#D6D6D6',
    cursor: 'pointer',
  },
  button: {
    padding:'8px 16px',
    background: '#3D6ACF',
    borderRadius: '5px',
    color: '#FBFDFF',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    marginLeft: '10px',
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
    },
  },
  breadcrumb: {
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  },
  tablebox: {
    marginTop: '32px',
  },
  subtitle3: {
    fontFamily: 'Poppins , sans-serif',
    color: '#263C67',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '500',
  },
}))

const Designs = () => {
  const classes = useStyles();
  const [designs, setDesigns] = useState([]);
  const [authuser, setAuthuser] = useState('');
  const [isDesignsAreLoading, setIsDesignsAreLoading] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [exclusiveStartKey, setExclusiveStartKey] = useState('');
  const [page, setPage] = useState(0);
  const [designCount, setDesignCount] = useState(0);
  const [showGroupDesignDialog, setShowGroupDesignDialog] = useState(false);

  const handleChange = event => {
    setSearchText(event.target.value);
  };

  const clearSearchText = () => {
    setSearchText('');
  };

  async function designLoader() {
    try {
      const auth = await Auth.currentAuthenticatedUser();
      if (auth) {
        setAuthuser(auth);
        loadDesigns(auth.username,page);
      }
    }
    catch (err) {
      console.log('Auth user error: ', err)
    }
  }

  useEffect(() => {
    designLoader();
  }, [rowsPerPage])

  const loadDesigns = (username,page) => {
    setIsDesignsAreLoading(true);

    let nextDesignStartKey = exclusiveStartKey;
    if (page == 0) {
      nextDesignStartKey = '';
    }

    api.get(`designs?userId=${username}&itemsPerPage=${rowsPerPage}&designName=${searchText}&exclusiveStartKey=${nextDesignStartKey}`)
      .then(data => {
        setIsDesignsAreLoading(false);
        setDesigns(data.data.designs);
        if (searchText=='') {
          setDesignCount(data.data.totalDesignCount);
        } else {
          setDesignCount(data.data.designCount);
        }
        if (data.data.exclusiveStartKey!==null) {
          setExclusiveStartKey(data.data.exclusiveStartKey);
        }
        else{
          setExclusiveStartKey('');
        }
      })
      .catch(error => {
        console.log(error);
        setIsDesignsAreLoading(false);
      });
  };

  const handleEnterKeyPress = (e) => {
    if(e.key === 'Enter') {
      e.preventDefault();
      loadDesigns(authuser.username,page);
    }
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    loadDesigns(authuser.username,newPage);
  };

  const handleSetRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
      <Box className={classes.container}>
        <ComingSoonAlert setShowGroupDesignDialog={setShowGroupDesignDialog} showGroupDesignDialog={showGroupDesignDialog} />
        <DesignsToolBar />
        <Box display="flex" alignItems="center">
            <Input
              value={searchText}
              autoFocus
              variant="outlined"
              placeholder="Search by name"
              className={classes.input}
              disableUnderline
              onChange={handleChange}
              onKeyPress={handleEnterKeyPress}
              startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon className={classes.adornment}/>
                  </InputAdornment>
              }
              endAdornment = {
                searchText.length > 0 ? <InputAdornment position="end" onClick={clearSearchText}>
                    <CloseIcon className={classes.endadornment}/>
                  </InputAdornment> : null
              }
              data-test="search_by_name"
            />
          <Button
            className={classes.button}
            onClick={event=>loadDesigns(authuser.username,page)}
            data-test="search_by_name_btn"
          >
            search
          </Button>
          <div style={{marginLeft:'60px'}}>
            <Typography className={classes.subtitle3} data-test="available_designs">
              {designCount} Designs available
            </Typography>
          </div>
        </Box>
        <Box className={classes.tablebox}>
          <DesignsTable
            designs={designs}
            showDate
            isDesignsAreLoading={isDesignsAreLoading}
            handleRowsPerPageChange={handleSetRowsPerPage}
            rowsPerPage={rowsPerPage}
            handlePageChange={(event, page)=>handleChangePage(page)}
            page={page}
            count={designCount}
            setShowGroupDesignDialog={setShowGroupDesignDialog}
          />
        </Box>
      </Box>
  );

};

export default Designs;
